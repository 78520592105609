import { FC, Fragment } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { fetchLogoutThunk, selectRole, selectUser } from 'pages/Auth/reducer';
import { Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';
import { colors } from 'variables';
import { ProfileAvatar } from 'components/Avatar';
import { headerNavigation } from 'components/Header';
import { ArrowLeftIcon, ArrowRightIcon, BagIcon, CloseIcon, ExitIcon, SettingsIcon, UserIcon, YoutubeIcon } from 'assets/icons';

import logo from 'assets/logo/Cloutboost_Horizontal_logo_(Warm Gradient)@4x_(1).png';
import { ROLE } from '../../app/RequiredRole';

export const navigation: any = {
  [ROLE.user]: [
    {
      name: 'Campaigns',
      icon: BagIcon,
      to: 'campaigns',
      activeLinks: ['influencers'],
    },
    // {
    //   name: 'Notifications',
    //   icon: NotificationsIcon,
    //   to: 'notifications',
    //   count: 4,
    // },
    { name: 'My profile', icon: UserIcon, to: 'profile' },
  ],
  [ROLE.viewer]: [
    {
      name: 'Campaigns',
      icon: BagIcon,
      to: 'campaigns',
      activeLinks: ['influencers'],
    },
    // {
    //   name: 'Notifications',
    //   icon: NotificationsIcon,
    //   to: 'notifications',
    //   count: 4,
    // },
    { name: 'My profile', icon: UserIcon, to: 'profile' },
  ],
  [ROLE.admin]: [
    { name: 'Find influencers', icon: YoutubeIcon, to: '/influencers' },
    {
      name: 'Campaigns',
      icon: BagIcon,
      to: '/campaigns',
      breadcrumbs: [
        {
          id: 'campaignId',
          to: (id: string | number) => `/campaigns/${id}`,
        },
      ],
    },
    // {
    //   name: 'Notifications',
    //   icon: NotificationsIcon,
    //   to: 'notifications',
    //   count: 4,
    // },
    { name: 'Admin tools', icon: SettingsIcon, to: '/admin' },
    { name: 'My profile', icon: UserIcon, to: '/profile' },
  ],
  [ROLE.test]: [
    { name: 'Find influencers', icon: YoutubeIcon, to: '/influencers' },
    {
      name: 'Campaigns',
      icon: BagIcon,
      to: '/campaigns',
      breadcrumbs: [
        {
          id: 'campaignId',
          to: (id: string | number) => `/campaigns/${id}`,
        },
      ],
    },
    { name: 'Admin tools', icon: SettingsIcon, to: '/', disabled: true },
    { name: 'My profile', icon: UserIcon, to: '/profile' },
  ],
  [ROLE.accountUser]: [
    { name: 'Find influencers', icon: YoutubeIcon, to: '/influencers' },
    {
      name: 'Campaigns',
      icon: BagIcon,
      to: '/campaigns',
      breadcrumbs: [
        {
          id: 'campaignId',
          to: (id: string | number) => `/campaigns/${id}`,
        },
      ],
    },
    { name: 'Admin tools', icon: SettingsIcon, to: '/admin' },
    { name: 'My profile', icon: UserIcon, to: '/profile' },
  ],
  [ROLE.indie]: [
    { name: 'Find influencers', icon: YoutubeIcon, to: '/influencers' },
    {
      name: 'Campaigns',
      icon: BagIcon,
      to: '/campaigns',
      breadcrumbs: [
        {
          id: 'campaignId',
          to: (id: string | number) => `/campaigns/${id}`,
        },
      ],
    },
    { name: 'My profile', icon: UserIcon, to: '/profile' },
  ],
};

interface ISidebarProps {
  sidebarOpen: boolean;
  collapseSidebar: boolean;
  setSidebarOpen: (value: boolean) => void;
  handleCollapseSidebar: () => void;
}

export const Sidebar: FC<ISidebarProps> = ({ sidebarOpen, collapseSidebar, setSidebarOpen, handleCollapseSidebar }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const role = useAppSelector(selectRole);
  const user = useAppSelector(selectUser);

  const navigationByUserRole: any[] = navigation[role];

  if (navigationByUserRole === null) return null;

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <CloseIcon className="h-6 w-6" pathFill="#ffffff" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>

                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-col w-full border-r border-gray-g2 bg-white">
                  <div className="flex items-center h-[72px] px-6">
                    <Link to="/" className="flex flex-shrink-0 items-center">
                      <img className="w-[187px]" src={logo} alt="Cloutboost" />
                    </Link>
                  </div>
                  <div className="px-6 sm:hidden">
                    <nav className={cn('flex flex-col space-y-1 bg-white transition-all duration-300 ease-in-out')} aria-label="Sidebar">
                      {headerNavigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.to}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={cn(
                            'px-2.5 py-0.5 text-sm leading-6 uppercase font-semibold text-black-b1 rounded-[4px] hover:text-gray-900 hover:bg-gray-g1'
                          )}
                        >
                          {item.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                  <div className="flex flex-1 flex-col overflow-y-auto">
                    <nav className={cn('flex-1 space-y-1 p-6 sm:pt-3 bg-white transition-all duration-300 ease-in-out')} aria-label="Sidebar">
                      {navigationByUserRole.map((item) => {
                        const extraActiveLink = item?.activeLinks ? item.activeLinks.some((d: string) => location.pathname.includes(d)) : false;

                        return (
                          <NavLink
                            key={item.name}
                            to={item.to}
                            className={({ isActive }) =>
                              cn(
                                'group flex items-center justify-start h-[54px] p-2.5 rounded-[4px] text-sm leading-6 font-normal',
                                isActive || extraActiveLink
                                  ? 'bg-blue-b1 text-white hover:text-white hover:bg-blue-b1'
                                  : 'text-black-b1 hover:text-gray-900 hover:bg-gray-g1'
                              )
                            }
                            onClick={(e) => {
                              if (item.disabled) {
                                e.preventDefault();
                                e.stopPropagation();
                              } else {
                                setSidebarOpen(false);
                              }
                            }}
                          >
                            {({ isActive }) => (
                              <>
                                <item.icon
                                  fill={isActive || extraActiveLink ? colors.white.default : colors.black.default}
                                  aria-hidden="true"
                                  className="shrink-0 ml-[0.3rem]"
                                />
                                <div className="truncate">
                                  <span className="ml-3">{item.name}</span>
                                  {item.count ? <span className="ml-3 w-2 h-2 rounded-full bg-red-r1"></span> : null}
                                </div>
                              </>
                            )}
                          </NavLink>
                        );
                      })}
                    </nav>
                    <div className={cn('transition-all duration-300 ease-in-out', collapseSidebar ? 'p-6' : 'py-6 px-3')}>
                      <div
                        className={cn(
                          'group flex items-center justify-start h-[54px] p-2.5 rounded-[4px] cursor-pointer text-sm leading-6 font-normal text-black-b1 hover:text-gray-900 hover:bg-gray-g1'
                        )}
                        onClick={() =>
                          dispatch(fetchLogoutThunk())
                            .unwrap()
                            .then(() => navigate('/', { replace: true }))
                            .catch(() => navigate('/', { replace: true }))
                        }
                      >
                        <ExitIcon aria-hidden="true" className="shrink-0 ml-[0.3rem]" />
                        <span className="ml-3 truncate">Log out</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className={cn('flex flex-shrink-0 border-t border-gray-g2 transition-all duration-300 ease-in-out', collapseSidebar ? 'p-6' : 'py-6 px-3')}
                  >
                    <Link to="/profile" className="group block w-full flex-shrink-0" onClick={() => setSidebarOpen(false)}>
                      <div className="flex items-center">
                        <div className="shrink-0 ml-[0.3rem]">
                          <ProfileAvatar />
                        </div>
                        <div className="ml-3 truncate">
                          <p className="text-sm leading-6 font-normal text-black-b1 group-hover:text-gray-900">{user?.full_name}</p>
                          <p className="text-[10px] leading-6 font-normal text-gray-gm group-hover:text-gray-400">{user?.email}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div
        className={cn(
          'hidden lg:fixed lg:top-[72px] lg:left-0 lg:bottom-0 lg:z-[1000] lg:flex transition-all duration-300 ease-in-out',
          collapseSidebar ? 'w-64' : 'w-[78px]'
        )}
      >
        {/* top = pading(p-6 = 24px) + navItem(54px) - toggle(h-8 = 32px) / 2 */}
        <div className="absolute top-[35px] -right-4 flex">
          <span
            className="w-8 h-8 flex items-center justify-center cursor-pointer shadow-s7 rounded-full border border-gray-g2 bg-white"
            onClick={handleCollapseSidebar}
          >
            {collapseSidebar ? <ArrowLeftIcon /> : <ArrowRightIcon />}
          </span>
        </div>

        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col w-full border-r border-gray-g2 bg-white">
          <div className="flex flex-1 flex-col overflow-y-auto">
            <nav
              className={cn('flex-1 space-y-1 bg-white transition-all duration-300 ease-in-out', collapseSidebar ? 'p-6' : 'py-6 px-3')}
              aria-label="Sidebar"
            >
              {navigationByUserRole.map((item) => {
                const extraActiveLink = item?.activeLinks ? item.activeLinks.some((d: string) => location.pathname.includes(d)) : false;

                return (
                  <NavLink
                    key={item.name}
                    to={item.to}
                    className={({ isActive }) =>
                      cn(
                        'group flex items-center justify-start h-[54px] p-2.5 rounded-[4px] text-sm leading-6 font-normal',
                        isActive || extraActiveLink
                          ? 'bg-blue-b1 text-white hover:text-white hover:bg-blue-b1'
                          : 'text-black-b1 hover:text-gray-900 hover:bg-gray-g1'
                      )
                    }
                    onClick={(e) => {
                      if (item.disabled) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                  >
                    {({ isActive }) => (
                      <>
                        <item.icon
                          fill={isActive || extraActiveLink ? colors.white.default : colors.black.default}
                          aria-hidden="true"
                          className="shrink-0 ml-[0.3rem]"
                        />
                        <div className="truncate">
                          <span className="ml-3">{item.name}</span>
                          {item.count ? <span className="ml-3 w-2 h-2 rounded-full bg-red-r1"></span> : null}
                        </div>
                      </>
                    )}
                  </NavLink>
                );
              })}
            </nav>
            <div className={cn('transition-all duration-300 ease-in-out', collapseSidebar ? 'p-6' : 'py-6 px-3')}>
              <div
                className={cn(
                  'group flex items-center justify-start h-[54px] p-2.5 rounded-[4px] cursor-pointer text-sm leading-6 font-normal text-black-b1 hover:text-gray-900 hover:bg-gray-g1'
                )}
                onClick={() =>
                  dispatch(fetchLogoutThunk())
                    .unwrap()
                    .then(() => navigate('/', { replace: true }))
                    .catch(() => navigate('/', { replace: true }))
                }
              >
                <ExitIcon aria-hidden="true" className="shrink-0 ml-[0.3rem]" />
                <span className="ml-3 truncate">Log out</span>
              </div>
            </div>
          </div>
          <div className={cn('flex flex-shrink-0 border-t border-gray-g2 transition-all duration-300 ease-in-out', collapseSidebar ? 'p-6' : 'py-6 px-3')}>
            <Link to="/profile" className="group block w-full flex-shrink-0">
              <div className="flex items-center">
                <div className="shrink-0 ml-[0.3rem]">
                  <ProfileAvatar />
                </div>
                <div className="ml-3 truncate">
                  <p className="text-sm leading-6 font-normal text-black-b1 group-hover:text-gray-900">{user?.full_name}</p>
                  <p className="text-[10px] leading-6 font-normal text-gray-gm group-hover:text-gray-400">{user?.email}</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
