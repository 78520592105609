import { FC } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useParams } from 'react-router-dom';
import { colors } from 'variables';
import { Tabs } from 'shared';
import {
  campaignActions,
  fetchCampaignEngagementDataThunk,
  fetchCampaignManagementDataThunk,
  fetchCampaignSelectedChannelsThunk,
  fetchCampaignThunk,
  selectCampaign,
  selectCampaignEngagementData,
  selectCampaignLoading,
  selectCampaignManagementData,
  selectCampaignReportIds,
  selectCampaignSelectedChannelsCount,
} from './reducer';
import { Influencers } from './tabs/Influencers';
import { Selected } from './tabs/Selected';
import { Engagement } from './tabs/Engagement';
import { Management } from './tabs/Management';
import { Report } from './tabs/Report';
import { NoCampaign } from './empties/NoCampaign';
import { StarIcon, StarSolidIcon } from 'assets/icons';
import { CampaignType } from '../../shared/enums/campaign_type';

export const Campaign: FC = () => {
  const { campaignId } = useParams();
  const dispatch = useAppDispatch();
  const campaign = useAppSelector(selectCampaign);
  const selectedChannelsCount = useAppSelector(selectCampaignSelectedChannelsCount);
  const engagementData = useAppSelector(selectCampaignEngagementData);
  const managementData = useAppSelector(selectCampaignManagementData);
  const reportIds = useAppSelector(selectCampaignReportIds);
  const loading = useAppSelector(selectCampaignLoading);

  useLegacyEffect(() => {
    if (campaignId)
      dispatch(fetchCampaignThunk(campaignId))
        .unwrap()
        .then(() => {
          dispatch(fetchCampaignSelectedChannelsThunk(campaignId));
          dispatch(fetchCampaignEngagementDataThunk(campaignId));
          dispatch(fetchCampaignManagementDataThunk(campaignId));
        });
  }, [campaignId]);

  useLegacyEffect(() => {
    return () => {
      dispatch(campaignActions.resetState());
    };
  }, []);

  if (campaign === undefined) return <div></div>; // TODO skeleton
  if (campaign === null) return <NoCampaign />;

  return (
    <>
      <Tabs
        classRoot="relative pt-6"
        tabs={[
          {
            query: 'influencers',
            title: 'Influencers',
            content: <Influencers />,
            panelClass: '!p-0',
          },
          {
            query: 'selected',
            title: ({ selected }: any) => (
              <div className="flex items-center">
                {selectedChannelsCount ? (
                  <StarSolidIcon className="mr-1.5" pathFill={selected ? colors.blue.b1 : colors.black.b1} aria-hidden="true" />
                ) : (
                  <StarIcon pathFill={selected ? colors.blue.b1 : colors.black.b1} className="mr-1.5" aria-hidden="true" />
                )}
                {`Selected${selectedChannelsCount ? ` (${selectedChannelsCount})` : ''}`}
              </div>
            ),
            content: <Selected />,
            panelClass: '!p-0',
          },
          {
            query: 'engagement',
            title: `Engagement${engagementData.length ? ` (${engagementData.length})` : ''}`,
            content: <Engagement />,
            panelClass: '!p-0',
            excludeIf: () => campaign.type === CampaignType.ORGANIC,
          },
          {
            query: 'management',
            title: `Campaign management${managementData.length ? ` (${managementData.length})` : ''}`,
            content: <Management />,
            panelClass: '!p-0',
          },
          {
            query: 'report',
            title: `Report${reportIds.length ? ` (${reportIds.length})` : ''}`,
            content: <Report />,
            panelClass: '!p-0',
          },
        ].filter((tab) => !(tab.excludeIf && tab.excludeIf()))}
      />
    </>
  );
};
