import { FC, useMemo, useState } from 'react';
import { Location, useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import WordCloud from 'react-d3-cloud';
import moment from 'moment';
import { colors } from 'variables';
import cn from 'classnames';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel, AccordionItemState } from 'react-accessible-accordion';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { max, orderBy, uniq } from 'lodash';
import { formatNumber, getTooltip } from 'utils/helper';
import { Breadcrumbs, Menu } from 'shared';
import { ROLE, UserHasRequiredRole } from 'app/RequiredRole';
import { createCampaignSelectedChannelThunk, selectCampaign } from 'pages/Campaign/reducer';
import {
  saveChannelListThunk,
  selectInfluencerObject,
  selectInfluencerPlatformObject,
  selectInfluencerTwitch,
  selectInfluencerYoutube,
  updateInfluencerByChannelIdThunk,
} from 'pages/Influencers/reducer';
import { PopupDescription } from 'components/PopupDescription';
import { navigation } from 'components/Sidebar';
import { showModal } from 'components/Modal/reducer';
import { ModalTypes } from 'components/Modal';
import { LanguageFullName } from 'components/LanguageFullName';
import { CountryFullName } from 'components/CountryFullName';
import {
  ArrowDownDropdownSolidIcon,
  ArrowDownSmallIcon,
  // ArrowLeftIcon,
  // ArrowRightIcon,
  ArrowUpDropdownSolidIcon,
  ArrowUpSmallIcon,
  CalendarSmallSolidIcon,
  ClockBigSolidIcon,
  CloudSmallSolidIcon,
  // ComedyIcon,
  DocumentSmallSolidIcon,
  EditIcon,
  // EducationIcon,
  // EntertainmentIcon,
  EyeOpenBigSolidIcon,
  // FacebookChannelSmallIcon,
  // FilmAnimationIcon,
  FlagSmallSolidIcon,
  // FoodIcon,
  GameSmallSolidIcon,
  // GamingIcon,
  HeartBigSolidIcon,
  InstagramChannelSmallIcon,
  // KnowledgeIcon,
  LaptopSmallSolidIcon,
  // LifestyleIcon,
  MessageSmallSolidIcon,
  // MoviesIcon,
  // MusicIcon,
  // NewsPoliticsIcon,
  // PeopleIcon,
  PuzzlePieceSmallSolidIcon,
  // ScienceIcon,
  // SportsIcon,
  TikTokChannelSmallIcon,
  // TravelIcon,
  // TrendDownIcon,
  // TrendUpIcon,
  TwitchChannelSmallIcon,
  TwitchLogoIcon,
  TwitterChannelSmallIcon,
  UserBigSolidIcon,
  VectorIcon,
  VideoBigSolidIcon,
  YoutubeChannelSmallIcon,
  YoutubeLogoIcon,
} from 'assets/icons';

import './styles.css';
import 'pure-react-carousel/dist/react-carousel.es.css';

const MIN_DESCRIPTION_SIZE = 300;
const MAX_DESCRIPTION_SIZE = 1000;

interface IDetailsProps {
  role: ROLE;
}

export const Details: FC<IDetailsProps> = ({ role }) => {
  const params = useParams();
  const location: Location = useLocation();

  const dispatch = useAppDispatch();
  const influencer = useAppSelector(selectInfluencerObject);
  const platformObject = useAppSelector(selectInfluencerPlatformObject);
  const youtube = useAppSelector(selectInfluencerYoutube);
  const twitch = useAppSelector(selectInfluencerTwitch);
  const campaign = useAppSelector(selectCampaign);
  const disabledByRole = UserHasRequiredRole([ROLE.indie]);

  const [showMore, setShowMore] = useState<boolean>(false);

  const isUserRole = role === ROLE.user;

  const descriptionText = useMemo(() => {
    const showNumber = showMore ? MAX_DESCRIPTION_SIZE : MIN_DESCRIPTION_SIZE;

    if (!platformObject?.channel_description) return '';
    return platformObject?.channel_description.substring(0, showNumber) + (platformObject?.channel_description.length > showNumber ? '...' : '');
  }, [showMore, platformObject?.channel_description]);

  const influencerName = useMemo(
    () =>
      influencer
        ? influencer.first_name && influencer.last_name
          ? influencer.first_name + ' ' + influencer.last_name
          : influencer.first_name || influencer.last_name || influencer.full_name || ''
        : '',
    [influencer]
  );

  const tags = useMemo(() => {
    if (platformObject?.top_tags && platformObject.top_tags !== 'undefined') {
      const _tags = JSON.parse(platformObject.top_tags) || {};
      const _max: any = max(Object.values(_tags));
      return orderBy(
        Object.keys(_tags).map((d: any) => ({
          text: d,
          value: (_tags[d] * 350) / _max,
        })),
        'value',
        'desc'
      ).slice(0, 30);
    }
    return [];
  }, [platformObject?.top_tags]);

  const breadcrumbs = useMemo(() => {
    let arr: any[] = [];

    const navItem = navigation.admin.find((d: any) => location.pathname.includes(d.to));

    if (navItem) {
      arr.push({ name: navItem.name, to: navItem.to });
      if (navItem?.breadcrumbs?.length) {
        navItem.breadcrumbs.forEach((d: any) => {
          arr.push({
            name: d?.name || (d.id === 'campaignId' ? campaign?.campaign_name || params?.[d.id] : params?.[d.id]) || '',
            to: d.to?.(params?.[d.id]) || d.to,
          });
        });
      }
    }

    arr.push({ name: influencerName, to: '' });

    return arr;
  }, [params, location, influencerName, campaign]);

  if (!influencer) return null;

  return (
    <>
      <div className="p-6 pb-0">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl leading-[42px] font-semibold">{influencerName}</h1>
          <div className="flex items-center space-x-4">
            {/* <Menu
              className="flex items-center justify-center"
              itemsClass="top-[2.7rem] !left-0" // min-w-[154px]
              optionClass="!px-4"
              items={[
                {
                  label: 'Excel',
                  onClick: () => {},
                },
                {
                  label: 'CSV',
                  onClick: () => {},
                },
              ]}
            >
              {({ open }) => (
                <button
                  type="button"
                  className={cn(
                    'inline-flex items-center justify-center w-[42px] h-[42px] rounded-[4px] border border-transparent shadow-sm focus:outline-none focus:ring-0',
                    open ? 'bg-blue-b1' : 'bg-white'
                  )}
                >
                  <ExportIcon pathFill={open ? colors.white.default : colors.blue.b1} aria-hidden="true" />
                </button>
              )}
            </Menu> */}
            {!isUserRole && (
              <Menu
                className="flex items-center justify-center"
                itemsClass="!origin-top-right !left-auto top-[2.7rem] right-0 w-[154px]"
                optionClass="!px-4"
                items={[
                  {
                    label: 'Campaign',
                    disabled: Boolean(!influencer['youtube_channel_id'] && !influencer['twitch_channel_id']) || disabledByRole,
                    onClick: () =>
                      dispatch(
                        showModal({
                          type: ModalTypes.AddToCampaign,
                          params: {
                            handleSave: async (data: any) =>
                              await dispatch(
                                createCampaignSelectedChannelThunk({
                                  campaign_id: data.campaign_id,
                                  data: {
                                    sm_platform: influencer['youtube_channel_id'] ? 'youtube' : 'twitch',
                                    channel_ids: [influencer['youtube_channel_id'] || influencer['twitch_channel_id']],
                                  },
                                })
                              ),
                          },
                        })
                      ),
                  },
                  {
                    label: 'Saved List',
                    onClick: () =>
                      dispatch(
                        showModal({
                          type: ModalTypes.SaveChannelList,
                          params: {
                            handleSave: async (obj: any) =>
                              await dispatch(
                                saveChannelListThunk({
                                  ...obj,
                                  ids: [influencer['youtube_channel_id'] || influencer['twitch_channel_id']],
                                })
                              ),
                          },
                        })
                      ),
                  },
                ]}
              >
                {({ open }) => (
                  <button
                    type="button"
                    className="inline-flex items-center space-x-3 h-[42px] rounded-[4px] border border-transparent bg-blue-b1 px-4 uppercase text-sm leading-6 font-medium text-gray-g1 hover:bg-blue-b1 focus:outline-none focus:ring-0 focus:ring-blue-b1 focus:ring-offset-0"
                  >
                    <p>ADD TO</p>
                    {open ? (
                      <ArrowUpDropdownSolidIcon className="ml-2" pathFill={colors.white.default} aria-hidden="true" />
                    ) : (
                      <ArrowDownDropdownSolidIcon className="ml-2" pathFill={colors.white.default} aria-hidden="true" />
                    )}
                  </button>
                )}
              </Menu>
            )}
          </div>
        </div>

        <Breadcrumbs paths={breadcrumbs} />
      </div>

      <div className="p-6 space-y-6 pt-0 overflow-auto">
        <div className="flex bg-white rounded-[4px]">
          {influencer?.picture_url ? (
            <div className="p-6 pr-0">
              <img
                className="w-[257px] h-[248px] shrink-0 rounded object-cover"
                src={influencer.picture_url}
                alt=""
                onError={(event: any) => {
                  setTimeout(() => {
                    event.target.src = influencer.picture_url;
                  }, 1000);
                }}
              />
            </div>
          ) : (
            <div className={cn('w-[257px] h-[248px] p-6 pr-0 rounded')}></div>
          )}
          <div className="flex flex-col flex-1 justify-between p-6">
            <div>
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
                    <MessageSmallSolidIcon />
                  </div>
                  <p className="ml-3.5 font-semibold text-base text-black-b1">Channel description</p>
                </div>
                {!isUserRole ? (
                  <div className="">
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        if (influencer?.youtube_channel_id || influencer?.twitch_channel_id)
                          dispatch(
                            showModal({
                              type: ModalTypes.EditInfluencerDescription,
                              params: {
                                influencer: platformObject,
                                handleSave: async (data: any) => {
                                  let obj = {};
                                  if (influencer.youtube_channel_id) {
                                    obj = {
                                      sm_platform: 'youtube',
                                      youtube_channel_id: influencer.youtube_channel_id,
                                    };
                                  } else if (influencer.twitch_channel_id) {
                                    obj = {
                                      sm_platform: 'twitch',
                                      twitch_channel_id: influencer.twitch_channel_id,
                                    };
                                  }

                                  return await dispatch(
                                    updateInfluencerByChannelIdThunk({
                                      data: {
                                        ...obj,
                                        ...data,
                                      },
                                    })
                                  );
                                },
                              },
                            })
                          );
                      }}
                    >
                      <EditIcon />
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="mt-6">
                <div className="text-sm leading-6 text-black-b1">
                  {descriptionText}
                  {descriptionText.length > MIN_DESCRIPTION_SIZE ? (
                    <span className="ml-3 cursor-pointer text-sm leading-6 text-blue-b1" onClick={() => setShowMore((prev) => !prev)}>
                      {showMore ? 'Show less' : 'Show more'}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="grid grid-cols-7 mt-6 text-sm leading-6">
              <div className="col-span-4 space-y-4">
                <div className="flex ">
                  <p className="w-[5.8rem] text-gray-gm">Email:</p>
                  <p className={'text-black-b1' + (influencer.email === 'hidden_for_user@example.com' && ' blur-sm')}>{influencer.email || ''}</p>
                </div>

                <div className="flex">
                  <p className="w-[5.8rem] text-gray-gm">Language:</p>
                  <LanguageFullName className="text-black-b1" language={influencer.language || ''} />
                </div>
                <div className="flex">
                  <p className="w-[5.8rem] text-gray-gm">Country:</p>
                  <CountryFullName className="text-black-b1" country={influencer.country || ''} />
                </div>
              </div>
              <div className="col-span-3 space-y-4">
                <div className="flex">
                  <p className="w-[7.5rem] text-gray-gm">Creation data:</p>
                  <p className="text-black-b1">{moment(platformObject?.channel_creation_date).format('MM/DD/YYYY')}</p>
                  {/* 08/14/2006 */}
                </div>
                {/* <div className="flex">
                  <p className="w-[7.5rem] text-gray-gm">Last updated:</p>
                  <p className="text-black-b1">--/--/----</p>
                </div> */}
                <div className="flex">
                  <p className="w-[7.5rem] text-gray-gm">Channels:</p>
                  <div className="flex space-x-3">
                    {influencer.twitter_url ? (
                      <a href={influencer.twitter_url} target="_blank" rel="noreferrer">
                        <TwitterChannelSmallIcon />
                      </a>
                    ) : null}
                    {/* {influencer.facebook_url ? (
                      <a href={influencer.facebook_url} target="_blank" rel="noreferrer">
                        <FacebookChannelSmallIcon />
                      </a>
                    ) : null} */}
                    {twitch.map((tc: any) => (
                      <a href={tc.channel_url} target="_blank" rel="noreferrer">
                        <TwitchChannelSmallIcon />
                      </a>
                    ))}
                    {youtube.map((yc: any) => (
                      <a href={yc.channel_url || `https://www.youtube.com/channel/${yc.youtube_channel_id}`} target="_blank" rel="noreferrer">
                        <YoutubeChannelSmallIcon />
                      </a>
                    ))}
                    {influencer.instagram_url ? (
                      <a href={influencer.instagram_url} target="_blank" rel="noreferrer">
                        <InstagramChannelSmallIcon />
                      </a>
                    ) : null}
                    {influencer.tiktok_url ? (
                      <a href={influencer.tiktok_url} target="_blank" rel="noreferrer">
                        <TikTokChannelSmallIcon />
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <NetworksAccordion />
        </div>

        {tags.length ? (
          <div className="flex space-x-6">
            <div className="flex-1 space-y-6 shrink-0 p-6 bg-white rounded-[4px]">
              <div className="flex items-center">
                <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
                  <CloudSmallSolidIcon />
                </div>
                <p className="flex items-center ml-3.5 space-x-1 font-semibold text-base">
                  <p className="text-black-b1">Cloud tag</p>
                  <p className="text-gray-gm">{tags.length}</p>
                </p>
              </div>
              <WordCloud width={undefined} height={180} data={tags} padding={1} />
            </div>
          </div>
        ) : null}

        <div className="">
          <InfluencerAccordion />
        </div>
      </div>
    </>
  );
};

export const CustomizedXAxisTick: FC = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={20} textAnchor="middle" fill={colors.gray.gm} fontSize={14}>
        <tspan style={{ whiteSpace: 'nowrap' }}>{payload.value}</tspan>
      </text>
    </g>
  );
};

export const CustomizedYAxisTick: FC = (props: any) => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={3} textAnchor="end" fill={colors.gray.gm} fontSize={14}>
        <tspan style={{ whiteSpace: 'nowrap' }}>{payload.value / 1000}k</tspan>
      </text>
    </g>
  );
};

export const CustomizedLabel = (props: any) => {
  const { x, y } = props;
  const barSize = 30;

  return (
    <g>
      <line x1={x - 3} y1={y} x2={x + barSize + 3} y2={y} stroke={colors.blue.b1} />
    </g>
  );
};

export const CustomizedCursor = (props: any) => {
  const { x, y, width, height } = props;

  return (
    <g>
      {/* <circle cx={x + width / 2} cy={y - 4} r={4} fill="#8884d8" /> */}
      <line x1={x + width / 2 - 1} y1={y} x2={x + width / 2 - 1} y2={y + height} stroke={colors.gray.gm} strokeDasharray="2 2" />
    </g>
  );
};

export const CustomizedTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="px-8 py-2 rounded-[2px] bg-white shadow-s5 text-sm leading-6 text-black-b1">
        <p className="">{`${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export const InfluencerAccordion: FC = () => {
  const youtube = useAppSelector(selectInfluencerYoutube);
  const twitch = useAppSelector(selectInfluencerTwitch);

  const items: any[] = useMemo(
    () =>
      youtube
        .map((yc: any, i: number) => ({
          id: `yc${i}`,
          heading: `YouTube Channel "${yc.channel_name}"`,
          HeadingIcon: <YoutubeLogoIcon aria-hidden="true" />,
          content: <YoutubeChannel youtube={yc} />,
          data: yc,
        }))
        .concat(
          twitch.map((tc: any, i: number) => ({
            id: `tc${i}`,
            heading: `Twitch Channel "${tc.channel_name}"`,
            HeadingIcon: <TwitchLogoIcon aria-hidden="true" />,
            content: <TwitchChannel twitch={tc} />,
            data: tc,
          }))
        ),
    [youtube, twitch]
  );

  return <AccordionComponent items={items} />;
};

export const NetworksAccordion: FC = () => {
  const items: any[] = useMemo(
    () => [
      {
        id: 0,
        heading: 'Interests and Social network',
        content: <Networks />,
      },
    ],
    []
  );
  return <AccordionComponent items={items} />;
};

interface IAccordionComponent {
  items: any[];
}

export const AccordionComponent: FC<IAccordionComponent> = ({ items }) => {
  return (
    <Accordion allowMultipleExpanded allowZeroExpanded preExpanded={items.map((d) => d.id)}>
      {items.map((item: any) => (
        <AccordionItem key={item.id} uuid={item.id}>
          <AccordionItemState>
            {({ expanded }) => (
              <AccordionItemHeading className={cn(expanded ? 'pb-4' : '')}>
                <AccordionItemButton className="flex items-center space-x-3 text-black-b1 text-base font-semibold focus:outline-none">
                  <div className="flex items-center space-x-3.5">
                    {item.HeadingIcon ? item.HeadingIcon : null}
                    <span>{item.heading}</span>
                  </div>
                  {expanded ? <ArrowUpSmallIcon aria-hidden="true" /> : <ArrowDownSmallIcon aria-hidden="true" />}
                </AccordionItemButton>
              </AccordionItemHeading>
            )}
          </AccordionItemState>
          <AccordionItemPanel className="pt-2">{item.content}</AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

interface IYoutubeChannel {
  youtube: any;
}

export const YoutubeChannel: FC<IYoutubeChannel> = ({ youtube }) => {
  const youtubeChannelData: any[] = useMemo(
    () => [
      {
        name: 'Subscribers',
        value: youtube.subscribers,
        bg: 'bg-green-gl',
        iconColor: colors.green.g1,
        Icon: UserBigSolidIcon,
      },
      {
        name: 'Total videos',
        value: youtube.total_videos,
        bg: 'bg-pink-pl',
        iconColor: colors.pink.p1,
        Icon: VectorIcon,
      },
      {
        name: 'Total views',
        value: youtube.total_views,
        bg: 'bg-blue-bl',
        iconColor: colors.blue.b1,
        Icon: EyeOpenBigSolidIcon,
      },
      {
        name: 'Average views',
        value: youtube.median_views_20,
        column: 'median_views_20',
        bg: 'bg-green-gl2',
        iconColor: colors.green.g2,
        Icon: EyeOpenBigSolidIcon,
      },
      {
        name: 'Average likes',
        value: youtube.average_likes,
        column: 'average_likes',
        bg: 'bg-yellow-yl',
        iconColor: colors.yellow.y1,
        Icon: HeartBigSolidIcon,
      },
      {
        name: 'Recent videos',
        value: youtube.recent_videos_30,
        column: 'recent_videos_30',
        bg: 'bg-green-gl',
        iconColor: colors.green.g1,
        Icon: VideoBigSolidIcon,
      },
    ],
    [youtube]
  );

  const youtubeScoresData: any[] = useMemo(
    () => [
      {
        name: 'Engagement score',
        score: youtube.engagement_score,
        column: 'engagement_score',
        change: 1,
        format: '0.[00]%',
      },
      {
        name: 'Frequency',
        score: youtube.frequency_30,
        column: 'frequency_30',
        change: 0.1,
        format: '0,0.[00]',
      },
      {
        name: 'True reach',
        score: youtube.true_reach_20,
        column: 'true_reach_20',
        change: -1.2,
        format: '0.[00]%',
      },
    ],
    [youtube]
  );

  if (!youtube) return null;

  return (
    <div className="flex space-x-6 pb-6">
      <div className="w-[440px] shrink-0">
        <div className="grid grid-cols-2 gap-6">
          {youtubeChannelData.map((d: any) => (
            <div key={d.name} className="group flex items-center space-x-4 pl-4 pr-1 py-4 bg-white rounded-[4px]">
              <div className={cn('flex items-center justify-center shrink-0 w-[2.875rem] h-[2.875rem] rounded-lg', d.bg)}>
                <d.Icon pathFill={d.iconColor} />
              </div>
              <div className="flex-1">
                <div className="flex items-center justify-between">
                  <p className="text-sm leading-6 text-gray-gm truncate">{d.name}</p>
                  {d?.column && <PopupDescription content={getTooltip('profile', 'youtube', d.column)} alwaysVisible={true} />}
                </div>
                <p className="text-lg font-semibold text-black-b1">{formatNumber(d.value)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col flex-1 justify-between p-6 pr-2 bg-white rounded-[4px]">
        <div className="flex items-center pr-4">
          <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
            <FlagSmallSolidIcon />
          </div>
          <p className="ml-3.5 font-semibold text-base text-black-b1">Channel scores</p>
        </div>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          isIntrinsicHeight={true}
          totalSlides={youtubeScoresData.length}
          visibleSlides={3}
          step={1}
        >
          {/* <div className="mb-6 pr-3 space-x-6 flex items-center justify-end">
            <ButtonBack
              role="button"
              aria-label="slide backward"
              className="focus:outline-none"
              id="prev"
            >
              <ArrowLeftIcon />
            </ButtonBack>
            <ButtonNext
              role="button"
              aria-label="slide forward"
              className="focus:outline-none"
              id="next"
            >
              <ArrowRightIcon />
            </ButtonNext>
          </div> */}
          <Slider>
            {youtubeScoresData.map((score: any, i: number) => (
              <Slide key={i} index={i} className="pr-4">
                <div className="group relative w-full h-[175px] flex p-6 rounded-[4px] bg-gray-g1">
                  {/* <div className="absolute top-5 right-6 space-x-2 flex items-center">
                    {score.change >= 0 ? <TrendUpIcon /> : <TrendDownIcon />}
                    <p
                      className={cn(
                        'text-sm leading-6',
                        score.change >= 0 ? 'text-green-g1' : 'text-red-r1'
                      )}
                    >
                      {score.change > 0 ? '+' : ''}
                      {score.change}%
                    </p>
                  </div> */}
                  {score?.column && (
                    <PopupDescription
                      content={getTooltip('profile', 'youtube', score.column)}
                      position={i === youtubeScoresData.length - 1 ? 'right' : 'center'}
                      rootClass="absolute top-4 right-4"
                      alwaysVisible={true}
                    />
                  )}
                  <div className="flex flex-col flex-1 justify-between">
                    <p className="text-2xl leading-10 font-semibold text-black-b1">
                      {`${formatNumber(score.score, score.format, 'float')}${score.customFormat || ''}`}
                    </p>
                    <p className="flex items-end text-sm leading-6 text-black-b1">{score.name}</p>
                  </div>
                </div>
              </Slide>
            ))}
          </Slider>
        </CarouselProvider>
      </div>
    </div>
  );
};

interface ITwitchChannel {
  twitch: any;
}

export const TwitchChannel: FC<ITwitchChannel> = ({ twitch }) => {
  const games = useMemo(() => (twitch?.recently_streamed_games ? uniq(Object.keys(twitch.recently_streamed_games)) : []), [twitch?.recently_streamed_games]);

  const twitchChannelData: any[] = useMemo(
    () => [
      {
        name: 'Followers',
        value: twitch.followers,
        bg: 'bg-green-gl',
        iconColor: colors.green.g1,
        Icon: UserBigSolidIcon,
      },
      {
        name: 'Last seen',
        value: twitch?.last_seen || '',
        column: 'last_seen',
        bg: 'bg-blue-bl',
        iconColor: colors.blue.b1,
        Icon: CalendarSmallSolidIcon,
        fullFormat: (value: any) => (value ? moment(value).format('MM-DD-YYYY') : '-'),
      },
      {
        name: 'Avg peak viewers',
        value: twitch.average_peak_20,
        column: 'average_peak_20',
        bg: 'bg-blue-bl',
        iconColor: colors.blue.b1,
        Icon: EyeOpenBigSolidIcon,
      },
      {
        name: 'Avg CCV',
        value: twitch.aaccv_20,
        column: 'aaccv_20',
        bg: 'bg-pink-pl',
        iconColor: colors.pink.p1,
        Icon: VectorIcon,
      },
      {
        name: 'Hours live',
        value: twitch.hours_live_30,
        column: 'hours_live_30',
        bg: 'bg-yellow-yl',
        iconColor: colors.yellow.y1,
        Icon: ClockBigSolidIcon,
        customFormat: 'h',
      },
      {
        name: 'Views hours',
        value: twitch.viewer_hours_30 / 3600,
        column: 'viewer_hours_30',
        bg: 'bg-green-gl2',
        iconColor: colors.green.g2,
        Icon: ClockBigSolidIcon,
        customFormat: 'h',
      },
    ],
    [twitch]
  );

  const twitchScoresData: any[] = useMemo(
    () => [
      {
        name: 'Games Streamed',
        column: 'games',
        score: games.length,
        change: 1,
        format: '0,0',
      },
      {
        name: 'Daily broadcast time',
        score: twitch.daily_broadcast_30 / 3600,
        column: 'daily_broadcast_30',
        change: -1.2,
        format: '0,0.[00]',
        customFormat: 'h',
      },
      {
        name: 'Active Days per Week',
        score: twitch.days_week_30,
        column: 'days_week_30',
        change: 0.1,
        format: '0,0.[00]',
      },
    ],
    [twitch, games]
  );

  if (!twitch) return null;

  return (
    <div className="flex space-x-6 pb-6">
      <div className="w-[440px] shrink-0">
        <div className="grid grid-cols-2 gap-6">
          {twitchChannelData.map((d: any) => (
            <div key={d.name} className="group flex items-center space-x-4 pl-4 pr-1 py-4 bg-white rounded-[4px]">
              <div className={cn('flex items-center justify-center shrink-0 w-[2.875rem] h-[2.875rem] rounded-lg', d.bg)}>
                <d.Icon pathFill={d.iconColor} />
              </div>
              <div className="flex-1">
                <div className="flex items-center justify-between">
                  <p className="text-sm leading-6 text-gray-gm truncate">{d.name}</p>
                  {d?.column && <PopupDescription content={getTooltip('profile', 'twitch', d.column)} alwaysVisible={true} />}
                </div>
                <p className="text-lg font-semibold text-black-b1">
                  {d?.fullFormat ? d.fullFormat(d.value) : `${formatNumber(d.value)}${d.customFormat || ''}`}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col flex-1 justify-between p-6 pr-2 bg-white rounded-[4px]">
        <div className="flex items-center pr-4">
          <div className="flex items-center justify-center w-6 h-6 rounded-[8px] bg-black-b1">
            <FlagSmallSolidIcon />
          </div>
          <p className="ml-3.5 font-semibold text-base text-black-b1">Channel scores</p>
        </div>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          isIntrinsicHeight={true}
          totalSlides={twitchScoresData.length}
          visibleSlides={3}
          step={1}
        >
          {/* <div className="mb-6 pr-3 space-x-6 flex items-center justify-end">
            <ButtonBack
              role="button"
              aria-label="slide backward"
              className="focus:outline-none"
              id="prev"
            >
              <ArrowLeftIcon />
            </ButtonBack>
            <ButtonNext
              role="button"
              aria-label="slide forward"
              className="focus:outline-none"
              id="next"
            >
              <ArrowRightIcon />
            </ButtonNext>
          </div> */}
          <Slider>
            {twitchScoresData.map((score: any, i: number) => (
              <Slide key={i} index={i} className="pr-4">
                <div className="group relative w-full h-[175px] flex p-6 rounded-[4px] bg-gray-g1">
                  {/* <div className="absolute top-5 right-6 space-x-2 flex items-center">
                    {score.change >= 0 ? <TrendUpIcon /> : <TrendDownIcon />}
                    <p
                      className={cn(
                        'text-sm leading-6',
                        score.change >= 0 ? 'text-green-g1' : 'text-red-r1'
                      )}
                    >
                      {score.change > 0 ? '+' : ''}
                      {score.change}%
                    </p>
                  </div> */}
                  {score?.column && (
                    <PopupDescription
                      content={getTooltip('profile', 'twitch', score.column)}
                      position={i === twitchScoresData.length - 1 ? 'right' : 'center'}
                      rootClass="absolute top-4 right-4"
                      alwaysVisible={true}
                    />
                  )}
                  <div className="flex flex-col flex-1 justify-between">
                    <p className="text-2xl leading-10 font-semibold text-black-b1">
                      {`${formatNumber(score.score, score.format, 'float')}${score.customFormat || ''}`}
                    </p>
                    <p className="flex items-end text-sm leading-6 text-black-b1">{score.name}</p>
                  </div>
                </div>
              </Slide>
            ))}
          </Slider>
        </CarouselProvider>
      </div>
    </div>
  );
};

// const topicsData: any[] = [
//   {
//     name: 'Entertainment',
//     Icon: EntertainmentIcon,
//   },
//   {
//     name: 'Travel&Event',
//     Icon: TravelIcon,
//   },
//   {
//     name: 'Music',
//     Icon: MusicIcon,
//   },
//   {
//     name: 'Gaming',
//     Icon: GamingIcon,
//   },
//   {
//     name: 'Lifestyle',
//     Icon: LifestyleIcon,
//   },
//   {
//     name: 'Comedy',
//     Icon: ComedyIcon,
//   },
//   {
//     name: 'Science & Technology',
//     Icon: ScienceIcon,
//   },
//   {
//     name: 'Movies',
//     Icon: MoviesIcon,
//   },
//   {
//     name: 'Food',
//     Icon: FoodIcon,
//   },
//   {
//     name: 'Knowledge',
//     Icon: KnowledgeIcon,
//   },
//   {
//     name: 'Education',
//     Icon: EducationIcon,
//   },
//   {
//     name: 'People & Blogs',
//     Icon: PeopleIcon,
//   },
//   {
//     name: 'News & Politics',
//     Icon: NewsPoliticsIcon,
//   },
//   {
//     name: 'Sports',
//     Icon: SportsIcon,
//   },
//   {
//     name: 'Film & Animation',
//     Icon: FilmAnimationIcon,
//   },
// ]

export const Networks: FC = () => {
  const influencer = useAppSelector(selectInfluencerObject);
  const platformObject = useAppSelector(selectInfluencerPlatformObject);
  const youtube = useAppSelector(selectInfluencerYoutube);
  const twitch = useAppSelector(selectInfluencerTwitch);

  const topics = useMemo(() => (platformObject?.categories ? Object.keys(JSON.parse(platformObject.categories)) : ['Gaming']), [platformObject?.categories]);

  const genres = useMemo(() => influencer.genres || [], [influencer.genres]);

  const games = useMemo(() => {
    const sortable = (obj: Object) => Object.entries(obj).sort(([, a], [, b]) => b - a);

    const youtube_games = sortable(youtube.reduce((games: object, channel: any) => ({ ...games, ...channel.youtube_games }), {}));
    const twitch_games = sortable(twitch.reduce((games: object, channel: any) => ({ ...games, ...channel.recently_streamed_games }), {}));
    const top_games = youtube_games.slice(0, 3).map(([k, v]) => k);

    for (const game of twitch_games) {
      if (!top_games.some((el) => el.toLowerCase() === game[0].toLowerCase())) {
        if (top_games.push(game[0]) === 6) {
          // 3 games from each platform
          break;
        }
      }
    }

    return top_games;
  }, [youtube, twitch]);

  const platforms = useMemo(() => (influencer?.platform ? influencer.platform.split(',') || [] : []), [influencer?.platform]);

  return (
    <div className="grid grid-cols-4 gap-6">
      <div className="group p-6 pr-3 bg-white rounded-[4px]">
        <div className="flex items-center justify-between pr-3">
          <div className="flex">
            <div className="flex items-center justify-center shrink-0 w-6 h-6 rounded-[8px] bg-black-b1">
              <DocumentSmallSolidIcon />
            </div>
            <span className="flex items-center ml-3.5 space-x-2 font-semibold text-base">
              <p className="text-black-b1">Channel Topics</p>
              <p className="text-gray-gm">{topics.length}</p>
            </span>
          </div>
          <PopupDescription content={getTooltip('profile', 'youtube', 'categories')} alwaysVisible={true} />
        </div>
        <div className="max-h-60 overflow-y-auto mt-6 pr-3 space-y-3">
          {topics.map((d: any, i: number) => (
            <div key={d + i} className="flex items-center h-[50px] space-x-2.5 shadow-s6">
              <p className="mb-2.5 text-sm leading-6 text-black-b1">{d}</p>
            </div>
          ))}
          {/* {topicsData.map((d: any, i: number) => (
            <div
              key={d.name + i}
              className="flex items-center h-[50px] space-x-2.5 bg-gray-g1 px-2.5 rounded-[4px]"
            >
              <d.Icon />
              <p className="text-sm leading-6 text-black-b1">{d.name}</p>
            </div>
          ))} */}
        </div>
      </div>
      <div className="group p-6 pr-3 bg-white rounded-[4px]">
        <div className="flex items-center justify-between pr-3">
          <div className="flex">
            <div className="flex items-center justify-center shrink-0 w-6 h-6 rounded-[8px] bg-black-b1">
              <GameSmallSolidIcon />
            </div>
            <span className="flex items-center ml-3.5 space-x-2 font-semibold text-base">
              <p className="text-black-b1">Favorite Games</p>
              <p className="text-gray-gm">{games.length}</p>
            </span>
          </div>
          <PopupDescription content={getTooltip('profile', 'youtube + twitch', 'favourite_games')} alwaysVisible={true} />
        </div>
        <div className="max-h-60 overflow-y-auto mt-6 pr-3 space-y-3">
          {games.map((d: any, i: number) => (
            <div key={d + i} className="flex items-center h-[50px] space-x-2.5 shadow-s6">
              <p className="mb-2.5 text-sm leading-6 text-black-b1">{d}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="group p-6 pr-3 bg-white rounded-[4px]">
        <div className="flex items-center justify-between pr-3">
          <div className="flex">
            <div className="flex items-center justify-center shrink-0 w-6 h-6 rounded-[8px] bg-black-b1">
              <PuzzlePieceSmallSolidIcon />
            </div>
            <span className="flex items-center ml-3.5 space-x-2 font-semibold text-base">
              <p className="text-black-b1">Favorite Genres</p>
              <p className="text-gray-gm">{genres.length}</p>
            </span>
          </div>
          <PopupDescription content={getTooltip('profile', 'youtube + twitch', 'genres')} alwaysVisible={true} />
        </div>
        <div className="max-h-60 overflow-y-auto mt-6 pr-3 space-y-3">
          {genres.map((d: any, i: number) => (
            <div key={d + i} className="flex items-center h-[50px] space-x-2.5 shadow-s6">
              <p className="mb-2.5 text-sm leading-6 text-black-b1">{d}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="group p-6 pr-3 bg-white rounded-[4px]">
        <div className="flex items-center justify-between pr-3">
          <div className="flex">
            <div className="flex items-center justify-center shrink-0 w-6 h-6 rounded-[8px] bg-black-b1">
              <LaptopSmallSolidIcon />
            </div>
            <span className="flex items-center ml-3.5 space-x-2 font-semibold text-base">
              <p className="text-black-b1">Top Platforms</p>
              <p className="text-gray-gm">{platforms.length}</p>
            </span>
          </div>
          <PopupDescription content={getTooltip('profile', 'youtube + twitch', 'platform')} position="right" alwaysVisible={true} />
        </div>
        <div className="max-h-60 overflow-y-auto mt-6 pr-3 space-y-3">
          {platforms.map((d: any, i: number) => (
            <div key={d + i} className="flex items-center h-[50px] space-x-2.5 shadow-s6">
              <p className="mb-2.5 text-sm leading-6 text-black-b1">{d}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
