import { FC, forwardRef, memo, useImperativeHandle, useMemo, useRef, useCallback } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useSMPlatforms } from 'hooks/useSMPlatforms';
import { SingleValue } from 'react-select';
import { useBlockLayout, useSortBy, useTable, useResizeColumns, useRowSelect } from 'react-table';
import { useVirtual } from 'react-virtual';
import { isEqual, orderBy } from 'lodash';
import moment from 'moment';
import cn from 'classnames';
import { colors } from 'variables';
import { Platform } from 'services';
import { approvalSelectedStatuses, contentFormatStatuses, outreachStatuses, platformSelectedConfig, tags } from 'utils/common';
import {
  localeSort,
  dateSort,
  filterCampaignChannels,
  formatNumber,
  getCpm,
  getCpmSelectedDenominator,
  getTooltip,
  getTotalForCPM,
  numberSort,
  replaceSpaceToUnderscore,
  statusSort,
} from 'utils/helper';
import { showChat } from 'components/Chat/reducer';
import { showModal } from 'components/Modal/reducer';
import { ModalTypes } from 'components/Modal';
import { SelectedEmpty } from '../empties/SelectedEmpty';
import {
  getTotalForColumn,
  PlatformSelectCustomSingleValue,
  PlatformSelectOption,
  platformSelectStyles,
  IndeterminateCheckbox,
} from 'pages/Influencers/tabs/InfluencersTab';
import { SimpleSearch } from 'components/SimpleSearch';
import { FormatSelect } from '../components/FormatSelect';
import { ChatTableView } from 'components/Chat/ChatTableView';
import { Breadcrumbs, HSelect, MenuActionWithFloat, RSelect, TableHeaderSort, TableCellTruncate, TableResizer, ActionMenu, TableHeaderTruncate } from 'shared';
import {
  campaignActions,
  deleteCampaignSelectedChannelThunk,
  deleteCampaignSelectedChannelsThunk,
  fetchCampaignSelectedChannelsThunk,
  updateCampaignSelectedChannelsThunk,
  selectCampaign,
  selectCampaignSelectedChannelsByPlatform,
  selectCampaignSelectedChannelsCount,
  selectCampaignSelectedSettingsFilters,
  selectCampaignSelectedSettingsPlatform,
  selectCampaignSelectedSettingsSearchValue,
  selectCampaignSelectedSettingsTableFilter,
  selectCampaignSelectedSettingsTableFilterHiddenColumns,
  selectCampaignSelectedSettingsTableFilterResizing,
  selectCampaignSelectedSettingsTableFilterSelectedRows,
  selectCampaignSelectedSettingsTableFilterSortBy,
  updateCampaignSelectedChannelThunk,
  updateCampaignEngagementDataThunk,
} from '../reducer';
import { selectRole } from 'pages/Auth/reducer';
import { selectAgencies } from 'pages/Admin/reducer';
import { PopupDescription } from 'components/PopupDescription';
import { ExportMenu } from 'components/ExportMenu';
import { LanguageFullName } from 'components/LanguageFullName';
import { CountryFullName } from 'components/CountryFullName';
import { Bars3Icon, LinkIcon, DotsIcon, EyeOpenIcon, EyeCloseIcon, UploadCloudIcon } from 'assets/icons';

import './styles.css';
import { CampaignType } from '../../../shared/enums/campaign_type';

const getTag = (row: any, field: string, campaignType: string) => {
  return row.published
    ? tags.report[field]
    : row.in_management
      ? tags.management[field]
      : row.in_engagement && campaignType !== CampaignType.ORGANIC
        ? tags.engagement[field]
        : '';
};

export const getStatus = (status: string, statuses: any[]) => {
  if (!status) {
    return statuses.find((d: any) => d.default);
  } else return statuses.find((d: any) => d.value === status);
};

const changedColumnContentFormats: any[] = ['short'];

export const checkContentFormat = (cf: any) => changedColumnContentFormats.includes(cf);

export const selectedColumnsForContentFormat = {
  median_views_20: 'short_views',
  true_reach_20: 'short_true_reach',
  engagement_score: 'short_eng_score',
  average_likes: 'short_likes',
  average_comments: 'short_comments',
};

export const engagementColumnsForContentFormat = {
  average_views: 'short_views',
};

export const getColumnFieldByContentFormat: any = {
  short: {
    // Selected
    ...selectedColumnsForContentFormat,

    // Engagement, Management
    ...engagementColumnsForContentFormat,
  },
};

export const getTotalForContentFormat = (rows: any[], accessor: string) =>
  rows.reduce(
    (sum: number, row: any) =>
      parseFloat(
        checkContentFormat(row.original.content_format)
          ? getColumnFieldByContentFormat[row.original.content_format]?.[accessor]
            ? row.original[getColumnFieldByContentFormat[row.original.content_format][accessor]] || 0
            : 0
          : row.values[accessor] || 0
      ) + sum,
    0
  ) || 0;

const getSelectedIds = (row: { original: { selected_id: any }; id: { toString: () => any } }, state: { selectedRowIds: {} }, data: any[]) => {
  let selected_ids = [row.original.selected_id];
  const checkedRows = Object.keys(state.selectedRowIds);
  const currentId = row.id.toString();
  if (checkedRows.includes(currentId)) {
    selected_ids = data.filter((item: any, index: number) => checkedRows.includes(index.toString())).map((item: any) => item.selected_id);
  }

  return selected_ids;
};

export const platformSelectedColumns = (props: any): any => {
  const { dispatch, campaignId, agencies, campaignType } = props;

  return {
    youtube: [
      {
        Header: ({ getToggleAllRowsSelectedProps }: any) => (
          <div className="flex items-center space-x-2 truncate">
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="border-2 bg-gray-gd border-white rounded-[2px]" />
            <TableHeaderTruncate name={platformSelectedConfig.youtube.channel_name.name} />
          </div>
        ),
        accessor: 'channel_name',
        Cell: ({ row, value }: any) => {
          return (
            <div className="flex flex-1 items-center justify-between overflow-hidden">
              <div className="flex items-center truncate space-x-2 overflow-hidden">
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} className="border-2 border-gray-gm rounded-[2px]" />
                {row.original.shared_with_client ? (
                  <EyeOpenIcon
                    className="cursor-pointer shrink-0"
                    pathFill={colors.blue.b1}
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();

                      dispatch(
                        updateCampaignSelectedChannelThunk({
                          campaign_id: campaignId || '',
                          selected_id: row.original.selected_id,
                          data: {
                            shared_with_client: false,
                          },
                        })
                      );
                    }}
                  />
                ) : (
                  <EyeCloseIcon
                    className="cursor-pointer shrink-0"
                    pathFill={colors.blue.b1}
                    onClick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();

                      dispatch(
                        updateCampaignSelectedChannelThunk({
                          campaign_id: campaignId || '',
                          selected_id: row.original.selected_id,
                          data: {
                            shared_with_client: true,
                          },
                        })
                      );
                    }}
                  />
                )}
                <TableCellTruncate
                  linkTo={row.original?.influencer_id != null ? `/campaigns/${campaignId}/${row.original.influencer_id}` : undefined}
                  value={value}
                />
                <a href={row.original.channel_url} target="_blank" rel="noopener noreferrer">
                  <LinkIcon />
                </a>
              </div>

              {getTag(row.original, 'full_label', campaignType) ? (
                <div className={cn('flex items-center justify-center px-3 rounded bg-gray-g1')}>
                  <p className="text-[10px] leading-4 text-gray-gd">{getTag(row.original, 'full_label', campaignType)}</p>
                </div>
              ) : null}
            </div>
          );
        },
        Footer: 'Total',
        width: 350,
        sortType: localeSort,
      },
      {
        Header: platformSelectedConfig.youtube.channel_description.name,
        accessor: 'channel_description',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 220,
      },
      {
        Header: platformSelectedConfig.youtube.channel_creation_date.name,
        accessor: 'channel_creation_date',
        Cell: (row: any) => <TableCellTruncate value={moment(row.value).format('DD MMM YYYY')} />,
        width: 130,
        sortType: dateSort,
      },
      {
        Header: platformSelectedConfig.youtube.approval.name,
        accessor: 'approval',
        Cell: ({ row, value, state, data }: any) => (
          <FormatSelect
            value={value}
            options={approvalSelectedStatuses}
            onChange={(value: string) => {
              if (value)
                dispatch(
                  updateCampaignSelectedChannelsThunk({
                    campaign_id: campaignId || '',
                    data: {
                      approval: value,
                      selected_ids: getSelectedIds(row, state, data),
                    },
                  })
                );
            }}
          />
        ),
        width: 130,
        sortType: (a: any, b: any, id: any) => statusSort(a, b, id, approvalSelectedStatuses),
      },
      {
        Header: platformSelectedConfig.youtube.outreach_status.name,
        accessor: platformSelectedConfig.youtube.outreach_status.id,
        Cell: ({ row, value, state, data }: any) => (
          <FormatSelect
            isDisabled={campaignType !== CampaignType.ORGANIC}
            noDropdownIndicator={campaignType !== CampaignType.ORGANIC}
            value={value}
            options={outreachStatuses}
            onChange={(value: string) => {
              if (value)
                dispatch(
                  updateCampaignEngagementDataThunk({
                    campaign_id: campaignId || '',
                    selected_ids: getSelectedIds(row, state, data),
                    data: {
                      outreach_status: value,
                    },
                  })
                );
            }}
          />
        ),
        width: 185,
        minWidth: 185,
        sortType: (a: any, b: any, id: any) => statusSort(a, b, id, outreachStatuses),
      },
      {
        Header: platformSelectedConfig.youtube.content_format.name,
        accessor: 'content_format',
        Cell: ({ row, value, state, data }: any) => (
          <FormatSelect
            value={value}
            options={contentFormatStatuses}
            onChange={(value: string) => {
              if (value)
                dispatch(
                  updateCampaignSelectedChannelsThunk({
                    campaign_id: campaignId || '',
                    data: {
                      content_format: value,
                      selected_ids: getSelectedIds(row, state, data),
                    },
                  })
                );
            }}
          />
        ),
        width: 180,
        minWidth: 180,
        sortType: (a: any, b: any, id: any) => statusSort(a, b, id, contentFormatStatuses),
      },
      {
        Header: platformSelectedConfig.youtube.theme.name,
        accessor: platformSelectedConfig.youtube.theme.id,
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: platformSelectedConfig.youtube.internal_price.name,
        accessor: 'internal_price',
        Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '$0,0.[00]', 'float')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'internal_price'), '$0,0.[00]', 'float')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.price.name,
        accessor: 'price',
        Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '$0,0.[00]', 'float')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'price'), '$0,0.[00]', 'float')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.internal_cpm.name,
        accessor: 'internal_cpm',
        Cell: ({ row }: any) => (
          <TableCellTruncate
            value={formatNumber(getCpm(row.original.internal_price, getCpmSelectedDenominator(row.original), row.original.sm_platform), '$0,0.[00]', 'float')}
          />
        ),
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForCPM(info.rows, 'internal_price'), '$0,0.[00]', 'float')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.cpm.name,
        accessor: 'cpm',
        Cell: ({ row }: any) => (
          <TableCellTruncate
            value={formatNumber(getCpm(row.original.price, getCpmSelectedDenominator(row.original), row.original.sm_platform), '$0,0.[00]', 'float')}
          />
        ),
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForCPM(info.rows, 'price'), '$0,0.[00]', 'float')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.subscribers.name,
        accessor: 'subscribers',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'subscribers'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.total_videos.name,
        accessor: 'total_videos',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'total_videos'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.total_views.name,
        accessor: 'total_views',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'total_views'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.recent_videos_60.name,
        accessor: 'recent_videos_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'recent_videos_60'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.recent_videos_30.name,
        accessor: 'recent_videos_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'recent_videos_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.median_views_60.name,
        accessor: 'median_views_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_views_60'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.median_views_30.name,
        accessor: 'median_views_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_views_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.median_views_20.name,
        accessor: 'median_views_20',
        Cell: (info: any) => {
          return (
            <TableCellTruncate
              value={formatNumber(checkContentFormat(info.row.original.content_format) ? info.row.original?.short_views : info.value, '0,0')}
            />
          );
        },
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForContentFormat(info.rows, 'median_views_20'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.mean_views_60.name,
        accessor: 'mean_views_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'mean_views_60'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.mean_views_30.name,
        accessor: 'mean_views_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'mean_views_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.mean_views_20.name,
        accessor: 'mean_views_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'mean_views_20'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.true_reach_60.name,
        accessor: 'true_reach_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'true_reach_60') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.true_reach_30.name,
        accessor: 'true_reach_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'true_reach_30') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.true_reach_20.name,
        accessor: 'true_reach_20',
        Cell: (info: any) => (
          <TableCellTruncate
            value={formatNumber(checkContentFormat(info.row.original.content_format) ? info.row.original?.short_true_reach : info.value, '0.[00]%', 'float')}
          />
        ),
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForContentFormat(info.rows, 'true_reach_20') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.frequency_60.name,
        accessor: 'frequency_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, undefined, 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'frequency_60') / info.rows.length, undefined, 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.frequency_30.name,
        accessor: 'frequency_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, undefined, 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'frequency_30') / info.rows.length, undefined, 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.engagement_score.name,
        accessor: 'engagement_score',
        Cell: (info: any) => (
          <TableCellTruncate
            value={formatNumber(checkContentFormat(info.row.original.content_format) ? info.row.original?.short_eng_score : info.value, '0.[00]%', 'float')}
          />
        ),
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForContentFormat(info.rows, 'engagement_score') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.average_likes.name,
        accessor: 'average_likes',
        Cell: (info: any) => (
          <TableCellTruncate value={formatNumber(checkContentFormat(info.row.original.content_format) ? info.row.original?.short_likes : info.value, '0,0')} />
        ),
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForContentFormat(info.rows, 'average_likes'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.average_comments.name,
        accessor: 'average_comments',
        Cell: (info: any) => (
          <TableCellTruncate
            value={formatNumber(checkContentFormat(info.row.original.content_format) ? info.row.original?.short_comments : info.value, '0,0')}
          />
        ),
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForContentFormat(info.rows, 'average_comments'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.avg_video_duration.name,
        accessor: 'avg_video_duration',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'avg_video_duration'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.avg_video_month.name,
        accessor: 'avg_video_month',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'avg_video_month'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.short_views.name,
        accessor: 'short_views',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'short_views'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.short_comments.name,
        accessor: 'short_comments',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'short_comments'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.short_likes.name,
        accessor: 'short_likes',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'short_likes'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.short_count.name,
        accessor: 'short_count',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'short_count'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.short_true_reach.name,
        accessor: 'short_true_reach',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'short_true_reach') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.short_eng_score.name,
        accessor: 'short_eng_score',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'short_eng_score') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.live_views.name,
        accessor: 'live_views',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'live_views'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.live_comments.name,
        accessor: 'live_comments',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'live_comments'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.live_likes.name,
        accessor: 'live_likes',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'live_likes'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.live_count.name,
        accessor: 'live_count',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'live_count'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.live_true_reach.name,
        accessor: 'live_true_reach',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'live_true_reach') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.live_eng_score.name,
        accessor: 'live_eng_score',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'live_eng_score') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.video_views.name,
        accessor: 'video_views',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'video_views'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.video_comments.name,
        accessor: 'video_comments',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'video_comments'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.video_likes.name,
        accessor: 'video_likes',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'video_likes'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.video_count.name,
        accessor: 'video_count',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'video_count'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.video_true_reach.name,
        accessor: 'video_true_reach',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'video_true_reach') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.video_eng_score.name,
        accessor: 'video_eng_score',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'video_eng_score') / info.rows.length, '0.[00]%', 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.youtube.language.name,
        accessor: 'language',
        width: 130,
        Cell: ({ value }: any) => <LanguageFullName language={value} />,
      },
      {
        Header: platformSelectedConfig.youtube.country.name,
        accessor: 'country',
        width: 130,
        Cell: ({ value }: any) => <CountryFullName country={value} />,
      },
      {
        Header: platformSelectedConfig.youtube.categories.name,
        accessor: 'categories',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: platformSelectedConfig.youtube.top_tags.name,
        accessor: 'top_tags',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 220,
      },
      {
        Header: platformSelectedConfig.youtube.youtube_games.name,
        accessor: 'youtube_games',
        Cell: ({ value }: any) => <TableCellTruncate value={JSON.stringify(value)} />,
        width: 220,
      },
      {
        Header: platformSelectedConfig.youtube.youtube_games_longterm.name,
        accessor: 'youtube_games_longterm',
        Cell: ({ value }: any) => <TableCellTruncate value={JSON.stringify(value)} />,
        width: 220,
      },
      {
        Header: platformSelectedConfig.youtube.direct_email.name,
        accessor: 'direct_email',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 220,
      },
      {
        Header: platformSelectedConfig.youtube.agency_id.name,
        accessor: 'agency_id',
        Cell: ({ value }: any) => <TableCellTruncate value={agencies?.[value]?.agency_name || ''} />,
        width: 130,
      },
      {
        Header: platformSelectedConfig.youtube.comments.name,
        accessor: 'comments',
        Cell: (row: any) => <ChatTableView row={row} from="manager" />,
        width: 130,
        className: 'justify-center',
        sortType: numberSort,
      },
    ].map((d: any) => ({
      ...d,
      tooltip: getTooltip('table', 'youtube', d.accessor),
    })),

    twitch: [
      {
        Header: ({ getToggleAllRowsSelectedProps }: any) => (
          <div className="flex items-center space-x-2">
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="border-2 bg-gray-gd border-white rounded-[2px]" />
            <TableHeaderTruncate name={platformSelectedConfig.twitch.channel_name.name} />
          </div>
        ),
        accessor: 'channel_name',
        Cell: ({ row, value }: any) => (
          <div className="flex flex-1 items-center justify-between overflow-hidden">
            <div className="flex items-center truncate space-x-2 overflow-hidden">
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} className="border-2 border-gray-gm rounded-[2px]" />
              {row.original.shared_with_client ? (
                <EyeOpenIcon
                  className="cursor-pointer shrink-0"
                  pathFill={colors.blue.b1}
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();

                    dispatch(
                      updateCampaignSelectedChannelThunk({
                        campaign_id: campaignId || '',
                        selected_id: row.original.selected_id,
                        data: {
                          shared_with_client: false,
                        },
                      })
                    );
                  }}
                />
              ) : (
                <EyeCloseIcon
                  className="cursor-pointer shrink-0"
                  pathFill={colors.blue.b1}
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();

                    dispatch(
                      updateCampaignSelectedChannelThunk({
                        campaign_id: campaignId || '',
                        selected_id: row.original.selected_id,
                        data: {
                          shared_with_client: true,
                        },
                      })
                    );
                  }}
                />
              )}
              <TableCellTruncate
                linkTo={row.original?.influencer_id != null ? `/campaigns/${campaignId}/${row.original.influencer_id}` : undefined}
                value={value}
              />
              <a href={row.original.channel_url} target="_blank" rel="noopener noreferrer">
                <LinkIcon />
              </a>
            </div>

            {getTag(row.original, 'full_label', campaignType) ? (
              <div className={cn('flex items-center justify-center px-3 rounded bg-gray-g1')}>
                <p className="text-[10px] leading-4 text-gray-gd">{getTag(row.original, 'full_label', campaignType)}</p>
              </div>
            ) : null}
          </div>
        ),
        Footer: 'Total',
        width: 350,
        sortType: localeSort,
      },
      {
        Header: platformSelectedConfig.twitch.channel_description.name,
        accessor: 'channel_description',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 220,
      },
      {
        Header: platformSelectedConfig.twitch.approval.name,
        accessor: 'approval',
        Cell: ({ row, value, state, data }: any) => (
          <FormatSelect
            value={value}
            options={approvalSelectedStatuses}
            onChange={(value: string) => {
              if (value)
                dispatch(
                  updateCampaignSelectedChannelsThunk({
                    campaign_id: campaignId || '',
                    data: {
                      approval: value,
                      selected_ids: getSelectedIds(row, state, data),
                    },
                  })
                );
            }}
          />
        ),
        width: 130,
        sortType: (a: any, b: any, id: any) => statusSort(a, b, id, approvalSelectedStatuses),
      },
      {
        Header: platformSelectedConfig.twitch.outreach_status.name,
        accessor: platformSelectedConfig.twitch.outreach_status.id,
        Cell: ({ row, value, state, data }: any) => (
          <FormatSelect
            isDisabled={campaignType !== CampaignType.ORGANIC}
            noDropdownIndicator={campaignType !== CampaignType.ORGANIC}
            value={value}
            options={outreachStatuses}
            onChange={(value: string) => {
              if (value)
                dispatch(
                  updateCampaignEngagementDataThunk({
                    campaign_id: campaignId || '',
                    selected_ids: getSelectedIds(row, state, data),
                    data: {
                      outreach_status: value,
                    },
                  })
                );
            }}
          />
        ),
        width: 185,
        minWidth: 185,
        sortType: (a: any, b: any, id: any) => statusSort(a, b, id, outreachStatuses),
      },
      {
        Header: platformSelectedConfig.twitch.content_format.name,
        accessor: 'content_format',
        Cell: ({ row, value, state, data }: any) => (
          <FormatSelect
            value={value}
            options={contentFormatStatuses}
            onChange={(value: string) => {
              if (value)
                dispatch(
                  updateCampaignSelectedChannelsThunk({
                    campaign_id: campaignId || '',
                    data: {
                      content_format: value,
                      selected_ids: getSelectedIds(row, state, data),
                    },
                  })
                );
            }}
          />
        ),
        width: 180,
        minWidth: 180,
        sortType: (a: any, b: any, id: any) => statusSort(a, b, id, contentFormatStatuses),
      },
      {
        Header: platformSelectedConfig.twitch.theme.name,
        accessor: platformSelectedConfig.twitch.theme.id,
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: platformSelectedConfig.twitch.internal_price.name,
        accessor: 'internal_price',
        Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '$0,0.[00]', 'float')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'internal_price'), '$0,0.[00]', 'float')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.price.name,
        accessor: 'price',
        Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '$0,0.[00]', 'float')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'price'), '$0,0.[00]', 'float')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.internal_cpm.name,
        accessor: 'internal_cpm',
        Cell: ({ row }: any) => (
          <TableCellTruncate
            value={formatNumber(getCpm(row.original.internal_price, getCpmSelectedDenominator(row.original), row.original.sm_platform), '$0,0.[00]', 'float')}
          />
        ),
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForCPM(info.rows, 'internal_price'), '$0,0.[00]', 'float')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.cpm.name,
        accessor: 'cpm',
        Cell: ({ row }: any) => (
          <TableCellTruncate
            value={formatNumber(getCpm(row.original.price, getCpmSelectedDenominator(row.original), row.original.sm_platform), '$0,0.[00]', 'float')}
          />
        ),
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForCPM(info.rows, 'price'), '$0,0.[00]', 'float')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.followers.name,
        accessor: 'followers',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'followers'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.days_week_30.name,
        accessor: 'days_week_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'days_week_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.aaccv_20.name,
        accessor: 'aaccv_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'aaccv_20'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.average_peak_20.name,
        accessor: 'average_peak_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'average_peak_20'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.median_accv_60.name,
        accessor: 'median_accv_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_accv_60'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.median_accv_30.name,
        accessor: 'median_accv_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_accv_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.median_accv_20.name,
        accessor: 'median_accv_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_accv_20'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.median_peak_60.name,
        accessor: 'median_peak_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_peak_60'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.median_peak_30.name,
        accessor: 'median_peak_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_peak_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.median_peak_20.name,
        accessor: 'median_peak_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'median_peak_20'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.average_stream_duration_20.name,
        accessor: 'average_stream_duration_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'average_stream_duration_20'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.avg_streams_month.name,
        accessor: 'avg_streams_month',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, undefined, 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'avg_streams_month') / info.rows.length, undefined, 'float')} />,
            [info.rows]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.hours_live_30.name,
        accessor: 'hours_live_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'hours_live_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.viewer_hours_30.name,
        accessor: 'viewer_hours_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'viewer_hours_30'), '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.daily_broadcast_30.name,
        accessor: 'daily_broadcast_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(+row.value / 3600 || 0, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'daily_broadcast_30') / 3600, '0,0')} />, [info.rows]);
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformSelectedConfig.twitch.language.name,
        accessor: 'language',
        Cell: ({ value }: any) => <LanguageFullName language={value} />,
        width: 130,
      },
      {
        Header: platformSelectedConfig.twitch.country.name,
        accessor: 'country',
        width: 130,
        Cell: ({ value }: any) => <CountryFullName country={value} />,
      },
      {
        Header: platformSelectedConfig.twitch.recently_streamed_games.name,
        accessor: 'recently_streamed_games',
        Cell: ({ value }: any) => <TableCellTruncate value={JSON.stringify(value)} />,
        width: 220,
      },
      {
        Header: platformSelectedConfig.twitch.games_longterm.name,
        accessor: 'games_longterm',
        Cell: ({ value }: any) => <TableCellTruncate value={JSON.stringify(value)} />,
        width: 220,
      },
      {
        Header: platformSelectedConfig.twitch.last_seen.name,
        accessor: 'last_seen',
        Cell: ({ value }: any) => <TableCellTruncate value={moment(value).format('MM-DD-YYYY')} />,
        width: 130,
      },
      {
        Header: platformSelectedConfig.twitch.direct_email.name,
        accessor: 'direct_email',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 220,
      },
      {
        Header: platformSelectedConfig.twitch.agency_id.name,
        accessor: 'agency_id',
        Cell: ({ value }: any) => <TableCellTruncate value={agencies?.[value]?.agency_name || ''} />,
        width: 130,
      },
      {
        Header: platformSelectedConfig.twitch.comments.name,
        accessor: 'comments',
        Cell: (row: any) => <ChatTableView row={row} from="manager" />,
        width: 130,
        className: 'justify-center',
        sortType: numberSort,
      },
    ].map((d: any) => ({
      ...d,
      tooltip: getTooltip('table', 'twitch', d.accessor),
    })),
  };
};

export const filteredPlatformColumns = (props: any): any[] => platformSelectedColumns(props)[props.platform];

export const makeColumnHiddenCheckboxes = (props: any): any[] => {
  const arr: any[] = [];

  filteredPlatformColumns(props).forEach((d: any) => {
    if (platformSelectedConfig[props.platform][d.accessor].role === 'main')
      arr.push({
        id: platformSelectedConfig[props.platform][d.accessor].id,
        label: platformSelectedConfig[props.platform][d.accessor].name,
        active: true,
      });
  });

  return arr;
};

interface ISelectedProps {}

export const Selected: FC<ISelectedProps> = () => {
  const { campaignId } = useParams();

  const dispatch = useAppDispatch();
  const campaign = useAppSelector(selectCampaign);
  const selectedChannels = useAppSelector(selectCampaignSelectedChannelsByPlatform);
  const selectedChannelsCount = useAppSelector(selectCampaignSelectedChannelsCount);
  const platform = useAppSelector(selectCampaignSelectedSettingsPlatform);
  const searchValue = useAppSelector(selectCampaignSelectedSettingsSearchValue);
  const filters = useAppSelector(selectCampaignSelectedSettingsFilters);
  const tableFilters = useAppSelector(selectCampaignSelectedSettingsTableFilter);
  const hiddenColumns = useAppSelector(selectCampaignSelectedSettingsTableFilterHiddenColumns);
  const selectedRows = useAppSelector(selectCampaignSelectedSettingsTableFilterSelectedRows);

  const agencies = useAppSelector(selectAgencies);

  const agenciesPrepare = useMemo(() => {
    let agencyObj: any = {};

    if (agencies.length) {
      agencies.forEach((agency: any) => {
        agencyObj[agency.agency_id] = { ...agency };
      });
    }

    return agencyObj;
  }, [agencies]);

  const tableRef = useRef<any>(null);

  const platformSelectOptions = useSMPlatforms(campaign);

  const handleChangeSelect = (props: any) =>
    dispatch(
      campaignActions.setSelectedSettings({
        tableFilters: {
          ...tableFilters,
          [platform]: {
            ...tableFilters[platform],
            hiddenColumns: props,
          },
        },
      })
    );

  const handleSelectChange = (newValue: SingleValue<any>, _platform: Platform) => {
    dispatch(
      campaignActions.setSelectedSettings({
        platform: newValue.value,
      })
    );
    dispatch(fetchCampaignSelectedChannelsThunk(campaignId || ''))
      .unwrap()
      .then(() => {
        dispatch(
          campaignActions.setSelectedSettings({
            tableFilters: {
              ...tableFilters,
              [newValue.value]: {
                ...tableFilters[newValue.value as Platform],
                hiddenColumns: !tableFilters[newValue.value as Platform].hiddenColumns
                  ? makeColumnHiddenCheckboxes({
                      dispatch,
                      campaignId,
                      platform: newValue.value,
                      agencies: agenciesPrepare,
                      campaignType: campaign?.type,
                    })
                  : tableFilters[newValue.value as Platform].hiddenColumns,
              },
            },
          })
        );
      })
      .catch(() => {
        dispatch(
          campaignActions.setSelectedSettings({
            platform: _platform,
            tableFilters: {
              ...tableFilters,
              [_platform]: {
                ...tableFilters[_platform],
                hiddenColumns: !tableFilters[_platform].hiddenColumns
                  ? makeColumnHiddenCheckboxes({
                      dispatch,
                      campaignId,
                      platform: _platform,
                      agencies: agenciesPrepare,
                      campaignType: campaign?.type,
                    })
                  : tableFilters[_platform].hiddenColumns,
              },
            },
          })
        );
      });
  };
  const isEmpty = !Boolean(selectedChannels.length);

  const memoFilteredPlatformColumns = useCallback(
    () =>
      filteredPlatformColumns({
        dispatch,
        campaignId,
        platform,
        agencies: agenciesPrepare,
        selectedRows,
        campaignType: campaign?.type,
      }).filter(
        (column: any) =>
          !(campaign.type === CampaignType.ORGANIC && ['price', 'internal_price', 'cpm', 'internal_cpm', 'internal_cpvh'].includes(column.accessor))
      ), // Removing certain fields for 'organic' campaigns
    [campaignId, platform, agenciesPrepare]
  );

  const columns = useMemo(
    () =>
      [
        ...memoFilteredPlatformColumns(),
        {
          Header: '',
          accessor: 'action',
          width: 50,
          minWidth: 50,
          maxWidth: 50,
          disabledResizing: true,
          disableSortBy: true,
          className: 'flex py-0 bg-gradient-to-l from-white via-white to-transparent group-hover:sticky group-hover:z-10 group-hover:right-0',
          Cell: ({ row }: any) => (
            <MenuActionWithFloat
              className="flex"
              optionClass="!px-2.5"
              items={[
                {
                  label: 'Edit',
                  onClick: () => {
                    dispatch(
                      showModal({
                        type: ModalTypes.EditSelectedChannel,
                        params: {
                          campaignType: campaign?.type,
                          influencer: row.original,
                          handleSave: async (data: any) =>
                            await dispatch(
                              updateCampaignSelectedChannelThunk({
                                campaign_id: campaignId || '',
                                selected_id: row.original.selected_id,
                                data,
                                thunk: { noLoading: true },
                              })
                            ),
                        },
                      })
                    );
                  },
                },
                {
                  label: 'Comment',
                  onClick: () => {
                    dispatch(showChat(row.original));
                  },
                },
                {
                  label: 'Delete',
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ModalTypes.DeleteConfirmation,
                        params: {
                          name: row.original.channel_name,
                          handleDelete: async () =>
                            await dispatch(
                              deleteCampaignSelectedChannelThunk({
                                campaign_id: campaignId || '',
                                selected_id: row.original.selected_id,
                              })
                            ),
                        },
                      })
                    ),
                },
              ]}
            >
              {({ open }) => (
                <span className={cn('cursor-pointer', { 'opacity-100': open })}>
                  <DotsIcon aria-hidden="true" />
                </span>
              )}
            </MenuActionWithFloat>
          ),
        },
      ].filter((d: any) => (hiddenColumns || []).some((dd: any) => dd.id === d.accessor || d.accessor === 'action')) || [],
    [memoFilteredPlatformColumns, hiddenColumns]
  );

  const hiddenColumnsOptions = useMemo(
    () =>
      memoFilteredPlatformColumns().map((d: any) => ({
        id: d.accessor,
        name: platformSelectedConfig[platform][d.accessor].name,
      })),
    [memoFilteredPlatformColumns]
  );

  const selectedChannelsSorted = useMemo(
    () => orderBy(selectedChannels, [(d) => d.channel_name || '', (d) => d.selected_id], ['asc', 'asc']),
    [selectedChannels]
  );

  const selectedChannelsFiltered = useMemo(
    () => filterCampaignChannels(selectedChannelsSorted, { filters: filters[platform] }),
    [selectedChannelsSorted, filters, platform]
  );

  const selectedChannelsSearched = useMemo(
    () => selectedChannelsFiltered.filter((d: any) => (d.channel_name || '').toLowerCase().indexOf(searchValue.toLowerCase()) !== -1),
    [searchValue, selectedChannelsFiltered]
  );

  const exportColumns = useMemo(() => {
    const columns = memoFilteredPlatformColumns()
      .filter((d: any) => (hiddenColumns || []).some((dd: any) => dd.id === d.accessor || d.accessor === 'action'))
      .map((d: any) => ({
        key: platformSelectedConfig[platform][d.accessor].id,
        header: platformSelectedConfig[platform][d.accessor].name,
      }));

    columns.splice(1, 0, {
      key: 'channel_url',
      header: platformSelectedConfig[platform]['channel_url'].name,
    });
    return columns;
  }, [memoFilteredPlatformColumns, hiddenColumns]);

  const exportData = useMemo(() => {
    return selectedChannelsSearched.map((d: any) => {
      const obj: any = {};
      exportColumns.forEach((dd: any) => {
        obj[dd.key] =
          Object.keys(selectedColumnsForContentFormat).includes(dd.key) && checkContentFormat(d.content_format)
            ? getColumnFieldByContentFormat[d.content_format]?.[dd.key]
              ? d[getColumnFieldByContentFormat[d.content_format][dd.key]] || 0
              : 0
            : d[dd.key];
      });
      return obj;
    });
  }, [selectedChannelsSearched, exportColumns]);

  const handleApply = (value: any) => {
    dispatch(
      campaignActions.setSelectedSettings({
        filters: {
          ...filters,
          [platform]: value,
        },
      })
    );
  };

  useLegacyEffect(() => {
    if (campaignId) dispatch(fetchCampaignSelectedChannelsThunk(campaignId));
  }, [campaignId]);

  // Initialize hiddenColumns
  useLegacyEffect(() => {
    if (!hiddenColumns)
      dispatch(
        campaignActions.setSelectedSettings({
          tableFilters: {
            ...tableFilters,
            [platform]: {
              ...tableFilters[platform],
              hiddenColumns: makeColumnHiddenCheckboxes({
                dispatch,
                campaignId,
                platform,
                agencies: agenciesPrepare,
                campaignType: campaign?.type,
              }),
            },
          },
        })
      );
  }, [hiddenColumns]);

  const role = useAppSelector(selectRole);
  const handlePreload = useCallback(() => {
    dispatch(fetchCampaignSelectedChannelsThunk(campaignId || ''));
  }, [dispatch, campaignId]);

  return (
    <>
      <SimpleSearch
        className="py-4"
        placeholder="Search by selected"
        defaultValue={searchValue}
        onChange={(value: string) =>
          dispatch(
            campaignActions.setSelectedSettings({
              searchValue: value,
            })
          )
        }
      />
      <div className="flex flex-1 flex-col overflow-hidden p-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl leading-[42px] font-semibold">{`Selected${selectedChannelsCount ? ` (${selectedChannelsCount})` : ''}`}</h1>
          <div className="flex items-center space-x-4">
            <button
              className={cn(
                'flex w-full h-10 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0'
              )}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                dispatch(
                  showModal({
                    type: ModalTypes.UploadChannels,
                    params: {
                      platform,
                      campaign_id: campaignId || '',
                      onSuccess: (payload: object) => {
                        dispatch(
                          showModal({
                            type: ModalTypes.UploadStats,
                            params: { payload, handlePreload },
                          })
                        );
                      },
                    },
                  })
                );
              }}
            >
              <UploadCloudIcon pathFill={colors.white.default} aria-hidden="true" />
              <p>Import Channels</p>
            </button>
            <ActionMenu
              items={[
                {
                  label: 'Open channels',
                  disabled: !selectedRows.length,
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ModalTypes.OpenSelected,
                        params: {
                          handleConfirm: async () =>
                            await dispatch(
                              updateCampaignSelectedChannelsThunk({
                                campaign_id: campaignId || '',
                                data: {
                                  shared_with_client: true,
                                  selected_ids: selectedRows,
                                },
                              })
                            ).then(() => tableRef?.current?.resetSelectedRows()),
                        },
                      })
                    ),
                },
                {
                  label: 'Delete channels',
                  disabled: !selectedRows.length,
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ModalTypes.DeleteSelected,
                        params: {
                          handleDelete: async () =>
                            await dispatch(
                              deleteCampaignSelectedChannelsThunk({
                                campaign_id: campaignId || '',
                                data: { selected_ids: selectedRows },
                              })
                            ).then(() => tableRef?.current?.resetSelectedRows()),
                        },
                      })
                    ),
                },
              ]}
            />
            <ExportMenu data={exportData} columns={exportColumns} filename={`${replaceSpaceToUnderscore(campaign.campaign_name)}_selected`} />
            <HSelect menuClass="w-44" selected={hiddenColumns} options={hiddenColumnsOptions} onChange={handleChangeSelect}>
              {({ open }) => (
                <button
                  type="button"
                  className={cn(
                    'inline-flex items-center justify-center w-[42px] h-[42px] rounded-[4px] border border-transparent shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0',
                    open ? 'bg-blue-b1' : 'bg-white'
                  )}
                >
                  <Bars3Icon pathFill={open ? colors.white.default : colors.blue.b1} aria-hidden="true" />
                </button>
              )}
            </HSelect>
            <div className="relative">
              <RSelect
                className="w-[150px]"
                styles={platformSelectStyles}
                defaultValue={platformSelectOptions[platform]}
                name="select-channels"
                isSearchable={false}
                isClearable={false}
                options={Object.keys(platformSelectOptions)
                  .filter((d: any) => d !== platform)
                  .map((d: any) => platformSelectOptions[d])}
                components={{
                  ClearIndicator: () => null,
                  IndicatorSeparator: () => null,
                  Option: PlatformSelectOption,
                  SingleValue: PlatformSelectCustomSingleValue,
                }}
                onChange={(newValue: SingleValue<any>) => handleSelectChange(newValue, platform)}
              />
              {selectedChannelsSearched.length ? (
                <span className="absolute -top-2 -right-2 min-w-[1.5rem] h-[1.5rem] inline-flex items-center justify-center rounded-full px-1.5 text-xs font-medium text-blue-b1 bg-[#ece8fd]">
                  {selectedChannelsSearched.length}
                </span>
              ) : null}
            </div>
          </div>
        </div>
        {/* TODO update breadcrumbs with data and routes */}
        <Breadcrumbs
          paths={[
            { name: `Campaigns`, to: `/campaigns` },
            {
              name: `${campaign.campaign_name}`,
              to: `/campaigns/${campaign.campaign_id}`,
            },
            {
              name: `Selected`,
              to: '',
            },
          ]}
        />

        {isEmpty ? (
          <SelectedEmpty />
        ) : (
          <>
            {/* <CampaignSelectedSettings
              platform={platform}
              searchChannels={{ filters }}
              role={role}
              handleSubmit={handleApply}
            /> */}

            <div className="mt-4 flex flex-col overflow-hidden">
              <div className="flex flex-col min-w-full align-middle overflow-hidden">
                <Table ref={tableRef} columns={columns} data={selectedChannelsSearched} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

interface ITableProps {
  ref: any;
  data: any[];
  columns: any[];
}

const Table: FC<ITableProps> = memo(
  forwardRef(({ columns, data }, ref: any) => {
    const dispatch = useAppDispatch();
    const platform = useAppSelector(selectCampaignSelectedSettingsPlatform);
    const tableFilters = useAppSelector(selectCampaignSelectedSettingsTableFilter);
    const tfSortBy = useAppSelector(selectCampaignSelectedSettingsTableFilterSortBy);
    const tfSelectedRows = useAppSelector(selectCampaignSelectedSettingsTableFilterSelectedRows);
    const tfResizing = useAppSelector(selectCampaignSelectedSettingsTableFilterResizing);

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const defaultColumn = useMemo(
      () => ({
        minWidth: 130,
        // width: 150,
        // maxWidth: 400,
      }),
      []
    );

    const selectedRows = useMemo(() => {
      const ids: any = {};
      data.forEach((d: any, i: number) => {
        if (tfSelectedRows.includes(d.selected_id)) ids[i] = true;
      });
      return ids;
    }, [data]);

    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
      // @ts-ignore
      toggleAllRowsSelected,
      // @ts-ignore
      state: { sortBy, selectedRowIds, columnResizing },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        // @ts-ignore
        initialState: { sortBy: tfSortBy, selectedRowIds: selectedRows },
        useControlledState: (state) => {
          return useMemo(() => {
            return {
              ...state,
              columnResizing: {
                // @ts-ignore
                ...state?.columnResizing,
                // @ts-ignore
                columnWidths: state?.columnResizing?.headerIdWidths
                  ? // @ts-ignore
                    {
                      ...tfResizing,
                      // @ts-ignore
                      ...state.columnResizing?.columnWidths,
                    }
                  : tfResizing,
              },
            };
            // @ts-ignore
          }, [state, tfResizing]);
        },
      },
      useSortBy,
      useRowSelect,
      useResizeColumns,
      useBlockLayout
    );

    useImperativeHandle(
      ref,
      () => ({
        resetSelectedRows: () => toggleAllRowsSelected(false),
      }),
      []
    );

    useLegacyEffect(() => {
      const ids: string[] = Object.keys(selectedRowIds).map((d: any) => data[d].selected_id);
      dispatch(
        campaignActions.setSelectedSettings({
          tableFilters: {
            ...tableFilters,
            [platform]: {
              ...tableFilters[platform],
              selectedRows: ids,
            },
          },
        })
      );
    }, [selectedRowIds]);

    // TODO make better update tableFilters. May be use the getSortByToggleProps hook to extend its functionality
    useLegacyEffect(() => {
      dispatch(
        campaignActions.setSelectedSettings({
          tableFilters: {
            ...tableFilters,
            [platform]: {
              ...tableFilters[platform],
              sortBy,
            },
          },
        })
      );
    }, [sortBy]);

    useLegacyEffect(() => {
      if (columnResizing?.columnWidths && !columnResizing?.isResizingColumn && !isEqual(tfResizing, columnResizing.columnWidths))
        dispatch(
          campaignActions.setSelectedSettings({
            tableFilters: {
              ...tableFilters,
              [platform]: {
                ...tableFilters[platform],
                resizing: columnResizing.columnWidths,
              },
            },
          })
        );
    }, [columnResizing?.columnWidths]);

    // Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: rows.length,
      overscan: 10,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

    // Render the UI for your table
    return (
      <div ref={tableContainerRef} className="overflow-auto shadow rounded-[4px]">
        <div {...getTableProps()} className="table">
          <div className="thead sticky top-0 z-30 bg-gray-gd rounded-tl-[4px]">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column: any, hidx: number) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title=""
                    className={cn(
                      'td group text-left text-xs leading-6 whitespace-nowrap font-normal text-white border-r border-gray-g2 last:border-r-0 hover:bg-gray-g3',
                      hidx === 0 ? '!sticky left-0 top-0 z-10 bg-gray-gd shadow-fixed-divider-header' : '',
                      column?.headerClassName || '',
                      column.isSorted ? 'bg-gray-g3' : ''
                    )}
                  >
                    <div className={cn('flex items-center justify-between px-4 py-2 space-x-1', column?.disableSortBy ? '' : 'cursor-pointer')}>
                      {typeof column.Header === 'string' ? <TableHeaderTruncate name={column.Header} /> : column.render('Header')}
                      <div className="flex items-center space-x-2">
                        {column.tooltip && <PopupDescription content={column.tooltip} />}
                        <TableHeaderSort column={column} />
                      </div>
                    </div>
                    <TableResizer column={column} />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="tbody bg-white">
            {paddingTop > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingTop}px` }} />
              </div>
            )}
            {virtualRows.map((virtualRow: any) => {
              const row = rows[virtualRow.index];
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr relative group h-[3.25rem] border-b border-gray-g2 last:border-b-0 hover:shadow-s3 hover:z-20">
                  {row.cells.map((cell: any, bidx: number) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className={cn(
                          'td relative !flex items-center whitespace-nowrap px-4 text-sm leading-6 font-normal text-black-b1 border-r border-gray-g2 last:border-r-0',
                          cell.column.id !== 'action' && cell.column.id !== 'approval' && cell.column.id !== 'content_format' ? 'truncate' : '',
                          bidx === 0 ? 'sticky left-0 top-0 z-10 shadow-fixed-divider bg-white' : '',
                          cell.column?.className || ''
                        )}
                      >
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {paddingBottom > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingBottom}px` }} />
              </div>
            )}
          </div>
          <div className="tfoot sticky bottom-0 z-30 bg-white">
            {footerGroups.map((group) => (
              <div {...group.getFooterGroupProps()} className="tr h-[3.25rem] shadow-s4">
                {group.headers.map((column, fidx) => (
                  <div
                    {...column.getFooterProps()}
                    className={cn(
                      'td !flex items-center whitespace-nowrap px-4 text-sm leading-6 font-semibold text-violet-v1 border-r border-gray-g2 last:border-r-0',
                      fidx === 0 ? 'sticky left-0 top-0 z-10 bg-white shadow-fixed-divider' : ''
                    )}
                  >
                    {column.render('Footer')}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }),
  (prev, next) => isEqual(prev, next)
);
