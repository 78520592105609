import { ExportMenu } from 'components/ExportMenu';
import { FC, memo, useMemo, useRef, useState } from 'react';
import { TableCellTruncate, TableHeaderSort, TableHeaderTruncate, TableResizer } from 'shared';
import { twitchReportCofig } from 'utils/common';
import cn from 'classnames';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useVirtual } from 'react-virtual';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useBlockLayout, useResizeColumns, useSortBy, useTable } from 'react-table';
import { PopupDescription } from 'components/PopupDescription';
import { isEqual } from 'lodash';
import {
  campaignActions,
  fetchCampaignThunk,
  fetchInfluencerTwitchReportThunk,
  selectCampaign,
  selectCampaignReportSettingsPlatform,
  selectCampaignReportSettingsTableFilter,
  selectCampaignReportSettingsTableFilterResizing,
  selectCampaignReportSettingsTableFilterSortBy,
  selectInfluencerTwitchReport,
} from '../reducer';
import { useParams } from 'react-router-dom';
import { fetchInfluencerThunk, selectInfluencerTwitch } from 'pages/Influencers/reducer';
import moment from 'moment';
import { formatNumber, replaceSpaceToUnderscore } from 'utils/helper';
import { getTotalForColumn } from 'pages/Influencers/tabs/InfluencersTab';

const twitchReportColumns = [
  {
    Header: twitchReportCofig.stream_title.name,
    accessor: twitchReportCofig.stream_title.id,
    Cell: ({ value }: any) => <TableCellTruncate value={value} />,
    width: '300',
  },
  {
    Header: twitchReportCofig.streaming_date.name,
    accessor: twitchReportCofig.streaming_date.id,
    Cell: ({ value }: any) => <TableCellTruncate value={value ? moment(value * 1000).format('MMM DD, YYYY') : ''} />,
    width: '200',
  },
  {
    Header: twitchReportCofig.avg_views.name,
    accessor: twitchReportCofig.avg_views.id,
    Cell: ({ value }: any) => <TableCellTruncate value={value} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, twitchReportCofig.avg_views.id), '0,0')} />, [info.rows]);
    },
  },
  {
    Header: twitchReportCofig.peak_viewers.name,
    accessor: twitchReportCofig.peak_viewers.id,
    Cell: ({ value }: any) => <TableCellTruncate value={value} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, twitchReportCofig.peak_viewers.id), '0,0')} />, [info.rows]);
    },
  },
  {
    Header: twitchReportCofig.stream_duration.name,
    accessor: twitchReportCofig.stream_duration.id,
    Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value / 3600, '0,0.[0]', 'float')} />,
    Footer: (info: any) => {
      return useMemo(
        () => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, twitchReportCofig.stream_duration.id) / 3600, '0,0.[0]', 'float')} />,
        [info.rows]
      );
    },
    width: '180',
  },
  {
    Header: twitchReportCofig.clicks.name,
    accessor: twitchReportCofig.clicks.id,
    Cell: ({ value }: any) => <TableCellTruncate value={value} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, twitchReportCofig.clicks.id), '0,0')} />, [info.rows]);
    },
  },
];

const InfluencerTwitchReport = () => {
  const { campaignId, influencerId } = useParams();
  const dispatch = useAppDispatch();
  const campaign = useAppSelector(selectCampaign);
  const influencerTwitch = useAppSelector(selectInfluencerTwitch);
  const influencerTwitchReport = useAppSelector(selectInfluencerTwitchReport);
  const [selectedRange, setSelectedRange] = useState<'30' | '90' | '365'>('90');

  const columns = useMemo(() => twitchReportColumns, []);

  const baseSelectorButtonStyle = 'h-full flex items-center px-4 cursor-pointer rounded';
  const activeSelectorButtonStyle = 'bg-blue-b1 hover:bg-blue-b1 text-white';

  useLegacyEffect(() => {
    dispatch(
      fetchInfluencerTwitchReportThunk({
        campaign_id: campaignId || '',
        influencer_id: influencerId || '',
        content_period: Number(selectedRange),
      })
    );
  }, [selectedRange]);

  useLegacyEffect(() => {
    if (campaignId) dispatch(fetchCampaignThunk(campaignId));
  }, [campaignId]);

  useLegacyEffect(() => {
    if (influencerId) dispatch(fetchInfluencerThunk(influencerId));
  }, [influencerId]);

  const exportColumns = columns.map((column) => ({
    key: column.accessor,
    header: column.Header,
  }));

  const exportData = useMemo(() => {
    return influencerTwitchReport.map((d: any) => {
      const obj: any = {};
      columns.forEach((dd: any) => {
        obj[dd.accessor] = d[dd.accessor];
      });
      return obj;
    });
  }, [influencerTwitchReport, columns]);

  return (
    <>
      <div className="p-6 gap-4 flex flex-col">
        <div className="gap-3 flex flex-col">
          <div className="flex justify-between">
            <h1 className="text-2xl leading-[42px] font-semibold">All influencer coverage</h1>

            <div className="flex align-center gap-3">
              <div className="h-[42px] flex items-center rounded text-[14px] bg-white">
                {['30', '90', '365'].map((range) => (
                  <span
                    key={range}
                    className={cn(baseSelectorButtonStyle, selectedRange === range && activeSelectorButtonStyle)}
                    onClick={() => setSelectedRange(range as '30' | '90' | '365')}
                  >
                    {range} Days
                  </span>
                ))}
              </div>

              <ExportMenu
                data={exportData}
                columns={exportColumns}
                filename={`${replaceSpaceToUnderscore(influencerTwitch?.[0]?.channel_name)}_twitch_report`}
              />
            </div>
          </div>

          <p className="text-sm text-black-b1 whitespace-nowrap">
            This is all content published by <span className="font-semibold">{influencerTwitch?.[0]?.channel_name}</span> for{' '}
            <span className="font-semibold">{campaign?.product}</span> since{' '}
            <span className="font-semibold">{moment(campaign?.launch_date).format('DD MMM YYYY')}</span>
          </p>
        </div>

        <Table columns={columns} data={influencerTwitchReport} />
      </div>
    </>
  );
};

interface ITableProps {
  data: any[];
  columns: any[];
}

const Table: FC<ITableProps> = memo(
  ({ columns, data }) => {
    const dispatch = useAppDispatch();
    const platform = useAppSelector(selectCampaignReportSettingsPlatform);
    const tableFilters = useAppSelector(selectCampaignReportSettingsTableFilter);
    const tfSortBy = useAppSelector(selectCampaignReportSettingsTableFilterSortBy);
    const tfResizing = useAppSelector(selectCampaignReportSettingsTableFilterResizing);

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const defaultColumn = useMemo(
      () => ({
        minWidth: 130,
        // width: 150,
        // maxWidth: 400,
      }),
      []
    );

    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
      // @ts-ignore
      state: { sortBy, columnResizing },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        // @ts-ignore
        initialState: { sortBy: tfSortBy },
        useControlledState: (state) => {
          return useMemo(() => {
            return {
              ...state,
              columnResizing: {
                // @ts-ignore
                ...state?.columnResizing,
                // @ts-ignore
                columnWidths: state?.columnResizing?.headerIdWidths
                  ? // @ts-ignore
                    {
                      ...tfResizing,
                      // @ts-ignore
                      ...state.columnResizing?.columnWidths,
                    }
                  : tfResizing,
              },
            };
            // @ts-ignore
          }, [state, tfResizing]);
        },
      },
      useSortBy,
      useResizeColumns,
      useBlockLayout
    );

    // TODO make better update tableFilters. May be use the getSortByToggleProps hook to extend its functionality
    useLegacyEffect(() => {
      dispatch(
        campaignActions.setReportSettings({
          tableFilters: {
            ...tableFilters,
            [platform]: {
              ...tableFilters[platform],
              sortBy,
            },
          },
        })
      );
    }, [sortBy]);

    useLegacyEffect(() => {
      if (columnResizing?.columnWidths && !columnResizing?.isResizingColumn && !isEqual(tfResizing, columnResizing.columnWidths))
        dispatch(
          campaignActions.setReportSettings({
            tableFilters: {
              ...tableFilters,
              [platform]: {
                ...tableFilters[platform],
                resizing: columnResizing.columnWidths,
              },
            },
          })
        );
    }, [columnResizing?.columnWidths]);

    // Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: rows.length,
      overscan: 10,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

    // Render the UI for your table
    return (
      <div ref={tableContainerRef} className="overflow-auto shadow rounded-[4px]">
        <div {...getTableProps()} className="table pr-40">
          <div className="thead sticky top-0 z-30 bg-gray-gd rounded-tl-[4px]">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column: any, hidx: number) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title=""
                    className={cn(
                      'td group text-left text-xs leading-6 whitespace-nowrap font-normal text-white border-r border-gray-g2 last:border-r-0 hover:bg-gray-g3',
                      hidx === 0 ? '!sticky left-0 top-0 z-10 bg-gray-gd shadow-fixed-divider-header' : '',
                      column?.headerClassName || '',
                      column.isSorted ? 'bg-gray-g3' : ''
                    )}
                  >
                    <div className={cn('flex items-center justify-between px-4 py-2 space-x-1', column?.disableSortBy ? '' : 'cursor-pointer')}>
                      {typeof column.Header === 'string' ? <TableHeaderTruncate name={column.Header} /> : column.render('Header')}
                      <div className="flex items-center space-x-2">
                        {column.tooltip && <PopupDescription content={column.tooltip} />}
                        <TableHeaderSort column={column} />
                      </div>
                    </div>
                    <TableResizer column={column} />
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()} className="tbody bg-white">
            {paddingTop > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingTop}px` }} />
              </div>
            )}

            {virtualRows.map((virtualRow: any) => {
              const row = rows[virtualRow.index];
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr relative group h-[3.25rem] border-b border-gray-g2 last:border-b-0 hover:shadow-s3 hover:z-20">
                  {row.cells.map((cell: any, bidx: number) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className={cn(
                          'td relative !flex items-center truncate whitespace-nowrap px-4 text-sm leading-6 font-normal text-black-b1 border-r border-gray-g2 last:border-r-0',
                          bidx === 0 ? 'sticky left-0 top-0 z-10 shadow-fixed-divider bg-white' : '',
                          cell.column?.className || ''
                        )}
                      >
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}

            {paddingBottom > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingBottom}px` }} />
              </div>
            )}
          </div>

          <div className="tfoot sticky bottom-0 z-30 bg-white">
            {footerGroups.map((group) => (
              <div {...group.getFooterGroupProps()} className="tr h-[3.25rem] shadow-s4">
                {group.headers.map((column, fidx) => (
                  <div
                    {...column.getFooterProps()}
                    className={cn(
                      'td !flex items-center whitespace-nowrap px-4 text-sm leading-6 font-semibold text-violet-v1 border-r border-gray-g2 last:border-r-0',
                      fidx === 0 ? 'sticky left-0 top-0 z-10 bg-white shadow-fixed-divider' : ''
                    )}
                  >
                    {column.render('Footer')}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);

export default InfluencerTwitchReport;
