import { FC, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { components, OptionProps, SingleValueProps, StylesConfig } from 'react-select';
import numeral from 'numeral';
import cn from 'classnames';
import { getCpm, getCpmSelectedDenominator } from 'utils/helper';
import { Form, Input, Label, RSelect, RSelectStyles, ClipLoader } from 'shared';
import { ModalState } from 'components/Modal/reducer';
import { ModalHeader } from '../../index';
import { getStatus } from 'pages/Campaign/tabs/Selected';
import { approvalSelectedStatuses, contentFormatStatuses } from 'utils/common';
import { CampaignType } from '../../../../shared/enums/campaign_type';

export const customColoredSelectStyles: StylesConfig<any, false> = {
  ...RSelectStyles,
  control: (styles, props) => ({
    ...(RSelectStyles?.control ? RSelectStyles?.control(styles, props) : {}),
    minHeight: '48px !important',
    height: '48px !important',
  }),
  valueContainer: (styles, props) => ({
    ...(RSelectStyles?.valueContainer ? RSelectStyles?.valueContainer(styles, props) : {}),
    display: 'flex',
  }),
};

export const CustomColoredSelectOption = ({ children, ...props }: OptionProps<any, false>) => {
  const { data } = props;

  return (
    <components.Option {...props}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
          borderRadius: '4px',
          padding: '0 10px',
          backgroundColor: data.color,
          color: data.textColor,
          height: '24px',
          fontSize: '10px',
        }}
      >
        {children}
      </div>
    </components.Option>
  );
};

export const CustomColoredSelectSingleValue = ({ children, ...props }: SingleValueProps<any, false>) => {
  const { data } = props;

  return (
    <components.SingleValue {...props} className="flex items-center">
      <div className={`flex items-center h-6 px-2.5 rounded-[4px] ${data.text || 'text-black-b1'} text-[10px] ${data.bg}`}>{children}</div>
    </components.SingleValue>
  );
};

interface IEditSelectedChannelProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  campaignType: string;
  hideModal: () => void;
}

export const EditSelectedChannel: FC<IEditSelectedChannelProps> = ({ params, hideModal, campaignType }) => {
  const defaultValues: any = useMemo(
    () => ({
      channel_name: params?.influencer?.channel_name || '',
      approval: params?.influencer?.approval || getStatus('', approvalSelectedStatuses).value,
      content_format: params?.influencer?.content_format || getStatus('', contentFormatStatuses).value,
      theme: params?.influencer?.theme || '',
      price: params?.influencer?.price || '',
      internal_price: params?.influencer?.internal_price || '',
      cpm: getCpm(params?.influencer?.price, getCpmSelectedDenominator(params?.influencer), params?.influencer?.sm_platform),
      internal_cpm: getCpm(params?.influencer?.internal_price, getCpmSelectedDenominator(params?.influencer), params?.influencer?.sm_platform),
    }),
    [params?.influencer]
  );

  return (
    <div className="flex h-full flex-col">
      <ModalHeader title="Update selected influencer" close={hideModal} />
      <Form
        className="flex-1 space-y-5 p-6 pt-0 sm:p-8 sm:pt-0"
        defaultValues={defaultValues}
        // validationType={ValidationTypes.AdminAddInfluencer}
        onSubmit={(values) => {
          console.log('SUMBIT', values);
          const copyValues = Object.assign({}, values);
          delete copyValues.channel_name;
          return params.handleSave(copyValues).then(() => hideModal());
        }}
      >
        {({ register, control, watch, setValue, formState }) => {
          const { errors, isSubmitting } = formState;
          return (
            <>
              <div className="pointer-events-none">
                <Label htmlFor="channel_name" label="Name" error={errors.channel_name} className="text-gray-gm" />
                <div className="mt-0.5">
                  <Input id="channel_name" type="text" placeholder="Name" error={errors.channel_name} disabled {...register('channel_name')} />
                </div>
              </div>

              <div>
                <Label htmlFor="approval" label="Approval" error={errors.approval} />
                <div className="mt-0.5">
                  <Controller
                    name="approval"
                    control={control}
                    render={(props: any) => {
                      const { field } = props;
                      return (
                        <RSelect
                          {...field}
                          styles={customColoredSelectStyles}
                          placeholder="Not Selected"
                          options={approvalSelectedStatuses}
                          error={errors.approval}
                          isClearable={false}
                          isSearchable={false}
                          components={{
                            ClearIndicator: () => null,
                            IndicatorSeparator: () => null,
                            SingleValue: CustomColoredSelectSingleValue,
                            Option: CustomColoredSelectOption,
                          }}
                          value={approvalSelectedStatuses.find((option) => option.value === field.value) || null}
                          onChange={(option: any) => {
                            field.onChange(option.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="content_format" label="Content format" error={errors.content_format} />
                <div className="mt-0.5">
                  <Controller
                    name="content_format"
                    control={control}
                    render={(props: any) => {
                      const { field } = props;
                      return (
                        <RSelect
                          {...field}
                          styles={customColoredSelectStyles}
                          placeholder="Not Selected"
                          options={contentFormatStatuses}
                          error={errors.content_format}
                          isClearable={false}
                          isSearchable={false}
                          components={{
                            ClearIndicator: () => null,
                            IndicatorSeparator: () => null,
                            SingleValue: CustomColoredSelectSingleValue,
                            Option: CustomColoredSelectOption,
                          }}
                          value={contentFormatStatuses.find((option) => option.value === field.value) || null}
                          onChange={(option: any) => {
                            field.onChange(option.value);
                          }}
                        />
                      );
                    }}
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="theme" label="Theme" error={errors.theme} />
                <div className="relative mt-0.5">
                  <Input id="theme" type="text" placeholder="Theme" error={errors.theme} {...register('theme')} />
                </div>
              </div>

              {params?.campaignType !== CampaignType.ORGANIC && (
                <>
                  <div className="grid grid-cols-2 gap-8">
                    <div>
                      <Label htmlFor="internal_price" label="Internal price" error={errors.internal_price} />
                      <div className="relative mt-0.5">
                        <Input
                          id="internal_price"
                          type="number"
                          placeholder="Internal price"
                          error={errors.internal_price}
                          className={watch('internal_price') ? '!pl-5' : ''}
                          {...register('internal_price', {
                            onChange: (e: any) => {
                              const value = e.target.value;
                              const cpmValue = getCpm(value, getCpmSelectedDenominator(params?.influencer), params?.influencer?.sm_platform);
                              setValue('internal_price', value);
                              setValue('internal_cpm', cpmValue);
                            },
                          })}
                        />
                        {watch('internal_price') ? <p className="absolute left-3 top-3 text-xs leading-6 font-normal text-black-b1">$</p> : null}
                      </div>
                    </div>

                    <div>
                      <Label htmlFor="price" label="Price" error={errors.price} />
                      <div className="relative mt-0.5">
                        <Input
                          id="price"
                          type="number"
                          placeholder="Price"
                          error={errors.price}
                          className={watch('price') ? '!pl-5' : ''}
                          {...register('price', {
                            onChange: (e: any) => {
                              const value = e.target.value;
                              const cpmValue = getCpm(value, getCpmSelectedDenominator(params?.influencer), params?.influencer?.sm_platform);
                              setValue('price', value);
                              setValue('cpm', cpmValue);
                            },
                          })}
                        />
                        {watch('price') ? <p className="absolute left-3 top-3 text-xs leading-6 font-normal text-black-b1">$</p> : null}
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-8">
                    <div className="pointer-events-none">
                      <Label
                        htmlFor="internal_cpm"
                        label={params?.influencer?.sm_platform === 'youtube' ? 'Internal cpm' : 'Internal cpvh'}
                        error={errors.internal_cpm}
                      />
                      <div className="relative mt-0.5">
                        <Input
                          id="internal_cpm"
                          name="internal_cpm"
                          type="text" // number
                          placeholder={params?.influencer?.sm_platform === 'youtube' ? 'Internal cpm' : 'Internal cpvh'}
                          // error={errors.internal_cpm}
                          className={watch('internal_cpm') ? '!pl-5' : ''}
                          value={
                            // TODO update formatNumber without 'a' after > 100000
                            watch('internal_cpm') ? numeral(watch('internal_cpm')).format('0,0.[00]') : ''
                          }
                          onChange={() => null}
                          // {...register('internal_cpm', {
                          //   // value: formatNumber(
                          //   //   watch('internal_cpm'),
                          //   //   undefined,
                          //   //   'float'
                          //   // ),
                          // })}
                        />
                        {watch('internal_cpm') ? <p className="absolute left-3 top-3 text-xs leading-6 font-normal text-black-b1">$</p> : null}
                      </div>
                    </div>

                    <div className="pointer-events-none">
                      <Label htmlFor="cpm" label={params?.influencer?.sm_platform === 'youtube' ? 'Cpm' : 'Cpvh'} error={errors.cpm} />
                      <div className="relative mt-0.5">
                        <Input
                          id="cpm"
                          name="cpm"
                          type="text" // number
                          placeholder={params?.influencer?.sm_platform === 'youtube' ? 'Cpm' : 'Cpvh'}
                          // error={errors.cpm}
                          className={watch('cpm') ? '!pl-5' : ''}
                          value={
                            // TODO update formatNumber without 'a' after > 100000
                            watch('cpm') ? numeral(watch('cpm')).format('0,0.[00]') : ''
                          }
                          onChange={() => null}
                          // {...register('cpm')}
                        />
                        {watch('cpm') ? <p className="absolute left-3 top-3 text-xs leading-6 font-normal text-black-b1">$</p> : null}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="grid grid-cols-2 gap-8">
                <button
                  type="button"
                  className="flex w-full h-12 items-center justify-center text-sm leading-6 text-blue-b1 font-medium rounded-[4px] border border-blue-b1 bg-white py-2 px-4 focus:outline-none focus:ring-0"
                  onClick={hideModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={cn(
                    'flex w-full h-12 items-center justify-center space-x-2 text-sm leading-6 text-gray-g1 font-medium rounded-[4px] border border-transparent bg-blue-b1 py-2 px-4 hover:bg-blue-b1 focus:outline-none focus:ring-0',
                    { disabled: isSubmitting }
                  )}
                  disabled={isSubmitting}
                >
                  <p>Save</p>
                  <ClipLoader loading={isSubmitting} size={20} styles={{ borderWidth: '2px' }} />
                </button>
              </div>
            </>
          );
        }}
      </Form>
    </div>
  );
};
