import { useAppSelector } from 'hooks/store';
import { selectRole } from 'pages/Auth/reducer';

export enum ROLE {
  admin = 'admin',
  user = 'user',
  test = 'test',
  accountUser = 'account_user',
  viewer = 'viewer',
  indie = 'indie',
}

export const userRoles: any = {
  admins: [ROLE.admin, ROLE.test],
  users: [ROLE.user],
};

export const RequiredRole = ({
  children,
  roles,
  customForbiddenPage,
}: {
  children: (role: ROLE) => JSX.Element;
  roles: Array<ROLE>;
  customForbiddenPage?: JSX.Element;
}) => {
  const role = useAppSelector(selectRole);

  const userHasRequiredRole = Boolean(role && roles.includes(role));

  if (!userHasRequiredRole) {
    return customForbiddenPage ? (
      customForbiddenPage
    ) : (
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div style={{ fontSize: '36px', marginBottom: '12px' }}>⚠️</div>
        <h1>
          <b>Access Denied</b>
        </h1>
        <h2 style={{ fontSize: '14px', marginTop: '8px' }}>If you think this is wrong, please contact the administrator</h2>
      </div>
    );
  }

  return children(role);
};

/**
 * Return true if current user has at least one role
 * @param roles ROLE[]
 * @returns boolean
 */
export const UserHasRequiredRole = (roles: ROLE[]): boolean => {
  const role = useAppSelector(selectRole);
  return roles.includes(role);
};
