import { makeRequest } from './http';
import { Platform } from './influencers';

export const getCampaignsApi = async (data: IGetCampaigns) => {
  const query = new URLSearchParams(data).toString();

  const response = await makeRequest({
    path: `/v2/campaigns${query ? '?' + query : ''}`,
    method: 'GET',
  });
  return response;
};

export const createCampaignApi = async (data: ICreateCampaign) => {
  const response = await makeRequest({
    path: `/campaigns`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

export const updateCampaignApi = async ({ campaign_id, data }: IUpdateCampaign) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}`,
    method: 'PUT',
    params: JSON.stringify(data),
  });
  return response;
};

export const duplicateCampaignApi = async ({ campaign_id, data }: IDuplicateCampaign) =>
  await makeRequest({
    path: `/campaigns/${campaign_id}/duplicate`,
    method: 'POST',
    params: JSON.stringify(data),
  });

export const deleteCampaignApi = async (campaign_id: string) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}`,
    method: 'DELETE',
  });
  return response;
};

// CAMPAIGN

export const getCampaignApi = async (campaign_id: string) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}`,
    method: 'GET',
  });
  return response;
};

export const getCampaignSelectedChannelsApi = async ({ campaign_id, sm_platform }: IGetCampaignSelectedChannels) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/selected/${sm_platform}`,
    method: 'GET',
  });
  return response;
};

export const createCampaignSelectedChannelApi = async ({ campaign_id, data }: ICreateCampaignSelectedChannel) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/selected`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

export const updateCampaignSelectedChannelsApi = async ({ campaign_id, data }: IUpdateCampaignSelectedChannel) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/selected`,
    method: 'PUT',
    params: JSON.stringify(data),
  });
  return response;
};

export const updateCampaignSelectedChannelApi = async ({ campaign_id, selected_id, data }: IUpdateCampaignSelectedChannel) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/selected/${selected_id}`,
    method: 'PUT',
    params: JSON.stringify(data),
  });
  return response;
};

export const updateCampaignSelectedSingleRowChannelApi = async ({ campaign_id, selected_id, data }: IUpdateCampaignSelectedChannel) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/selected/${selected_id}`,
    method: 'PUT',
    params: JSON.stringify(data),
  });
  return response;
};

export const openCampaignSelectedChannelsApi = async ({ campaign_id, data }: IOpenCampaignSelectedChannels) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/selected`,
    method: 'PUT',
    params: JSON.stringify(data),
  });
  return response;
};

export const deleteCampaignSelectedChannelApi = async ({ campaign_id, selected_id }: IDeleteCampaignSelectedChannel) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/selected/${selected_id}`,
    method: 'DELETE',
  });
  return response;
};

export const deleteCampaignSelectedChannelsApi = async ({ campaign_id, data }: IDeleteCampaignSelectedChannels) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/selected_delete`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

// Engagement

export const getCampaignEngagementDataApi = async (campaign_id: string) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/engagement`,
    method: 'GET',
  });
  return response;
};

export const updateCampaignEngagementDataApi = async ({ campaign_id, data, selected_ids }: IUpdateCampaignEngagementData) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/engagement`,
    method: 'PUT',
    params: JSON.stringify({ data, selected_ids }),
  });
  return response;
};

// MANAGEMENT

export const getCampaignManagementDataApi = async (campaign_id: string) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/management`,
    method: 'GET',
  });
  return response;
};

export const updateCampaignManagementDataApi = async ({ campaign_id, selected_ids, data }: IUpdateCampaignManagementData) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/management/`,
    method: 'PUT',
    params: JSON.stringify({ data, selected_ids }),
  });
  return response;
};

export const deleteInManagementApi = async ({ campaign_id, selected_id }: IDeleteInManagement) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/management/${selected_id}`,
    method: 'DELETE',
  });
  return response;
};

// REPORT

export const createCampaignReportApi = async ({ campaign_id, data }: ICreateCampaignReport) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/report`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

export const campaignReportFetchClicksApi = async ({ data }: ICampaignReportFetchClicks) => {
  const response = await makeRequest({
    path: `/campaigns/clicks`,
    method: 'POST',
    params: JSON.stringify(data),
  });

  return response;
};

export const createCampaignReportTwitchApi = async ({ campaign_id, data }: ICreateCampaignReport) => {
  const response = await makeRequest({
    path: `/campaigns/${campaign_id}/report_twitch`,
    method: 'POST',
    params: JSON.stringify(data),
  });
  return response;
};

export const fetchTwitchSegments = async ({ data }: IFetchTwitchSegments) => {
  const response = await makeRequest({
    path: `/twitch/segments?campaign_id=${data.campaign_id}&user_name=${data.user_name}`,
    method: 'GET',
  });

  return response;
};

export const uploadChannelsDataApi = async (formData: any, platform: string, campaign_id: string) => {
  const response = await makeRequest({
    path: `/upload_channels_data?platform=${platform}&campaign_id=${campaign_id}`,
    method: 'POST',
    params: formData,
    formData: true,
  });
  return response;
};

export const getInfluencerTwitchReportApi = async (data: { campaign_id: string; influencer_id: string; content_period: number }) => {
  const response = await makeRequest({
    path: `/campaigns/${data.campaign_id}/influencer_twitch_report/${data.influencer_id}?content_period=${data.content_period}`,
    method: 'GET',
  });

  return response;
};

// TYPES

export interface IGetCampaigns {
  page?: string | number;
  order_by?: string;
  order_dir?: string | 'asc' | 'desc';
  [prop: string]: any;
}

export interface ICreateCampaign {
  campaign_name: string;
  client_id: string | number;
  client_emails: string[];
  launch_date: string;
  product: string;
  type: string;
  sm_platform: string;
  shared_with: string[];
}

export interface IUpdateCampaign {
  campaign_id: string;
  data: Partial<ICreateCampaign>;
}

export interface IDuplicateCampaign {
  campaign_id: string;
  data: ICreateCampaign;
}

// CAMPAIGN

export interface IGetCampaignSelectedChannels {
  campaign_id: string;
  sm_platform: Platform;
}

export interface ICreateCampaignSelectedChannel {
  campaign_id: string;
  data: {
    sm_platform: Platform;
    channel_ids: string[];
  };
}

export interface IUpdateCampaignSelectedChannel {
  campaign_id: string;
  selected_id: string;
  data: {
    [key: string]: any;
  };
}

export interface IOpenCampaignSelectedChannels {
  campaign_id: string;
  data: {
    shared_with_client?: boolean;
    approval?: string;
    outreach_status?: string;
    content_format?: string;
    selected_ids: string[];
  };
}

export interface IDeleteCampaignSelectedChannel {
  campaign_id: string;
  selected_id: string;
}

export interface IDeleteCampaignSelectedChannels {
  campaign_id: string;
  data: { selected_ids: string[] };
}

// Engagement

export interface IUpdateCampaignEngagementData {
  campaign_id: string;
  selected_ids: string[];
  data: {
    [key: string]: any;
  };
}

// Management

export interface IUpdateCampaignManagementData {
  campaign_id: string;
  selected_ids: string[];
  data: {
    [key: string]: any;
  };
}

export interface ICreateCampaignReport {
  campaign_id: string;
  data: {
    sm_platform: Platform;
    selected_ids: string[] | number[];
    start_date?: string | null;
    end_date?: string | null;
  };
}

export interface ICampaignReportFetchClicks {
  data: {
    sm_platform: Platform;
    links: string[];
  };
}

export interface IDeleteInManagement {
  campaign_id: number;
  selected_id: number;
}

export interface IFetchTwitchSegments {
  data: {
    user_name: string;
    campaign_id: string | number;
  };
}

export interface IUploadChannelsData {
  file: File;
  platform: string;
  campaign_id: string;
}
