import { FC, memo, useMemo, useRef } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useBlockLayout, useSortBy, useTable, useResizeColumns, useRowSelect } from 'react-table';
import { useVirtual } from 'react-virtual';
import { isEqual, orderBy } from 'lodash';
import cn from 'classnames';
import { colors } from 'variables';
import { approvalSelectedStatuses, contentFormatStatuses, contractStatuses, engagementConfig, outreachStatuses, tags } from 'utils/common';
import {
  formatNumber,
  getCpm,
  getCpmOtherDenominator,
  getTotalForCPM,
  numberSort,
  replaceSpaceToUnderscore,
  statusSort,
  localeSort,
  filterCampaignChannels,
} from 'utils/helper';
import { selectAgencies } from 'pages/Admin/reducer';
import {
  campaignActions,
  fetchCampaignEngagementDataThunk,
  selectCampaign,
  selectCampaignEngagementData,
  selectCampaignEngagementIds,
  selectCampaignEngagementSettingsFilters,
  selectCampaignEngagementSettingsSearchValue,
  selectCampaignEngagementSettingsTableFilter,
  selectCampaignEngagementSettingsTableFilterHiddenColumns,
  selectCampaignEngagementSettingsTableFilterResizing,
  selectCampaignEngagementSettingsTableFilterSortBy,
  updateCampaignEngagementDataThunk,
} from '../reducer';
import { PopupDescription } from 'components/PopupDescription';
import { FormatSelect } from '../components/FormatSelect';
import { showModal } from 'components/Modal/reducer';
import { ModalTypes } from 'components/Modal';
import { SimpleSearch } from 'components/SimpleSearch';
import { CountryFullName } from 'components/CountryFullName';
import { ExportMenu } from 'components/ExportMenu';
import { getTotalForColumn, IndeterminateCheckbox } from 'pages/Influencers/tabs/InfluencersTab';
import { checkContentFormat, engagementColumnsForContentFormat, getColumnFieldByContentFormat, getTotalForContentFormat } from './Selected';
import { Breadcrumbs, HSelect, MenuActionWithFloat, SwitchComponent, TableHeaderSort, TableCellTruncate, TableResizer, TableHeaderTruncate } from 'shared';
import { Bars3Icon, LinkIcon, YoutubeSmallTableIcon, TwitchSmallTableIcon, DotsIcon, HeaderMenuFilterIcon, ArrowDownSmallSolidIcon } from 'assets/icons';

import './styles.css';

export const smPlatformConfig: any = {
  youtube: <YoutubeSmallTableIcon pathFill="#FF0000" />,
  twitch: <TwitchSmallTableIcon pathFill="#64449E" />,
};

const getTag = (row: any, field: string) => {
  return row.published ? tags.report[field] : row.in_management ? tags.management[field] : '';
};

const getSelectedIds = (row: { original: { selected_id: any }; id: { toString: () => any } }, state: { selectedRowIds: {} }, data: any[]) => {
  let selected_ids = [row.original.selected_id];
  const checkedRows = Object.keys(state.selectedRowIds);
  const currentId = row.id.toString();
  if (checkedRows.includes(currentId)) {
    selected_ids = data.filter((item: any, index: number) => checkedRows.includes(index.toString())).map((item: any) => item.selected_id);
  }

  return selected_ids;
};

const tableColumns = ({ dispatch, campaignId, agencies }: any) => [
  {
    Header: ({ getToggleAllRowsSelectedProps }: any) => (
      <div className="flex items-center space-x-2 truncate">
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="border-2 bg-gray-gd border-white rounded-[2px]" />
        <TableHeaderTruncate name={engagementConfig.channel_name.name} />
      </div>
    ),

    accessor: 'channel_name',
    Cell: ({ row, value }: any) => {
      return (
        <div className="flex flex-1 items-center justify-between overflow-hidden">
          <div className="flex items-center space-x-2 overflow-hidden">
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} className="border-2 border-gray-gm rounded-[2px]" />
            <TableCellTruncate
              linkTo={row.original?.influencer_id != null ? `/campaigns/${campaignId}/${row.original.influencer_id}` : undefined}
              value={value}
            />
            <a href={row.original.channel_url} target="_blank" rel="noopener noreferrer">
              <LinkIcon />
            </a>
          </div>
          {getTag(row.original, 'full_label') ? (
            <div className={cn('flex items-center justify-center px-3 rounded bg-gray-g1')}>
              <p className="text-[10px] leading-4 text-gray-gd">{getTag(row.original, 'full_label')}</p>
            </div>
          ) : null}
        </div>
      );
    },
    Footer: 'Total',
    width: 300,
    sortType: localeSort,
  },
  {
    Header: engagementConfig.sm_platform.name,
    accessor: 'sm_platform',
    Cell: ({ value }: any) => <div className="flex justify-center items-center">{smPlatformConfig[value]}</div>,
    width: 130,
    className: 'justify-center',
  },
  {
    Header: engagementConfig.approval.name,
    accessor: 'approval',
    Cell: ({ row, value, state, data }: any) => (
      <FormatSelect
        value={value}
        options={approvalSelectedStatuses}
        onChange={(value: string) => {
          if (value)
            dispatch(
              updateCampaignEngagementDataThunk({
                campaign_id: campaignId || '',
                selected_ids: getSelectedIds(row, state, data),
                data: {
                  approval: value,
                },
              })
            );
        }}
      />
    ),
    width: 130,
    sortType: (a: any, b: any, id: any) => statusSort(a, b, id, approvalSelectedStatuses),
  },
  {
    Header: engagementConfig.outreach_status.name,
    // Header: (props: any) => (
    //   <HeaderMenuFilter {...props} options={outreachStatuses} />
    // ),
    accessor: 'outreach_status',
    Cell: ({ row, value, state, data }: any) => (
      <FormatSelect
        value={value}
        options={outreachStatuses}
        onChange={(value: string) => {
          if (value)
            dispatch(
              updateCampaignEngagementDataThunk({
                campaign_id: campaignId || '',
                selected_ids: getSelectedIds(row, state, data),
                data: {
                  outreach_status: value,
                },
              })
            );
        }}
      />
    ),
    width: 200,
    minWidth: 200,
    sortType: (a: any, b: any, id: any) => statusSort(a, b, id, outreachStatuses),
  },
  {
    Header: engagementConfig.contract_status.name,
    accessor: 'contract_status',
    Cell: ({ row, value, state, data }: any) => (
      <FormatSelect
        isDisabled={row.original.outreach_status !== 'successful_engagement'}
        value={value}
        options={contractStatuses}
        onChange={(value: string) => {
          if (value)
            dispatch(
              updateCampaignEngagementDataThunk({
                campaign_id: campaignId || '',
                selected_ids: getSelectedIds(row, state, data),
                data: {
                  contract_status: value,
                },
              })
            );
        }}
      />
    ),
    width: 170,
    minWidth: 170,
    sortType: (a: any, b: any, id: any) => statusSort(a, b, id, contractStatuses),
  },
  {
    Header: engagementConfig.content_format.name,
    accessor: 'content_format',
    Cell: ({ row, value, state, data }: any) => (
      <FormatSelect
        value={value}
        options={contentFormatStatuses}
        onChange={(value: string) => {
          if (value)
            dispatch(
              updateCampaignEngagementDataThunk({
                campaign_id: campaignId || '',
                selected_ids: getSelectedIds(row, state, data),
                data: {
                  content_format: value,
                },
              })
            );
        }}
      />
    ),
    width: 180,
    minWidth: 180,
    sortType: (a: any, b: any, id: any) => statusSort(a, b, id, contentFormatStatuses),
  },
  {
    Header: engagementConfig.theme.name,
    accessor: engagementConfig.theme.id,
    Cell: ({ value }: any) => <TableCellTruncate value={value} />,
    width: 130,
  },
  {
    Header: engagementConfig.internal_price.name,
    accessor: 'internal_price',
    Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '$0,0.[00]', 'float')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'internal_price'), '$0,0.[00]', 'float')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
  },
  {
    Header: engagementConfig.price.name,
    accessor: 'price',
    Cell: ({ value }: any) => <TableCellTruncate value={formatNumber(value, '$0,0.[00]', 'float')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'price'), '$0,0.[00]', 'float')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
  },
  {
    Header: engagementConfig.internal_cpm.name,
    accessor: 'internal_cpm',
    Cell: ({ row }: any) => (
      <TableCellTruncate
        value={formatNumber(
          row.original.sm_platform === 'youtube' ? getCpm(row.original.internal_price, getCpmOtherDenominator(row.original), row.original.sm_platform) : null,
          '$0,0.[00]',
          'float'
        )}
      />
    ),
    Footer: (info: any) => {
      return useMemo(
        () => <TableCellTruncate value={formatNumber(getTotalForCPM(info.rows, 'internal_price', 'youtube'), '$0,0.[00]', 'float')} />,
        [info.rows]
      );
    },
    width: 130,
    sortType: numberSort,
  },
  {
    Header: engagementConfig.cpm.name,
    accessor: 'cpm',
    Cell: ({ row }: any) => (
      <TableCellTruncate
        value={formatNumber(
          row.original.sm_platform === 'youtube' ? getCpm(row.original.price, getCpmOtherDenominator(row.original), row.original.sm_platform) : null,
          '$0,0.[00]',
          'float'
        )}
      />
    ),
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForCPM(info.rows, 'price', 'youtube'), '$0,0.[00]', 'float')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
  },
  {
    Header: engagementConfig.internal_cpvh.name,
    accessor: 'internal_cpvh',
    Cell: ({ row }: any) => (
      <TableCellTruncate
        value={formatNumber(
          row.original.sm_platform === 'twitch' ? getCpm(row.original.internal_price, getCpmOtherDenominator(row.original), row.original.sm_platform) : null,
          '$0,0.[00]',
          'float'
        )}
      />
    ),
    Footer: (info: any) => {
      return useMemo(
        () => <TableCellTruncate value={formatNumber(getTotalForCPM(info.rows, 'internal_price', 'twitch'), '$0,0.[00]', 'float')} />,
        [info.rows]
      );
    },
    width: 130,
    sortType: numberSort,
  },
  {
    Header: engagementConfig.cpvh.name,
    accessor: 'cpvh',
    Cell: ({ row }: any) => (
      <TableCellTruncate
        value={formatNumber(
          row.original.sm_platform === 'twitch' ? getCpm(row.original.price, getCpmOtherDenominator(row.original), row.original.sm_platform) : null,
          '$0,0.[00]',
          'float'
        )}
      />
    ),
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForCPM(info.rows, 'price', 'twitch'), '$0,0.[00]', 'float')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
  },
  {
    Header: engagementConfig.subscribers.name,
    accessor: 'subscribers',
    Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForColumn(info.rows, 'subscribers'), '0,0')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
  },
  {
    Header: engagementConfig.average_views.name,
    accessor: 'average_views',
    Cell: (info: any) => (
      <TableCellTruncate value={formatNumber(checkContentFormat(info.row.original.content_format) ? info.row.original?.short_views : info.value, '0,0')} />
    ),
    Footer: (info: any) => {
      return useMemo(() => <TableCellTruncate value={formatNumber(getTotalForContentFormat(info.rows, 'average_views'), '0,0')} />, [info.rows]);
    },
    width: 130,
    sortType: numberSort,
  },
  {
    Header: engagementConfig.country.name,
    accessor: 'country',
    width: 130,
    Cell: ({ value }: any) => <CountryFullName country={value} />,
  },
  {
    Header: engagementConfig.direct_email.name,
    accessor: 'direct_email',
    Cell: ({ value }: any) => <TableCellTruncate value={value} />,
    width: 220,
  },
  {
    Header: engagementConfig.agency_id.name,
    accessor: 'agency_id',
    Cell: ({ value }: any) => <TableCellTruncate value={agencies[value]?.agency_name || ''} />,
    width: 130,
  },
];

export const makeColumnHiddenCheckboxes = (props: any): any[] => {
  const arr: any[] = [];

  tableColumns(props).forEach((d: any) => {
    if (engagementConfig[d.accessor].role === 'main')
      arr.push({
        id: engagementConfig[d.accessor].id,
        label: engagementConfig[d.accessor].name,
        active: true,
      });
  });

  return arr;
};

interface IEngagementProps {
  // selectedRows: any[]
}

export const Engagement: FC<IEngagementProps> = () => {
  const { campaignId } = useParams();
  const dispatch = useAppDispatch();
  const campaign = useAppSelector(selectCampaign);
  const engagementData = useAppSelector(selectCampaignEngagementData);
  const agencies = useAppSelector(selectAgencies);
  const searchValue = useAppSelector(selectCampaignEngagementSettingsSearchValue);
  const tableFilters = useAppSelector(selectCampaignEngagementSettingsTableFilter);
  const hiddenColumns = useAppSelector(selectCampaignEngagementSettingsTableFilterHiddenColumns);
  const filters = useAppSelector(selectCampaignEngagementSettingsFilters);

  const agenciesPrepare = useMemo(() => {
    let agencyObj: any = {};

    if (agencies.length)
      agencies.forEach((agency: any) => {
        agencyObj[agency.agency_id] = { ...agency };
      });
    return agencyObj;
  }, [agencies]);

  const handleChangeSelect = (props: any) =>
    dispatch(
      campaignActions.setEngagementSettings({
        tableFilters: {
          ...tableFilters,
          hiddenColumns: props,
        },
      })
    );

  const isEmpty = Boolean(!engagementData.length);

  const columns = useMemo(() => {
    if (campaignId)
      return (
        [
          ...tableColumns({ dispatch, campaignId, agencies: agenciesPrepare }),
          {
            Header: '',
            accessor: 'action',
            width: 50,
            minWidth: 50,
            maxWidth: 50,
            disabledResizing: true,
            disableSortBy: true,
            className: 'flex py-0 bg-gradient-to-l from-white via-white to-transparent group-hover:sticky group-hover:z-10 group-hover:right-0',
            Cell: ({ row }: any) => (
              <MenuActionWithFloat
                className="flex"
                optionClass="!px-2.5"
                items={[
                  {
                    label: 'Edit',
                    onClick: () => {
                      dispatch(
                        showModal({
                          type: ModalTypes.EngagementEdit,
                          params: {
                            influencer: row.original,
                            handleSave: async (data: any) =>
                              await dispatch(
                                updateCampaignEngagementDataThunk({
                                  campaign_id: campaignId || '',
                                  selected_ids: [row.original.selected_id],
                                  data,
                                  thunk: { noLoading: true },
                                })
                              ),
                          },
                        })
                      );
                    },
                  },
                ]}
              >
                {({ open }) => (
                  <span className={cn('cursor-pointer', { 'opacity-100': open })}>
                    <DotsIcon aria-hidden="true" />
                  </span>
                )}
              </MenuActionWithFloat>
            ),
          },
        ].filter((d: any) => (hiddenColumns || []).some((dd: any) => dd.id === d.accessor || d.accessor === 'action')) || []
      );

    return [];
  }, [campaignId, hiddenColumns, agenciesPrepare, dispatch]);

  const hiddenColumnsOptions = useMemo(
    () =>
      tableColumns({
        dispatch,
        campaignId,
        agencies: agenciesPrepare,
      }).map((d: any) => ({
        id: d.accessor,
        name: engagementConfig[d.accessor].name,
      })),
    [campaignId, agenciesPrepare, dispatch]
  );

  const engagementDataSorted = useMemo(() => orderBy(engagementData, [(d) => d.channel_name || '', (d) => d.selected_id], ['asc', 'asc']), [engagementData]);

  const engagementDataFiltered = useMemo(() => filterCampaignChannels(engagementDataSorted, { filters }), [engagementDataSorted, filters]);

  const engagementDataSearched = useMemo(
    () => engagementDataFiltered.filter((d: any) => (d.channel_name || '').toLowerCase().indexOf(searchValue.toLowerCase()) !== -1),
    [searchValue, engagementDataFiltered]
  );

  const exportColumns = useMemo(() => {
    const columns = tableColumns({
      dispatch,
      campaignId,
      agencies: agenciesPrepare,
    })
      .filter((d: any) => (hiddenColumns || []).some((dd: any) => dd.id === d.accessor || d.accessor === 'action'))
      .map((d: any) => ({
        key: engagementConfig[d.accessor].id,
        header: engagementConfig[d.accessor].name,
      }));

    columns.splice(1, 0, {
      key: 'channel_url',
      header: engagementConfig['channel_url'].name,
    });
    return columns;
  }, [campaignId, agenciesPrepare, hiddenColumns, dispatch]);

  const exportData = useMemo(() => {
    return engagementDataSearched.map((d: any) => {
      const obj: any = {};
      exportColumns.forEach((dd: any) => {
        obj[dd.key] =
          Object.keys(engagementColumnsForContentFormat).includes(dd.key) && checkContentFormat(d.content_format)
            ? getColumnFieldByContentFormat[d.content_format]?.[dd.key]
              ? d[getColumnFieldByContentFormat[d.content_format][dd.key]] || 0
              : 0
            : d[dd.key];
      });
      return obj;
    });
  }, [engagementDataSearched, exportColumns]);

  const handleApply = (value: any) => {
    dispatch(
      campaignActions.setEngagementSettings({
        filters: value,
      })
    );
  };

  useLegacyEffect(() => {
    if (campaignId) dispatch(fetchCampaignEngagementDataThunk(campaignId));
  }, [campaignId]);

  // Initialize hiddenColumns
  useLegacyEffect(() => {
    if (!hiddenColumns)
      dispatch(
        campaignActions.setEngagementSettings({
          tableFilters: {
            ...tableFilters,
            hiddenColumns: makeColumnHiddenCheckboxes({
              dispatch,
              campaignId,
              agencies: agenciesPrepare,
            }),
          },
        })
      );
  }, [hiddenColumns]);

  return (
    <>
      <SimpleSearch
        className="py-4"
        placeholder="Search by engagement"
        defaultValue={searchValue}
        onChange={(value: string) =>
          dispatch(
            campaignActions.setEngagementSettings({
              searchValue: value,
            })
          )
        }
      />
      <div className="flex flex-1 flex-col overflow-hidden p-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl leading-[42px] font-semibold">Engagement ({engagementDataSearched.length})</h1>
          {isEmpty ? null : (
            <div className="flex items-center space-x-4">
              <ExportMenu data={exportData} columns={exportColumns} filename={`${replaceSpaceToUnderscore(campaign.campaign_name)}_engagement`} />
              <HSelect
                menuClass="w-44 !origin-top-right !left-auto right-0"
                selected={hiddenColumns}
                options={hiddenColumnsOptions}
                onChange={(props: any) => handleChangeSelect(props)}
              >
                {({ open }) => (
                  <button
                    type="button"
                    className={cn(
                      'inline-flex items-center justify-center w-[42px] h-[42px] rounded-[4px] border border-transparent shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0',
                      open ? 'bg-blue-b1' : 'bg-white'
                    )}
                  >
                    <Bars3Icon pathFill={open ? colors.white.default : colors.blue.b1} aria-hidden="true" />
                  </button>
                )}
              </HSelect>
            </div>
          )}
        </div>
        {/* TODO update breadcrumbs with data and routes */}
        <Breadcrumbs
          paths={[
            { name: `Campaigns`, to: `/campaigns` },
            {
              name: `${campaign.campaign_name}`,
              to: `/campaigns/${campaign.campaign_id}`,
            },
            {
              name: `Engagement`,
              to: '',
            },
          ]}
        />

        {isEmpty ? (
          // <EngagementEmpty />
          <div>No influencers here yet </div>
        ) : (
          <>
            {/* <CampaignEngagementSettings
              campaign={campaign}
              searchChannels={{ filters }}
              role={role}
              handleSubmit={handleApply}
            /> */}

            <div className="flex flex-col overflow-hidden">
              <div className="flex flex-col min-w-full align-middle overflow-hidden">
                <Table columns={columns} data={engagementDataSearched} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

interface IHeaderMenuFilterProps {
  options: any[];
  [prop: string]: any;
}

export const HeaderMenuFilter: FC<IHeaderMenuFilterProps> = ({ options = [], column: { id }, state: { tableFilters } }) => {
  const dispatch = useAppDispatch();

  const selected = useMemo(() => {
    // TODO make tableFilters: {sortBy, filters, ...}. Now tableFilters: {[id]: {}}
    if (tableFilters[id] && tableFilters[id].filter) return tableFilters[id].filter;
    return [];
  }, [tableFilters, id]);

  return (
    <HSelect
      menuListClass="!py-0"
      selected={selected}
      options={options.map((d: any, i: number) => {
        return {
          id: d.value,
          name: d.value,
          Option: ({ selected }: any) => {
            return (
              <div
                className={cn(
                  'h-12 whitespace-nowrap px-3 space-x-4 flex items-center justify-between cursor-pointer border-b border-gray-g2 hover:bg-gray-50'
                )}
              >
                <div className="flex items-center space-x-3">
                  <HeaderMenuFilterIcon />
                  <div className={`flex items-center h-6 px-2.5 rounded-[4px] text-black-b1 text-[10px] ${d.bg}`}>{d.label}</div>
                </div>
                <SwitchComponent checked={selected} />
              </div>
            );
          },
        };
      })}
      onChange={(props: any) => {
        dispatch(
          campaignActions.setEngagementSettings({
            tableFilters: {
              ...tableFilters,
              [id]: {
                ...(tableFilters[id] || {}),
                filter: props.map((d: any) => ({ id: d.id, name: d.name })),
              },
            },
          })
        );
      }}
    >
      {({ open }) => (
        <div className="flex items-center cursor-pointer space-x-2">
          <p className="group inline-flex">Outreach Status</p>
          {open ? <ArrowDownSmallSolidIcon pathFill={colors.white.default} /> : <ArrowDownSmallSolidIcon pathFill={colors.white.default} />}
        </div>
      )}
    </HSelect>
  );
};

interface ITableProps {
  data: any[];
  columns: any[];
}

const Table: FC<ITableProps> = memo(
  ({ columns, data }) => {
    const dispatch = useAppDispatch();
    const tableFilters = useAppSelector(selectCampaignEngagementSettingsTableFilter);
    const tfSortBy = useAppSelector(selectCampaignEngagementSettingsTableFilterSortBy);
    const tfResizing = useAppSelector(selectCampaignEngagementSettingsTableFilterResizing);
    const engagementSelectedIds = useAppSelector(selectCampaignEngagementIds);

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const defaultColumn = useMemo(
      () => ({
        minWidth: 130,
      }),
      []
    );

    const selectedRows = useMemo(() => {
      const ids: any = {};
      data.forEach((d: any, i: number) => {
        if (engagementSelectedIds.includes(d.selected_id)) ids[i] = true;
      });
      return ids;
    }, [data, engagementSelectedIds]);

    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
      // @ts-ignore
      state: { sortBy, columnResizing, selectedRowIds },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
        // @ts-ignore
        initialState: { sortBy: tfSortBy, selectedRowIds: selectedRows },
        useControlledState: (state) => {
          return useMemo(() => {
            return {
              ...state,
              columnResizing: {
                // @ts-ignore
                ...state?.columnResizing,
                // @ts-ignore
                columnWidths: state?.columnResizing?.headerIdWidths
                  ? // @ts-ignore
                    {
                      ...tfResizing,
                      // @ts-ignore
                      ...state.columnResizing?.columnWidths,
                    }
                  : tfResizing,
              },
            };
            // @ts-ignore
          }, [state, tfResizing]);
        },
      },
      // useFilters,
      useBlockLayout,
      useSortBy,
      useRowSelect,
      useResizeColumns
    );

    useLegacyEffect(() => {
      const ids: string[] = Object.keys(selectedRowIds).map((d: any) => data[d].selected_id);
      dispatch(campaignActions.setEngagementSelectedIds(ids));
    }, [selectedRowIds]);

    // TODO make better update tableFilters. May be use the getSortByToggleProps hook to extend its functionality
    useLegacyEffect(() => {
      dispatch(
        campaignActions.setEngagementSettings({
          tableFilters: {
            ...tableFilters,
            sortBy,
          },
        })
      );
    }, [sortBy]);

    useLegacyEffect(() => {
      if (columnResizing?.columnWidths && !columnResizing?.isResizingColumn && !isEqual(tfResizing, columnResizing.columnWidths))
        dispatch(
          campaignActions.setEngagementSettings({
            tableFilters: {
              ...tableFilters,
              resizing: columnResizing.columnWidths,
            },
          })
        );
    }, [columnResizing?.columnWidths]);

    // Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: rows.length,
      overscan: 10,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

    // Render the UI for your table
    return (
      <div ref={tableContainerRef} className="overflow-auto shadow rounded-[4px]">
        <div {...getTableProps()} className="table">
          <div className="thead sticky top-0 z-30 bg-gray-gd rounded-tl-[4px]">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column: any, hidx: number) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title=""
                    className={cn(
                      'td group text-left text-xs leading-6 whitespace-nowrap font-normal text-white border-r border-gray-g2 last:border-r-0 hover:bg-gray-g3',
                      hidx === 0 ? '!sticky left-0 top-0 z-10 bg-gray-gd shadow-fixed-divider-header' : '',
                      column?.headerClassName || '',
                      column.isSorted ? 'bg-gray-g3' : ''
                    )}
                  >
                    <div className={cn('flex items-center justify-between px-4 py-2 space-x-1', column?.disableSortBy ? '' : 'cursor-pointer')}>
                      {typeof column.Header === 'string' ? <TableHeaderTruncate name={column.Header} /> : column.render('Header')}
                      <div className="flex items-center space-x-2">
                        {column.tooltip && <PopupDescription content={column.tooltip} />}
                        <TableHeaderSort column={column} />
                      </div>
                    </div>
                    <TableResizer column={column} />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="tbody bg-white">
            {paddingTop > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingTop}px` }} />
              </div>
            )}
            {virtualRows.map((virtualRow: any) => {
              const row = rows[virtualRow.index];
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr relative group h-[3.25rem] border-b border-gray-g2 last:border-b-0 hover:shadow-s3 hover:z-20">
                  {row.cells.map((cell: any, bidx: number) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className={cn(
                          'td relative !flex items-center whitespace-nowrap px-4 text-sm leading-6 font-normal text-black-b1 border-r border-gray-g2 last:border-r-0',
                          cell.column.id !== 'action' &&
                            cell.column.id !== 'contract_status' &&
                            cell.column.id !== 'outreach_status' &&
                            cell.column.id !== 'content_format'
                            ? 'truncate'
                            : '',
                          bidx === 0 ? 'sticky left-0 top-0 z-10 shadow-fixed-divider bg-white' : '',
                          cell.column?.className || ''
                        )}
                      >
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {paddingBottom > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingBottom}px` }} />
              </div>
            )}
          </div>
          <div className="tfoot sticky bottom-0 z-30 bg-white">
            {footerGroups.map((group) => (
              <div {...group.getFooterGroupProps()} className="tr h-[3.25rem] shadow-s4">
                {group.headers.map((column, fidx) => (
                  <div
                    {...column.getFooterProps()}
                    className={cn(
                      'td !flex items-center whitespace-nowrap px-4 text-sm leading-6 font-semibold text-violet-v1 border-r border-gray-g2 last:border-r-0',
                      fidx === 0 ? 'sticky left-0 top-0 z-10 bg-white shadow-fixed-divider' : ''
                    )}
                  >
                    {column.render('Footer')}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);
