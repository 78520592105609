import { FC, memo, useRef, useMemo, useState, useEffect, useCallback } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { useLocation, useParams } from 'react-router-dom';
import { useBlockLayout, useRowSelect, useSortBy, useResizeColumns, useTable } from 'react-table';
import { useVirtual } from 'react-virtual';
import { SingleValue } from 'react-select';
import { isEqual, orderBy } from 'lodash';
import cn from 'classnames';
import { colors } from 'variables';
import { Platform } from 'services';
import { platformConfig } from 'utils/common';
import { ModalTypes } from 'components/Modal';
import { showModal } from 'components/Modal/reducer';
import { selectAgencies } from 'pages/Admin/reducer';
import { createCampaignSelectedChannelThunk } from 'pages/Campaign/reducer';
import { deleteSavedInfluencersThunk, fetchSavedChannelListInfluencersThunk, influencersActions, selectSavedInfluencers } from './reducer';
import { ActionMenu, Breadcrumbs, HSelect, Menu, RSelect, TableHeaderSort, TableHeaderTruncate, TableResizer } from 'shared';
import {
  makeColumnHiddenCheckboxes,
  platformColumns,
  PlatformSelectCustomSingleValue,
  PlatformSelectOption,
  platformSelectOptions,
  platformSelectStyles,
} from './tabs/InfluencersTab';
import { PopupDescription } from 'components/PopupDescription';
import { ExportMenu } from 'components/ExportMenu';
import { replaceSpaceToUnderscore } from 'utils/helper';
import { Bars3Icon } from 'assets/icons';

import '../Campaign/tabs/styles.css';
import { UserHasRequiredRole, ROLE } from '../../app/RequiredRole';

const usesTableColumns = {
  youtube: [
    'channel_name',
    'channel_description',
    'channel_creation_date',
    'subscribers',
    'total_videos',
    'total_views',
    'recent_videos_60',
    'recent_videos_30',
    'median_views_60',
    'median_views_30',
    'median_views_20',
    'mean_views_60',
    'mean_views_30',
    'mean_views_20',
    'true_reach_60',
    'true_reach_30',
    'true_reach_20',
    'frequency_60',
    'frequency_30',
    'engagement_score',
    'average_likes',
    'average_comments',
    'language',
    'country',
    'categories',
    'top_tags',
    'direct_email',
    'agency_id',
  ],
  twitch: [
    'channel_name',
    'channel_description',
    'followers',
    'days_week_30',
    'aaccv_20',
    'average_peak_20',
    'average_stream_duration_20',
    'hours_live_30',
    'viewer_hours_30',
    'daily_broadcast_30',
    'language',
    'recently_streamed_games',
    'direct_email',
    'agency_id',
  ],
};

export const SearchesPage: FC = () => {
  const { searchesId } = useParams();
  const location = useLocation(); // TODO add campaignName from reducer state, not location.state

  const dispatch = useAppDispatch();
  const savedInfluencers = useAppSelector(selectSavedInfluencers);
  const agencies = useAppSelector(selectAgencies);
  const disabledByRole = UserHasRequiredRole([ROLE.indie]);

  const agenciesPrepare = useMemo(() => {
    let agencyObj: any = {};

    if (agencies.length) {
      agencies.forEach((agency: any) => {
        agencyObj[agency.agency_id] = { ...agency };
      });
    }

    return agencyObj;
  }, [agencies]);

  const [platformSelect, setPlatformSelect] = useState<Platform>('youtube');
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<any[]>(() => makeColumnHiddenCheckboxes('youtube'));
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const handleUpdateSelectedRows = useCallback((rows: any[]) => {
    setSelectedRows(rows);
  }, []);

  const handleChangeSelect = (props: any) => setSelectedCheckboxes(props);

  const handleSelectChange = (newValue: SingleValue<any>) => {
    setPlatformSelect(newValue.value);
    setSelectedRows([]);
    if (searchesId)
      dispatch(
        fetchSavedChannelListInfluencersThunk({
          list_id: searchesId,
          platform: newValue.value,
        })
      )
        .unwrap()
        .then(() => {
          setSelectedCheckboxes(makeColumnHiddenCheckboxes(newValue.value));
        })
        .catch(() => {
          setSelectedCheckboxes(makeColumnHiddenCheckboxes(newValue.value));
        });
  };

  const filteredPlatformColumns = useMemo(
    () => platformColumns(undefined, agenciesPrepare)[platformSelect].filter((d: any) => usesTableColumns[platformSelect].includes(d.accessor)),
    [platformSelect, agenciesPrepare]
  );

  const columns = useMemo(
    () => [...filteredPlatformColumns].filter((d: any) => selectedCheckboxes.some((dd: any) => dd.id === d.accessor || d.accessor === 'action')) || [],
    [selectedCheckboxes, filteredPlatformColumns]
  );

  const hiddenColumnsOptions = useMemo(
    () =>
      filteredPlatformColumns.map((d: any) => ({
        id: d.accessor,
        name: platformConfig[platformSelect][d.accessor].name,
      })),
    [platformSelect, filteredPlatformColumns]
  );

  const savedInfluencersSorted = useMemo(
    () => orderBy(savedInfluencers, [(d) => d.channel_name || '', (d) => d.selected_id], ['asc', 'asc']),
    [savedInfluencers]
  );

  useLegacyEffect(() => {
    if (searchesId)
      dispatch(
        fetchSavedChannelListInfluencersThunk({
          list_id: searchesId,
          platform: 'youtube',
        })
      );
  }, [searchesId]);

  useLegacyEffect(() => {
    return () => {
      dispatch(influencersActions.resetSavedInfluencers());
    };
  }, []);

  const exportColumns = useMemo(() => {
    const columns = platformColumns(undefined, agenciesPrepare)
      [platformSelect].filter((d: any) => {
        return (selectedCheckboxes || []).some((dd: any) => dd.id === d.accessor || d.accessor === 'action');
      })
      .map((d: any) => ({
        key: platformConfig[platformSelect][d.accessor].id,
        header: platformConfig[platformSelect][d.accessor].name,
      }));

    columns.splice(1, 0, {
      key: 'channel_url',
      header: platformConfig[platformSelect]['channel_url'].name,
    });
    return columns;
  }, [platformSelect, selectedCheckboxes]);

  const exportData = useMemo(() => {
    return savedInfluencersSorted.map((d: any) => {
      const obj: any = {};
      exportColumns.forEach((dd: any) => {
        obj[dd.key] = d[dd.key];
      });
      return obj;
    });
  }, [savedInfluencersSorted, exportColumns]);

  return (
    <>
      {/* <HeaderSearchWithSettings size="lg" isDisabled={true} /> */}

      <div className="flex flex-1 flex-col p-6 bg-gray-g1 overflow-hidden">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl leading-[42px] font-semibold">{`${savedInfluencers.length} Influencers Found`}</h1>
          <div className="flex items-center space-x-4">
            <ActionMenu
              items={[
                {
                  label: 'Add to campaign',
                  disabled: !selectedRows.length || disabledByRole,
                  onClick: () =>
                    dispatch(
                      showModal({
                        type: ModalTypes.AddToCampaign,
                        params: {
                          handleSave: async (data: any) =>
                            await dispatch(
                              createCampaignSelectedChannelThunk({
                                campaign_id: data.campaign_id,
                                data: {
                                  sm_platform: platformSelect,
                                  channel_ids: selectedRows.map((r: any) => r.id),
                                },
                              })
                            ),
                        },
                      })
                    ),
                },
                {
                  label: 'Delete',
                  onClick: () => {
                    if (searchesId && selectedRows.length)
                      dispatch(
                        deleteSavedInfluencersThunk({
                          list_id: searchesId,
                          platform: platformSelect,
                          ids: selectedRows.map((d: any) => d.id),
                        })
                      )
                        .unwrap()
                        .then(() => setSelectedRows([]));
                  },
                },
              ]}
            />
            <ExportMenu
              data={exportData}
              columns={exportColumns}
              filename={`${replaceSpaceToUnderscore(location.state?.searchesName || searchesId || '')}_saved_list`}
            />
            <HSelect menuClass="w-44" selected={selectedCheckboxes} options={hiddenColumnsOptions} onChange={(props: any) => handleChangeSelect(props)}>
              {({ open }) => (
                <button
                  type="button"
                  className={cn(
                    'inline-flex items-center justify-center w-[42px] h-[42px] rounded-[4px] border border-transparent shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0',
                    open ? 'bg-blue-b1' : 'bg-white'
                  )}
                >
                  <Bars3Icon pathFill={open ? colors.white.default : colors.blue.b1} aria-hidden="true" />
                </button>
              )}
            </HSelect>
            <RSelect
              className="w-[150px]"
              styles={platformSelectStyles}
              defaultValue={platformSelectOptions[platformSelect]}
              name="select-channels"
              isSearchable={false}
              isClearable={false}
              options={Object.keys(platformSelectOptions)
                .filter((d: any) => d !== platformSelect)
                .map((d: any) => platformSelectOptions[d])}
              components={{
                ClearIndicator: () => null,
                IndicatorSeparator: () => null,
                Option: PlatformSelectOption,
                SingleValue: PlatformSelectCustomSingleValue,
              }}
              onChange={handleSelectChange}
            />
          </div>
        </div>
        <Breadcrumbs
          paths={[
            { name: `Find influencers`, to: `/influencers` },
            { name: `Saved lists`, to: `/influencers?tab=saved_list` },
            {
              name: `${location.state?.searchesName || searchesId || ''}`,
              to: '',
            },
          ]}
        />

        <div className="flex flex-col overflow-hidden">
          <div className="flex flex-col min-w-full align-middle overflow-hidden">
            <Table columns={columns} data={savedInfluencersSorted} platform={platformSelect} updateSelectedRows={handleUpdateSelectedRows} />
          </div>
        </div>
      </div>
    </>
  );
};

const Table: FC<any> = memo(
  ({ columns, data, platform, updateSelectedRows }) => {
    const tableContainerRef = useRef<HTMLDivElement>(null);

    const defaultColumn = useMemo(
      () => ({
        minWidth: 130,
        // width: 150,
        // maxWidth: 400,
      }),
      []
    );

    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      footerGroups,
      rows,
      prepareRow,
      // @ts-ignore
      state: { selectedRowIds },
    } = useTable(
      {
        columns,
        data,
        defaultColumn,
      },
      useSortBy,
      useRowSelect,
      useResizeColumns,
      useBlockLayout
    );

    useEffect(() => {
      updateSelectedRows(
        Object.keys(selectedRowIds).map((d: any) => ({
          index: d,
          id: data[d][platform + '_channel_id'],
        }))
      );
    }, [selectedRowIds]);

    // Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: rows.length,
      overscan: 10,
    });
    const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

    // Render the UI for your table
    return (
      <div ref={tableContainerRef} className="overflow-auto shadow rounded-[4px]">
        <div {...getTableProps()} className="table pr-40">
          <div className="thead sticky top-0 z-30 bg-gray-gd rounded-tl-[4px]">
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column: any, hidx: number) => (
                  <div
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    title=""
                    className={cn(
                      'td group text-left text-xs leading-6 whitespace-nowrap font-normal text-white border-r border-gray-g2 last:border-r-0 hover:bg-gray-g3',
                      hidx === 0 ? '!sticky left-0 top-0 z-10 bg-gray-gd shadow-fixed-divider-header' : '',
                      column?.headerClassName || '',
                      column.isSorted ? 'bg-gray-g3' : ''
                    )}
                  >
                    <div className={cn('flex items-center justify-between px-4 py-2 space-x-1', column?.disableSortBy ? '' : 'cursor-pointer')}>
                      {typeof column.Header === 'string' ? <TableHeaderTruncate name={column.Header} /> : column.render('Header')}
                      <div className="flex items-center space-x-2">
                        {column.tooltip && <PopupDescription content={column.tooltip} />}
                        <TableHeaderSort column={column} />
                      </div>
                    </div>
                    <TableResizer column={column} />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div {...getTableBodyProps()} className="tbody bg-white">
            {paddingTop > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingTop}px` }} />
              </div>
            )}
            {virtualRows.map((virtualRow: any) => {
              const row: any = rows[virtualRow.index];
              prepareRow(row);
              return (
                <div {...row.getRowProps()} className="tr relative group h-[3.25rem] border-b border-gray-g2 last:border-b-0 hover:shadow-s3 hover:z-20">
                  {row.cells.map((cell: any, bidx: number) => {
                    return (
                      <div
                        {...cell.getCellProps()}
                        className={cn(
                          'td relative !flex items-center truncate whitespace-nowrap px-4 text-sm leading-6 font-normal text-black-b1 border-r border-gray-g2 last:border-r-0',
                          bidx === 0 ? 'sticky left-0 top-0 z-10 shadow-fixed-divider bg-white' : '',
                          cell.column?.className || ''
                        )}
                      >
                        {cell.render('Cell')}
                      </div>
                    );
                  })}
                </div>
              );
            })}
            {paddingBottom > 0 && (
              <div className="tr">
                <div className="td" style={{ height: `${paddingBottom}px` }} />
              </div>
            )}
          </div>
          <div className="tfoot sticky bottom-0 z-30 bg-white">
            {footerGroups.map((group) => (
              <div {...group.getFooterGroupProps()} className="tr h-[3.25rem] shadow-s4">
                {group.headers.map((column, fidx) => (
                  <div
                    {...column.getFooterProps()}
                    className={cn(
                      'td !flex items-center whitespace-nowrap px-4 text-sm leading-6 font-semibold text-violet-v1 border-r border-gray-g2 last:border-r-0',
                      fidx === 0 ? 'sticky left-0 top-0 z-10 bg-white shadow-fixed-divider' : ''
                    )}
                  >
                    {column.render('Footer')}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
  (prev, next) => isEqual(prev, next)
);
