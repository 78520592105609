import { FC, forwardRef, useState, useEffect, useMemo, useRef, useCallback, useImperativeHandle } from 'react';
import { useLegacyEffect } from 'hooks/useLegacyEffect';
import { useVirtual } from 'react-virtual';
import { useTable, useRowSelect, useResizeColumns, useBlockLayout } from 'react-table';
import { useAppDispatch, useAppSelector } from 'hooks/store';
import { components, OptionProps, SingleValue, SingleValueProps, StylesConfig } from 'react-select';
import { cloneDeep, isEqual } from 'lodash';
import moment from 'moment';
import cn from 'classnames';
import { colors } from 'variables';
import { Platform } from 'services';
import { dateSort, formatNumber, getTooltip, numberSort } from 'utils/helper';
import { platformConfig } from 'utils/common';
import {
  createInfluencerThunk,
  fetchChannelsThunk,
  influencersActions,
  initInfluencersTabThunk,
  saveChannelListThunk,
  selectChannels,
  selectInfluencersLoadingToHeader,
  selectInfluencersSelectedIds,
  selectPlatform,
  selectSearchChannels,
  selectSearchTableFilters,
  selectSearchTableFiltersResizing,
  selectTotal,
} from '../reducer';
import { selectAgencies } from 'pages/Admin/reducer';
import { createCampaignSelectedChannelThunk } from 'pages/Campaign/reducer';
import { ActionMenu, Checkbox, HSelect, Menu, RSelect, TableHeaderSort, TableCellTruncate, TableResizer, TableHeaderTruncate } from 'shared';
import { showModal } from 'components/Modal/reducer';
import { ModalTypes } from 'components/Modal';
import { Settings } from 'components/Settings';
import { LanguageFullName } from 'components/LanguageFullName';
import { CountryFullName } from 'components/CountryFullName';
import { PopupDescription } from 'components/PopupDescription';
import { LinkIcon, Bars3Icon, YoutubeLogoIcon, TwitchLogoIcon, ArrowDownSortIcon, ArrowUpSortIcon } from 'assets/icons';
import { PlusIcon } from '@heroicons/react/24/outline';

import './styles.css';
import { ROLE, UserHasRequiredRole } from '../../../app/RequiredRole';

export const platformSelectOptions: any = {
  youtube: {
    value: 'youtube',
    label: 'Youtube',
    icon: YoutubeLogoIcon,
  },
  twitch: { value: 'twitch', label: 'Twitch', icon: TwitchLogoIcon },
};

export const selectConfig: any[] = [];

export const getTotalForColumn = (rows: any[], accessor: string) => rows.reduce((sum: number, row: any) => parseFloat(row.values[accessor] || 0) + sum, 0) || 0;

export const platformColumns = (total?: any, agencies?: any[]): any => {
  return {
    youtube: [
      {
        Header: ({ getToggleAllRowsSelectedProps }: any) => (
          <div className="flex items-center space-x-2 truncate">
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="border-2 bg-gray-gd border-white rounded-[2px]" />
            <TableHeaderTruncate name={platformConfig.youtube.channel_name.name} />
          </div>
        ),
        accessor: 'channel_name',
        Cell: ({ row, value }: any) => (
          <div className="flex items-center space-x-2 overflow-hidden">
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} className="border-2 border-gray-gm rounded-[2px]" />
            <TableCellTruncate linkTo={row.original?.influencer_id != null ? `/influencers/${row.original.influencer_id}` : undefined} value={value} />
            <a href={row.original.channel_url} target="_blank" rel="noopener">
              <LinkIcon />
            </a>
          </div>
        ),
        Footer: 'Total',
        width: 220,
      },
      {
        Header: platformConfig.youtube.channel_description.name,
        accessor: 'channel_description',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 220,
      },
      {
        Header: platformConfig.youtube.channel_creation_date.name,
        accessor: 'channel_creation_date',
        Cell: (row: any) => <TableCellTruncate value={moment(row.value).format('DD MMM YYYY')} />,
        width: 130,
        sortType: dateSort,
      },
      // {
      //   Header: platformConfig.youtube.channel_url.name,
      //   accessor: 'channel_url',
      // },
      {
        Header: platformConfig.youtube.subscribers.name,
        accessor: 'subscribers',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          // Only calculate total visits if rows change
          return useMemo(
            () => <TableCellTruncate value={formatNumber(total ? parseFloat(total.subscribers || 0) : getTotalForColumn(info.rows, 'subscribers'), '0,0')} />,
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.total_videos.name,
        accessor: 'total_videos',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(total ? parseFloat(total.total_videos || 0) : getTotalForColumn(info.rows, 'total_videos'), '0,0')} />,
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.total_views.name,
        accessor: 'total_views',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(total ? parseFloat(total.total_views || 0) : getTotalForColumn(info.rows, 'total_views'), '0,0')} />,
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.recent_videos_60.name,
        accessor: 'recent_videos_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(total ? parseFloat(total.recent_videos_60 || 0) : getTotalForColumn(info.rows, 'recent_videos_60'), '0,0')}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.recent_videos_30.name,
        accessor: 'recent_videos_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(total ? parseFloat(total.recent_videos_30 || 0) : getTotalForColumn(info.rows, 'recent_videos_30'), '0,0')}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.median_views_60.name,
        accessor: 'median_views_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(total ? parseFloat(total.median_views_60 || 0) : getTotalForColumn(info.rows, 'median_views_60'), '0,0')}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.median_views_30.name,
        accessor: 'median_views_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(total ? parseFloat(total.median_views_30 || 0) : getTotalForColumn(info.rows, 'median_views_30'), '0,0')}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.median_views_20.name,
        accessor: 'median_views_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(total ? parseFloat(total.median_views_20 || 0) : getTotalForColumn(info.rows, 'median_views_20'), '0,0')}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.mean_views_60.name,
        accessor: 'mean_views_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate value={formatNumber(total ? parseFloat(total.mean_views_60 || 0) : getTotalForColumn(info.rows, 'mean_views_60'), '0,0')} />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.mean_views_30.name,
        accessor: 'mean_views_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate value={formatNumber(total ? parseFloat(total.mean_views_30 || 0) : getTotalForColumn(info.rows, 'mean_views_30'), '0,0')} />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.mean_views_20.name,
        accessor: 'mean_views_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate value={formatNumber(total ? parseFloat(total.mean_views_20 || 0) : getTotalForColumn(info.rows, 'mean_views_20'), '0,0')} />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.true_reach_60.name,
        accessor: 'true_reach_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(
                  total ? parseFloat(total.true_reach_60 || 0) : getTotalForColumn(info.rows, 'true_reach_60') / info.rows.length,
                  '0.[00]%',
                  'float'
                )}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.true_reach_30.name,
        accessor: 'true_reach_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(
                  total ? parseFloat(total.true_reach_30 || 0) : getTotalForColumn(info.rows, 'true_reach_30') / info.rows.length,
                  '0.[00]%',
                  'float'
                )}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.true_reach_20.name,
        accessor: 'true_reach_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(
                  total ? parseFloat(total.true_reach_20 || 0) : getTotalForColumn(info.rows, 'true_reach_20') / info.rows.length,
                  '0.[00]%',
                  'float'
                )}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.frequency_60.name,
        accessor: 'frequency_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, undefined, 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(
                  total ? parseFloat(total.frequency_60 || 0) : getTotalForColumn(info.rows, 'frequency_60') / info.rows.length,
                  undefined,
                  'float'
                )}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.frequency_30.name,
        accessor: 'frequency_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, undefined, 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(
                  total ? parseFloat(total.frequency_30 || 0) : getTotalForColumn(info.rows, 'frequency_30') / info.rows.length,
                  undefined,
                  'float'
                )}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.engagement_score.name,
        accessor: 'engagement_score',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(
                  total ? parseFloat(total.engagement_score || 0) : getTotalForColumn(info.rows, 'engagement_score') / info.rows.length,
                  '0.[00]%',
                  'float'
                )}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.average_likes.name,
        accessor: 'average_likes',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate value={formatNumber(total ? parseFloat(total.average_likes || 0) : getTotalForColumn(info.rows, 'average_likes'), '0,0')} />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.average_comments.name,
        accessor: 'average_comments',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(total ? parseFloat(total.average_comments || 0) : getTotalForColumn(info.rows, 'average_comments'), '0,0')}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.avg_video_duration.name,
        accessor: 'avg_video_duration',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(total ? parseFloat(total.avg_video_duration || 0) : getTotalForColumn(info.rows, 'avg_video_duration'), '0,0')}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.avg_video_month.name,
        accessor: 'avg_video_month',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(total ? parseFloat(total.avg_video_month || 0) : getTotalForColumn(info.rows, 'avg_video_month'), '0,0')}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.short_views.name,
        accessor: 'short_views',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(total ? parseFloat(total.short_views || 0) : getTotalForColumn(info.rows, 'short_views'), '0,0')} />,
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.short_comments.name,
        accessor: 'short_comments',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate value={formatNumber(total ? parseFloat(total.short_comments || 0) : getTotalForColumn(info.rows, 'short_comments'), '0,0')} />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.short_likes.name,
        accessor: 'short_likes',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(total ? parseFloat(total.short_likes || 0) : getTotalForColumn(info.rows, 'short_likes'), '0,0')} />,
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.short_count.name,
        accessor: 'short_count',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(total ? parseFloat(total.short_count || 0) : getTotalForColumn(info.rows, 'short_count'), '0,0')} />,
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.short_true_reach.name,
        accessor: 'short_true_reach',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(
                  total ? parseFloat(total.short_true_reach || 0) : getTotalForColumn(info.rows, 'short_true_reach') / info.rows.length,
                  '0.[00]%',
                  'float'
                )}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.short_eng_score.name,
        accessor: 'short_eng_score',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(
                  total ? parseFloat(total.short_eng_score || 0) : getTotalForColumn(info.rows, 'short_eng_score') / info.rows.length,
                  '0.[00]%',
                  'float'
                )}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.live_views.name,
        accessor: 'live_views',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(total ? parseFloat(total.live_views || 0) : getTotalForColumn(info.rows, 'live_views'), '0,0')} />,
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.live_comments.name,
        accessor: 'live_comments',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate value={formatNumber(total ? parseFloat(total.live_comments || 0) : getTotalForColumn(info.rows, 'live_comments'), '0,0')} />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.live_likes.name,
        accessor: 'live_likes',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(total ? parseFloat(total.live_likes || 0) : getTotalForColumn(info.rows, 'live_likes'), '0,0')} />,
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.live_count.name,
        accessor: 'live_count',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(total ? parseFloat(total.live_count || 0) : getTotalForColumn(info.rows, 'live_count'), '0,0')} />,
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.live_true_reach.name,
        accessor: 'live_true_reach',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(
                  total ? parseFloat(total.live_true_reach || 0) : getTotalForColumn(info.rows, 'live_true_reach') / info.rows.length,
                  '0.[00]%',
                  'float'
                )}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.live_eng_score.name,
        accessor: 'live_eng_score',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(
                  total ? parseFloat(total.live_eng_score || 0) : getTotalForColumn(info.rows, 'live_eng_score') / info.rows.length,
                  '0.[00]%',
                  'float'
                )}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.video_views.name,
        accessor: 'video_views',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(total ? parseFloat(total.video_views || 0) : getTotalForColumn(info.rows, 'video_views'), '0,0')} />,
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.video_comments.name,
        accessor: 'video_comments',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate value={formatNumber(total ? parseFloat(total.video_comments || 0) : getTotalForColumn(info.rows, 'video_comments'), '0,0')} />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.video_likes.name,
        accessor: 'video_likes',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(total ? parseFloat(total.video_likes || 0) : getTotalForColumn(info.rows, 'video_likes'), '0,0')} />,
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.video_count.name,
        accessor: 'video_count',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(total ? parseFloat(total.video_count || 0) : getTotalForColumn(info.rows, 'video_count'), '0,0')} />,
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.video_true_reach.name,
        accessor: 'video_true_reach',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(
                  total ? parseFloat(total.video_true_reach || 0) : getTotalForColumn(info.rows, 'video_true_reach') / info.rows.length,
                  '0.[00]%',
                  'float'
                )}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.video_eng_score.name,
        accessor: 'video_eng_score',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0.[00]%', 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(
                  total ? parseFloat(total.video_eng_score || 0) : getTotalForColumn(info.rows, 'video_eng_score') / info.rows.length,
                  '0.[00]%',
                  'float'
                )}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.youtube.language.name,
        accessor: 'language',
        width: 130,
        Cell: ({ value }: any) => <LanguageFullName language={value} />,
      },
      {
        Header: platformConfig.youtube.country.name,
        accessor: 'country',
        width: 130,
        Cell: ({ value }: any) => <CountryFullName country={value} />,
      },
      {
        Header: platformConfig.youtube.categories.name,
        accessor: 'categories',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 130,
      },
      {
        Header: platformConfig.youtube.top_tags.name,
        accessor: 'top_tags',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 220,
      },
      {
        Header: platformConfig.youtube.youtube_games.name,
        accessor: 'youtube_games',
        Cell: ({ value }: any) => <TableCellTruncate value={JSON.stringify(value)} />,
        width: 220,
      },
      {
        Header: platformConfig.youtube.youtube_games_longterm.name,
        accessor: 'youtube_games_longterm',
        Cell: ({ value }: any) => <TableCellTruncate value={JSON.stringify(value)} />,
        width: 220,
      },
      {
        Header: platformConfig.youtube.genres.name,
        accessor: 'genres',
        Cell: ({ value }: any) => <TableCellTruncate value={value ? JSON.stringify(value) : ''} />,
        width: 220,
      },
      {
        Header: platformConfig.youtube.themes.name,
        accessor: 'themes',
        Cell: ({ value }: any) => <TableCellTruncate value={value ? JSON.stringify(value) : ''} />,
        width: 220,
      },
      {
        Header: platformConfig.youtube.direct_email.name,
        accessor: 'direct_email',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 220,
      },
      {
        Header: platformConfig.youtube.agency_id.name,
        accessor: 'agency_id',
        Cell: ({ value }: any) => <TableCellTruncate value={agencies?.[value]?.agency_name || ''} />,
        width: 130,
      },
    ].map((d: any) => ({
      ...d,
      tooltip: getTooltip('table', 'youtube', d.accessor),
    })),
    twitch: [
      {
        Header: ({ getToggleAllRowsSelectedProps }: any) => (
          <div className="flex items-center space-x-2">
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} className="border-2 bg-gray-gd border-white rounded-[2px]" />
            <TableHeaderTruncate name={platformConfig.twitch.channel_name.name} />
          </div>
        ),
        accessor: 'channel_name',
        Cell: ({ row, value }: any) => (
          <div className="flex items-center space-x-2 overflow-hidden">
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} className="border-2 border-gray-gm rounded-[2px]" />
            <TableCellTruncate linkTo={row.original?.influencer_id != null ? `/influencers/${row.original.influencer_id}` : undefined} value={value} />
            <a href={row.original.channel_url} target="_blank" rel="noopener">
              <LinkIcon />
            </a>
          </div>
        ),
        Footer: 'Total',
        width: 220,
      },
      {
        Header: platformConfig.twitch.channel_description.name,
        accessor: 'channel_description',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 220,
      },
      // {
      //   Header: platformConfig.twitch.channel_url.name,
      //   accessor: 'channel_url',
      // },
      {
        Header: platformConfig.twitch.followers.name,
        accessor: 'followers',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(total ? parseFloat(total.followers || 0) : getTotalForColumn(info.rows, 'followers'), '0,0')} />,
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      // {
      //   Header: platformConfig.twitch.total_views.name,
      //   accessor: 'total_views',
      //   Cell: (row: any) => formatNumber(row.value),
      // },
      {
        Header: platformConfig.twitch.days_week_30.name,
        accessor: 'days_week_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(total ? parseFloat(total.days_week_30 || 0) : getTotalForColumn(info.rows, 'days_week_30'), '0,0')} />,
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.twitch.aaccv_20.name,
        accessor: 'aaccv_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => <TableCellTruncate value={formatNumber(total ? parseFloat(total.aaccv_20 || 0) : getTotalForColumn(info.rows, 'aaccv_20'), '0,0')} />,
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.twitch.average_peak_20.name,
        accessor: 'average_peak_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(total ? parseFloat(total.average_peak_20 || 0) : getTotalForColumn(info.rows, 'average_peak_20'), '0,0')}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.twitch.median_accv_60.name,
        accessor: 'median_accv_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate value={formatNumber(total ? parseFloat(total.median_accv_60 || 0) : getTotalForColumn(info.rows, 'median_accv_60'), '0,0')} />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.twitch.median_accv_30.name,
        accessor: 'median_accv_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate value={formatNumber(total ? parseFloat(total.median_accv_30 || 0) : getTotalForColumn(info.rows, 'median_accv_30'), '0,0')} />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.twitch.median_accv_20.name,
        accessor: 'median_accv_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate value={formatNumber(total ? parseFloat(total.median_accv_20 || 0) : getTotalForColumn(info.rows, 'median_accv_20'), '0,0')} />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.twitch.median_peak_60.name,
        accessor: 'median_peak_60',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate value={formatNumber(total ? parseFloat(total.median_peak_60 || 0) : getTotalForColumn(info.rows, 'median_peak_60'), '0,0')} />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.twitch.median_peak_30.name,
        accessor: 'median_peak_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate value={formatNumber(total ? parseFloat(total.median_peak_30 || 0) : getTotalForColumn(info.rows, 'median_peak_30'), '0,0')} />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.twitch.median_peak_20.name,
        accessor: 'median_peak_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate value={formatNumber(total ? parseFloat(total.median_peak_20 || 0) : getTotalForColumn(info.rows, 'median_peak_20'), '0,0')} />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.twitch.average_stream_duration_20.name,
        accessor: 'average_stream_duration_20',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(
                  total ? parseFloat(total.average_stream_duration_20 || 0) : getTotalForColumn(info.rows, 'average_stream_duration_20'),
                  '0,0'
                )}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.twitch.avg_streams_month.name,
        accessor: 'avg_streams_month',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, undefined, 'float')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(
                  total ? parseFloat(total.avg_streams_month || 0) : getTotalForColumn(info.rows, 'avg_streams_month') / info.rows.length,
                  undefined,
                  'float'
                )}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.twitch.hours_live_30.name,
        accessor: 'hours_live_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate value={formatNumber(total ? parseFloat(total.hours_live_30 || 0) : getTotalForColumn(info.rows, 'hours_live_30'), '0,0')} />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.twitch.viewer_hours_30.name,
        accessor: 'viewer_hours_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(row.value, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(total ? parseFloat(total.viewer_hours_30 || 0) : getTotalForColumn(info.rows, 'viewer_hours_30'), '0,0')}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.twitch.daily_broadcast_30.name,
        accessor: 'daily_broadcast_30',
        Cell: (row: any) => <TableCellTruncate value={formatNumber(+row.value / 3600 || 0, '0,0')} />,
        Footer: (info: any) => {
          return useMemo(
            () => (
              <TableCellTruncate
                value={formatNumber(
                  total ? parseFloat(total.daily_broadcast_30 || 0) / 3600 : getTotalForColumn(info.rows, 'daily_broadcast_30') / 3600,
                  '0,0'
                )}
              />
            ),
            [info.rows, total]
          );
        },
        width: 130,
        sortType: numberSort,
      },
      {
        Header: platformConfig.twitch.language.name,
        accessor: 'language',
        Cell: ({ value }: any) => <LanguageFullName language={value} />,
        width: 130,
      },
      {
        Header: platformConfig.twitch.games_streamed.name,
        accessor: 'games_streamed',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 220,
      },
      {
        Header: platformConfig.twitch.recently_streamed_games.name,
        accessor: 'recently_streamed_games',
        Cell: ({ value }: any) => <TableCellTruncate value={JSON.stringify(value)} />,
        width: 220,
      },
      {
        Header: platformConfig.twitch.games_longterm.name,
        accessor: 'games_longterm',
        Cell: ({ value }: any) => <TableCellTruncate value={JSON.stringify(value)} />,
        width: 220,
      },
      {
        Header: platformConfig.twitch.genres.name,
        accessor: 'genres',
        Cell: ({ value }: any) => <TableCellTruncate value={value ? JSON.stringify(value) : ''} />,
        width: 220,
      },
      {
        Header: platformConfig.twitch.themes.name,
        accessor: 'themes',
        Cell: ({ value }: any) => <TableCellTruncate value={value ? JSON.stringify(value) : ''} />,
        width: 220,
      },
      {
        Header: platformConfig.twitch.last_seen.name,
        accessor: 'last_seen',
        Cell: ({ value }: any) => <TableCellTruncate value={moment(value).format('MM-DD-YYYY')} />,
        width: 130,
      },
      {
        Header: platformConfig.twitch.direct_email.name,
        accessor: 'direct_email',
        Cell: ({ value }: any) => <TableCellTruncate value={value} />,
        width: 220,
      },
      {
        Header: platformConfig.twitch.agency_id.name,
        accessor: 'agency_id',
        Cell: ({ value }: any) => <TableCellTruncate value={agencies?.[value]?.agency_name || ''} />,
        width: 130,
      },
    ].map((d: any) => ({
      ...d,
      tooltip: getTooltip('table', 'twitch', d.accessor),
    })),
  };
};

export const makeColumnHiddenCheckboxes = (platform: Platform, total?: any, agencies?: any[]): any[] => {
  const arr: any[] = [];

  platformColumns(total, agencies)[platform].forEach((d: any) => {
    if (platformConfig[platform][d.accessor].role === 'main')
      arr.push({
        id: platformConfig[platform][d.accessor].id,
        label: platformConfig[platform][d.accessor].name,
        active: true,
      });
  });

  return arr;
};

export const platformSelectStyles: StylesConfig<any, false> = {
  control: (styles) => ({
    ...styles,
    minWidth: '150px',
    minHeight: '42px !important',
    height: '42px !important',
    borderWidth: 0,
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingLeft: '11px',
    paddingRight: 0,
  }),
  option: (styles) => ({
    ...styles,
    height: '42px !important',
    backgroundColor: colors.white.default,
    '&:hover': {
      backgroundColor: colors.gray.g1,
    },
  }),
  menu: (styles) => ({
    ...styles,
    marginTop: '4px',
    boxShadow: 'var(--shadow-s1)',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.gray.g1,
  }),
  menuList: (styles) => ({ ...styles, padding: 0 }),
};

export const PlatformSelectOption = ({ children, ...props }: OptionProps<any, false>) => {
  const { data } = props;

  return (
    <components.Option {...props} className="!flex items-center space-x-2">
      <data.icon className="shrink-0" />
      <p className="text-sm leading-6 text-black-b1">{children}</p>
    </components.Option>
  );
};

export const PlatformSelectCustomSingleValue = ({ children, ...props }: SingleValueProps<any, false>) => {
  const { data } = props;

  return (
    <components.SingleValue {...props} className="flex items-center space-x-2">
      <data.icon className="shrink-0" />
      <p className="text-sm leading-6 text-black-b1 truncate">{children}</p>
    </components.SingleValue>
  );
};

export const InfluencersTab: FC = () => {
  const dispatch = useAppDispatch();
  const channels = useAppSelector(selectChannels);
  const total = useAppSelector(selectTotal);
  const platform = useAppSelector(selectPlatform);
  const searchChannels = useAppSelector(selectSearchChannels);
  const selectedIds = useAppSelector(selectInfluencersSelectedIds);
  const agencies = useAppSelector(selectAgencies);
  const disabledByRole = UserHasRequiredRole([ROLE.indie]);

  const agenciesPrepare = useMemo(() => {
    let agencyObj: any = {};

    if (agencies.length) {
      agencies.forEach((agency: any) => {
        agencyObj[agency.agency_id] = { ...agency };
      });
    }

    return agencyObj;
  }, [agencies]);

  const [platformSelect, setPlatformSelect] = useState<Platform>(platform);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<any[]>(() => makeColumnHiddenCheckboxes(platform, total, agenciesPrepare));

  const tableRef = useRef<any>(null);

  const handleChangeSelect = (props: any) => setSelectedCheckboxes(props);

  const handleSelectChange = (newValue: SingleValue<any>) => {
    setPlatformSelect(newValue.value);
    dispatch(influencersActions.setPlatform(newValue.value));
    dispatch(fetchChannelsThunk())
      .unwrap()
      .then(() => {
        tableRef?.current?.resetSelectedRows();
        setSelectedCheckboxes(makeColumnHiddenCheckboxes(newValue.value, total));
      })
      .catch(() => {
        setSelectedCheckboxes(makeColumnHiddenCheckboxes(newValue.value, total));
      });
  };

  const columns = useMemo(() => {
    const _platformColumns = cloneDeep(platformColumns(total, agenciesPrepare)[platformSelect]);

    _platformColumns[0] = {
      ..._platformColumns[0],
      Header: platformConfig[platformSelect].channel_name.name,
    };

    return [..._platformColumns].filter((d: any) => selectedCheckboxes.some((dd: any) => dd.id === d.accessor || d.accessor === 'action')) || [];
  }, [selectedCheckboxes, total, agenciesPrepare]);

  const handleApply = (value: any) => {
    dispatch(
      influencersActions.setSearchChannels({
        ...searchChannels,
        filters: {
          ...searchChannels.filters,
          [platform]: value,
        },
      })
    );
    dispatch(fetchChannelsThunk())
      .unwrap()
      .then(() => tableRef?.current?.resetSelectedRows());
  };

  // Now for load saved list to Saved List modal select
  useLegacyEffect(() => {
    dispatch(initInfluencersTabThunk());
    dispatch(fetchChannelsThunk());
  }, []);

  // if (!channels.length && searchChannels.length) return <NoSearchChannels />

  return (
    <>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl leading-[42px] font-semibold">{`${formatNumber(total ? parseFloat(total.count || 0) : 0)} Influencers Found`}</h1>
        <div className="flex items-center space-x-4">
          <ActionMenu
            items={[
              {
                label: 'Add to saved list',
                onClick: () =>
                  dispatch(
                    showModal({
                      type: ModalTypes.SaveChannelList,
                      params: {
                        handleSave: async (obj: any) =>
                          await dispatch(
                            saveChannelListThunk({
                              ...obj,
                              ids: selectedIds,
                            })
                          )
                            .unwrap()
                            .then(() => tableRef?.current?.resetSelectedRows()),
                      },
                    })
                  ),
              },
              {
                label: 'Add to campaign',
                disabled: !selectedIds.length || disabledByRole,
                onClick: () =>
                  dispatch(
                    showModal({
                      type: ModalTypes.AddToCampaign,
                      params: {
                        handleSave: async (data: any) =>
                          await dispatch(
                            createCampaignSelectedChannelThunk({
                              campaign_id: data.campaign_id,
                              data: {
                                sm_platform: platformSelect,
                                channel_ids: selectedIds,
                              },
                            })
                          )
                            .unwrap()
                            .then(() => tableRef?.current?.resetSelectedRows()),
                      },
                    })
                  ),
              },
            ]}
          />
          {/* <Menu
            className="flex items-center justify-center"
            itemsClass="!origin-top-right !left-auto top-[2.7rem] right-0 min-w-[154px]"
            optionClass="!px-4"
            items={[
              {
                label: 'Excel',
                onClick: () => {},
              },
              {
                label: 'CSV',
                onClick: () => {},
              },
            ]}
          >
            {({ open }) => (
              <button
                type="button"
                className={cn(
                  'inline-flex items-center justify-center w-[42px] h-[42px] rounded-[4px] border border-transparent shadow-sm focus:outline-none focus:ring-0',
                  open ? 'bg-blue-b1' : 'bg-white'
                )}
              >
                <ExportIcon pathFill={open ? colors.white.default : colors.blue.b1} aria-hidden="true" />
              </button>
            )}
          </Menu> */}
          <HSelect
            menuClass="w-44"
            selected={selectedCheckboxes}
            options={platformColumns(total, agenciesPrepare)[platformSelect].map((d: any) => ({
              id: d.accessor,
              name: platformConfig[platformSelect][d.accessor].name,
            }))}
            onChange={(props: any) => handleChangeSelect(props)}
          >
            {({ open }) => (
              <button
                type="button"
                className={cn(
                  'inline-flex items-center justify-center w-[42px] h-[42px] rounded-[4px] border border-transparent shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0',
                  open ? 'bg-blue-b1' : 'bg-white'
                )}
              >
                <Bars3Icon pathFill={open ? colors.white.default : colors.blue.b1} aria-hidden="true" />
              </button>
            )}
          </HSelect>
          <RSelect
            className="w-[150px]"
            styles={platformSelectStyles}
            defaultValue={platformSelectOptions[platformSelect]}
            name="select-channels"
            isSearchable={false}
            isClearable={false}
            options={Object.keys(platformSelectOptions)
              .filter((d: any) => d !== platformSelect)
              .map((d: any) => platformSelectOptions[d])}
            components={{
              ClearIndicator: () => null,
              IndicatorSeparator: () => null,
              Option: PlatformSelectOption,
              SingleValue: PlatformSelectCustomSingleValue,
            }}
            onChange={handleSelectChange}
          />
          {/* <button
            type="button"
            className="inline-flex items-center h-[42px] rounded-[4px] border border-transparent bg-blue-b1 px-2.5 uppercase text-sm leading-6 font-medium text-gray-g1 hover:bg-blue-b1 focus:outline-none focus:ring-0 focus:ring-blue-b1 focus:ring-offset-0"
            onClick={() =>
              dispatch(
                showModal({
                  type: ModalTypes.AddInfluencer,
                  params: {
                    handleSave: async (data: any) =>
                      await dispatch(createInfluencerThunk(data)),
                  },
                })
              )
            }
          >
            <PlusIcon className="mr-2 h-5 w-5" aria-hidden="true" />
            <p className="mr-1.5">ADD INFLUENCERS</p>
          </button> */}
        </div>
      </div>

      <Settings platform={platform} searchChannels={searchChannels} handleSubmit={handleApply} />

      <div className="mt-4 flex flex-col overflow-hidden">
        <div className="flex flex-col min-w-full align-middle overflow-hidden">
          <Table ref={tableRef} columns={columns} data={channels} platform={platformSelect} />
        </div>
      </div>
    </>
  );
};

export const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }: any, ref: any) => {
  const defaultRef = useRef<any>();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <Checkbox ref={resolvedRef} onClick={(e: any) => e.stopPropagation()} {...rest} />;
});

interface ITableProps {
  data: any[];
  columns: any[];
  platform: Platform;
}

const Table = forwardRef(function Table({ columns, data, platform }: ITableProps, ref: any) {
  const dispatch = useAppDispatch();
  const total = useAppSelector(selectTotal);
  const searchChannels = useAppSelector(selectSearchChannels);
  const selectedIds = useAppSelector(selectInfluencersSelectedIds);
  const loading = useAppSelector(selectInfluencersLoadingToHeader);
  const tableFilters = useAppSelector(selectSearchTableFilters);
  const tfResizing = useAppSelector(selectSearchTableFiltersResizing);

  //we need a reference to the scrolling element for logic down below
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const defaultColumn = useMemo(
    () => ({
      minWidth: 130,
      // width: 150,
      // maxWidth: 400,
    }),
    []
  );

  const totalDBRowCount = parseFloat(total.count || 0);
  const totalFetched = data.length;

  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        // once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
        if (scrollHeight - scrollTop - clientHeight < 300 && !loading && totalFetched < totalDBRowCount) {
          dispatch(
            fetchChannelsThunk({
              offset: totalFetched,
              isScrollFetch: true,
            })
          );
        }
      }
    },
    [totalFetched, totalDBRowCount, loading]
  );

  const selectedRows = useMemo(() => {
    const ids: any = {};
    data.forEach((d: any, i: number) => {
      if (selectedIds.includes(d.youtube_channel_id || d.twitch_channel_id)) ids[i] = true;
    });
    return ids;
  }, [data]);

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    toggleAllRowsSelected,
    // @ts-ignore
    state: { selectedRowIds, columnResizing },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // @ts-ignore
      initialState: {
        // @ts-ignore
        selectedRowIds: selectedRows,
      },
      useControlledState: (state) => {
        return useMemo(() => {
          return {
            ...state,
            columnResizing: {
              // @ts-ignore
              ...state?.columnResizing,
              // @ts-ignore
              columnWidths: state?.columnResizing?.headerIdWidths
                ? // @ts-ignore
                  {
                    ...tfResizing,
                    // @ts-ignore
                    ...state.columnResizing?.columnWidths,
                  }
                : tfResizing,
            },
          };
          // @ts-ignore
        }, [state, tfResizing]);
      },
    },
    useRowSelect,
    useResizeColumns,
    useBlockLayout
  );

  useLegacyEffect(() => {
    const ids: string[] = Object.keys(selectedRowIds).map((d: any) => data[d][platform + '_channel_id']);
    if (!isEqual(selectedIds, ids)) dispatch(influencersActions.setInfluencersIds(ids));
  }, [selectedIds, selectedRowIds]);

  useImperativeHandle(
    ref,
    () => ({
      resetSelectedRows: () => toggleAllRowsSelected(false),
    }),
    []
  );

  useLegacyEffect(() => {
    if (columnResizing?.columnWidths && !columnResizing?.isResizingColumn && !isEqual(tfResizing, columnResizing.columnWidths))
      dispatch(
        influencersActions.setSearchChannels({
          tableFilters: {
            ...tableFilters,
            [platform]: {
              ...tableFilters[platform],
              resizing: columnResizing.columnWidths,
            },
          },
        })
      );
  }, [columnResizing?.columnWidths]);

  const handleSort = (order_by: string, order_dir: string | 'asc' | 'desc') => {
    dispatch(
      influencersActions.setSearchChannels({
        ...searchChannels,
        order_by: {
          ...searchChannels.order_by,
          [platform]: order_by,
        },
        order_dir: {
          ...searchChannels.order_dir,
          [platform]: order_dir,
        },
      })
    );
    dispatch(fetchChannelsThunk());
  };

  const checkOrderDir = (column: string) => {
    return searchChannels.order_by[platform] === column && searchChannels.order_dir[platform] === 'desc'
      ? 'asc'
      : searchChannels.order_dir[platform] === 'asc'
        ? ''
        : 'desc';
  };

  //Virtualizing is optional, but might be necessary if we are going to potentially have hundreds or thousands of rows
  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

  useLegacyEffect(() => {
    if (tableContainerRef.current && tableContainerRef.current.scrollTop) tableContainerRef.current.scrollTop = 0;
  }, [searchChannels, platform]);

  // Render the UI for your table
  return (
    <div className="overflow-auto shadow rounded-[4px]" onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)} ref={tableContainerRef}>
      <div {...getTableProps()} className="table pr-40">
        <div className="thead sticky top-0 z-30 bg-gray-gd rounded-tl-[4px]">
          {headerGroups.map((headerGroup) => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map((column: any, hidx: number) => (
                <div
                  {...column.getHeaderProps()}
                  title=""
                  className={cn(
                    'td group text-left text-xs leading-6 whitespace-nowrap font-normal text-white border-r border-gray-g2 last:border-r-0 hover:bg-gray-g3',
                    hidx === 0 ? '!sticky left-0 top-0 z-10 bg-gray-gd shadow-fixed-divider-header' : '',
                    column?.headerClassName || '',
                    searchChannels.order_by[platform] === column.id ? 'bg-gray-g3' : ''
                  )}
                >
                  <div
                    className="flex items-center justify-between px-4 py-2 space-x-1 cursor-pointer"
                    onClick={() => {
                      handleSort(searchChannels.order_by[platform] === column.id && !checkOrderDir(column.id) ? '' : column.id, checkOrderDir(column.id));
                    }}
                  >
                    {typeof column.Header === 'string' ? <TableHeaderTruncate name={column.Header} /> : column.render('Header')}
                    <div className="flex items-center space-x-2">
                      {column.tooltip && <PopupDescription content={column.tooltip} />}
                      <TableHeaderSort
                        column={column}
                        isSorted={searchChannels.order_by[platform] === column.id}
                        isSortedDesc={searchChannels.order_dir[platform] === 'desc'}
                      />
                    </div>
                  </div>
                  <TableResizer column={column} />
                </div>
              ))}
            </div>
          ))}
        </div>
        <div {...getTableBodyProps()} className="tbody bg-white">
          {paddingTop > 0 && (
            <div className="tr">
              <div className="td" style={{ height: `${paddingTop}px` }} />
            </div>
          )}
          {virtualRows.map((virtualRow: any) => {
            const row: any = rows[virtualRow.index];
            prepareRow(row);
            return (
              <div {...row.getRowProps()} className="tr relative group h-[3.25rem] border-b border-gray-g2 last:border-b-0 hover:shadow-s3 hover:z-20">
                {row.cells.map((cell: any, bidx: number) => {
                  return (
                    <div
                      {...cell.getCellProps()}
                      className={cn(
                        'td relative !flex items-center truncate whitespace-nowrap px-4 text-sm leading-6 font-normal text-black-b1 border-r border-gray-g2 last:border-r-0',
                        bidx === 0 ? 'sticky left-0 top-0 z-10 shadow-fixed-divider bg-white' : '',
                        cell.column?.className || ''
                      )}
                    >
                      {cell.render('Cell')}
                    </div>
                  );
                })}
              </div>
            );
          })}
          {paddingBottom > 0 && (
            <div className="tr">
              <div className="td" style={{ height: `${paddingBottom}px` }} />
            </div>
          )}
        </div>
        <div className="tfoot sticky bottom-0 z-30 bg-white">
          {footerGroups.map((group) => (
            <div {...group.getFooterGroupProps()} className="tr h-[3.25rem] shadow-s4">
              {group.headers.map((column, fidx) => (
                <div
                  {...column.getFooterProps()}
                  className={cn(
                    'td !flex items-center whitespace-nowrap px-4 text-sm leading-6 font-semibold text-violet-v1 border-r border-gray-g2 last:border-r-0',
                    fidx === 0 ? 'sticky left-0 top-0 z-10 bg-white shadow-fixed-divider' : ''
                  )}
                >
                  {column.render('Footer')}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});
