import { FC } from 'react';
import { Tabs } from 'shared';
import { ManageInfluencers } from './tabs/ManageInfluencers';
import { PeopleManagement } from './tabs/PeopleManagement';
import { ManageAgencies } from './tabs/ManageAgencies';
import { ROLE, UserHasRequiredRole } from '../../app/RequiredRole';

export const Admin: FC = () => {
  const tabs = [];
  UserHasRequiredRole([ROLE.admin]) &&
    tabs.push({ query: 'manage_influencers', title: 'Manage influencers', content: <ManageInfluencers />, panelClass: '!p-0' });
  UserHasRequiredRole([ROLE.admin, ROLE.accountUser]) &&
    tabs.push({ query: 'people_management', title: 'People management', content: <PeopleManagement />, panelClass: '!p-0' });
  UserHasRequiredRole([ROLE.admin]) && tabs.push({ query: 'manage_agencies', title: 'Manage agencies', content: <ManageAgencies />, panelClass: '!p-0' });

  return (
    <>
      <Tabs classRoot="pt-6" tabs={tabs} />
    </>
  );
};
