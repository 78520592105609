import { FC } from 'react';

export const CampaignsForbidden: FC = () => {
  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ fontSize: '36px', marginBottom: '12px' }}>⚠️</div>
      <h1>
        <b>Access Denied</b>
      </h1>
      <h2 style={{ fontSize: '14px', marginTop: '8px' }}>
        <i>
          This account doesn’t have access to Campaigns. Please{' '}
          <a href="https://www.cloutboost.com/portal" target="_blank" rel="noreferrer">
            <u>check our plans</u>
          </a>{' '}
          to upgrade and unlock this feature.
        </i>
      </h2>
    </div>
  );
};
