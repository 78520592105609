import { FC, useEffect } from 'react';
import { ModalState } from 'components/Modal/reducer';

import { CloseIcon } from 'assets/icons';

interface IDeleteSelectedProps extends ModalState {
  startLoading: (name: string) => void;
  stopLoading: () => void;
  hideModal: () => void;
}

export const UploadStats: FC<IDeleteSelectedProps> = ({ params, hideModal }) => {
  const { handlePreload } = params;

  useEffect(() => {
    handlePreload();
  }, [handlePreload]);

  return (
    <div className="flex h-full flex-col">
      <div className="p-6 sm:p-8">
        <div className="relative flex items-start justify-center space-x-3">
          <div className="absolute -top-2 -right-2 z-10 flex h-7 items-center">
            <button type="button" className="text-black-b1 focus:outline-none" onClick={hideModal}>
              <span className="sr-only">Close panel</span>
              <CloseIcon aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="relative flex flex-col items-center justify-center">
          <h2 className="mb-2">
            <b>Your file is being processed!</b>
          </h2>
          <p className="mb-2 text-sm ">
            We've received your CSV file, our system is now processing the data. You'll receive an email notification as soon as the import is complete and your
            channels are ready for review.
          </p>
        </div>
      </div>
    </div>
  );
};
